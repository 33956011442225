import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { ThemingService } from "../../../services/theming.service";

@Component({
  selector: 'tandem-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit, AfterViewInit {

  @Input() label: string = '';
  @Input() customLabel?: string;
  @Input() form: FormGroup | AbstractControl = new FormGroup({});
  @Input() inputId: string = '';
  @Input() inputType: string = 'text';
  @Input() placeholder = '';
  @Input() isDisabled = false;
  @Input() isOptional = false;
  @Input() hideErrorMessage = false;
  @Input() controlName: string = '';
  @Input() tooltipText?: string;
  @Input() onFocus: () => void = () => {};
  @Input() autoFocus: boolean = false;

  @Input() prefixText?: string;
  @Input() suffixText?: string;

  @Input() maskType?: 'currency' | 'percent' | 'phone' | 'number';
  @Input() percentDecimalPlaces?: number = 2;
  @Input() numericDecimalPlaces: number = 0;

  @ViewChild('input') inputElement!: ElementRef;

  control: FormControl = new FormControl();
  controlBlurred = false;

  mask = '';
  dropSpecialCharacters: boolean | string[] = true;
  thousandSeparator = ',';
  decimalMarker: '.' | ',' | ['.', ','] = '.';
  allowNegativeNumbers = false;

  constructor(private themeService: ThemingService) {}

  ngOnInit() {
    this.control = this.form.get(this.controlName) as FormControl;
    if (this.maskType) {
      this.setupMask();
    }
    this.setupValidators();
  }

  ngAfterViewInit() {
    if (this.autoFocus) {
      setTimeout(() => {
        this.inputElement?.nativeElement?.focus();
      }, 0);
    }
  }

  setupMask() {
    switch (this.maskType) {
      case 'number':
        this.inputType = 'text';
        if (this.numericDecimalPlaces > 0) {
          this.mask = `separator.${this.numericDecimalPlaces}`;
        } else {
          this.mask = 'separator.0';
        }
        this.dropSpecialCharacters = [','];
        this.thousandSeparator = ',';
        this.decimalMarker = '.';
        this.allowNegativeNumbers = true;
        break;

      case 'percent':
        this.inputType = 'text';
        if (this.percentDecimalPlaces && this.percentDecimalPlaces > 0) {
          this.mask = `separator.${this.percentDecimalPlaces}`;
        } else {
          this.mask = 'separator.0';
        }
        this.dropSpecialCharacters = [','];
        this.thousandSeparator = ',';
        this.decimalMarker = '.';
        this.allowNegativeNumbers = false;
        break;

      case 'phone':
        this.inputType = 'tel';
        this.mask = '(000) 000-0000';
        this.dropSpecialCharacters = true;
        break;

      case 'currency':
        this.inputType = 'text';
        this.mask = 'separator.2';
        this.dropSpecialCharacters = [','];
        this.thousandSeparator = ',';
        this.decimalMarker = '.';
        this.allowNegativeNumbers = false;
        break;
    }
  }

  // Updated percent input handler - more permissive
  onPercentInput(event: KeyboardEvent | InputEvent | any) {
    if (this.maskType !== 'percent') return;

    const input = event.target as HTMLInputElement;
    const currentValue = input.value.replace(/[,%]/g, '');
    const decimalIndex = currentValue.indexOf('.');

    // Allow backspace and delete
    if (event instanceof KeyboardEvent && ['Backspace', 'Delete'].includes(event.key)) {
      return;
    }

    // Prevent input if it would result in more than 4 decimal places
    if (decimalIndex !== -1 && currentValue.length - decimalIndex > 5) {
      event.preventDefault();
      return;
    }

    // Prevent input if it would exceed 999.99
    const numValue = parseFloat(currentValue);
    if (!isNaN(numValue) && numValue > 999.99) {
      event.preventDefault();
      // Optionally, set the value to 999.99
      this.control.setValue('999.99');
      return;
    }

    // If it's a keydown event, check if the new character is valid
    if (event instanceof KeyboardEvent) {
      const newChar = event.key;
      if (!/^[0-9.]$/.test(newChar)) {
        event.preventDefault();
        return;
      }

      // Prevent multiple decimal points
      if (newChar === '.' && currentValue.includes('.')) {
        event.preventDefault();
        return;
      }
    }
  }
  setupValidators() {
    if (this.maskType === 'percent') {
      this.control.setValidators([
        this.control.validator!,
        this.maxValidator(999.99)
      ]);
      this.control.updateValueAndValidity();
    }
  }

  maxValidator(maxValue: number) {
    return (control: AbstractControl) => {
      const value = parseFloat(control.value);
      if (isNaN(value) || value > maxValue) {
        return { max: { max: maxValue, actual: value } };
      }
      return null;
    };
  }

  getMaxMessage() {
    if (this.control.hasError('max')) {
      return `${this.label} cannot exceed ${this.control.getError('max')['max']}`;
    }
    return `${this.label} cannot be greater than ${this.control.getError('max')['max']}`;
  }

  getMinMessage() {
    if (this.control.getError('min')['min'] === 0) {
      return `${this.label} can't be negative`;
    } else {
      return `${this.label} must be at least ${this.control.getError('min')['min']} characters`;
    }
  }

  getMinLengthMessage() {
    return `${this.label || this.placeholder} must be at least ${this.control.getError('minlength').requiredLength} characters`
  }

  // onPercentInput(event: KeyboardEvent) {
  //   if (this.maskType !== 'percent') return;
  //
  //   const input = event.target as HTMLInputElement;
  //   let value = input.value.replace(/,/g, '');
  //   const maxValue = 999.99;
  //
  //   const specialKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
  //   if (specialKeys.includes(event.key)) {
  //     return;
  //   }
  //
  //   if (!/[\d.]/.test(event.key)) {
  //     event.preventDefault();
  //     return;
  //   }
  //
  //   if (event.key === '.' && value.includes('.')) {
  //     event.preventDefault();
  //     return;
  //   }
  //
  //   const newValue = value + event.key;
  //   const parsedValue = parseFloat(newValue);
  //
  //   if (!isNaN(parsedValue) && parsedValue > maxValue) {
  //     event.preventDefault();
  //   }
  // }
}
