<div
  class="fixed z-[90] gridAlign gutter-gap-1 mx-auto margin-px-1 max-w-[1680px] top-0 right-0 left-0 w-full h-screen flex pointer-events-none {{hidden ? 'hidden' : ''}}"
>
  <div
    *ngFor="let item of [].constructor(10); let i = index"
    class="bg-dark xl:hidden opacity-10 h-full cols-1"
  ></div>
  <div
    *ngFor="let item of [].constructor(18); let i = index"
    class="bg-dark xl-max:hidden opacity-10 h-full cols-1"
  ></div>
</div>
