import { Injectable } from '@angular/core';
import {AngularFirestore, CollectionReference} from "@angular/fire/compat/firestore";
import {FinancialPosition} from "../models/financial-position";
import {AbstractDatabaseService} from "../../tandem-core/services/abstract-database.service";
import {ChartData} from "chart.js";
import {Timestamp} from "firebase/firestore";

import {map} from "rxjs/operators";
import firebase from "firebase/compat";
import Query = firebase.firestore.Query;
import {ProposedChange} from "../models/proposed-change";
import {CurrencyService} from "../../tandem-core/services/currency.service";

@Injectable({
  providedIn: 'root'
})
export class FinancialPositionService extends AbstractDatabaseService<FinancialPosition> {
  factor: number = 1.33; // Adjust this factor to change the step difference

  constructor(protected override afs: AngularFirestore,
              private currencyService: CurrencyService) {
    super('financialPositions', afs);
  }

  public getMessage(proposedChange: ProposedChange): string {
    const amount = this.currencyService.formatNumber(proposedChange.amount);
    if (proposedChange.sourceType === 'Asset') {
      if (proposedChange.destinationType === 'Asset') {
        // Asset to Asset
        return `Move ${amount} from ${proposedChange.sourceItem} to ${proposedChange.destinationItem}`;
      } else {
        // Asset to Liability
        return `Pay off ${amount} of ${proposedChange.destinationItem} from ${proposedChange.sourceItem}`;
      }
    } else { // Source Liability
      if (proposedChange.destinationType === 'Asset') {
        // Liability to Asset
        return `Take out an additional ${amount} of ${proposedChange.sourceItem} to add ${amount} of ${proposedChange.destinationItem}`;
      } else {
        // Liability to Liability
        return `Pay off ${amount} of ${proposedChange.destinationItem} by taking out an additional ${amount} of ${proposedChange.sourceItem}`;
      }
    }
  }

  public convertFinancialPositionToChartData(financialPosition: FinancialPosition, omitLabels: boolean = false): ChartData {

    let redIndex = 0;
    let greenIndex = 0;
    let darkRedIndex = 0;
    let darkGreenIndex = 0;

    const redColors = this.generateShades('#D5212E', 30);
    const darkRedColors = this.generateShades('#A10000', 30);
    const greenColors = this.generateShades('#8bc34a', 30);
    const darkGreenColors = this.generateShades('#558b2f', 30);
    const categoryColors: string[] = [];
    const categoryLabels: string[] = [];
    const categoryTotals: number[] = [];
    const sectionColors: string[] = ['#558b2f', '#A10000'];
    const sectionLabels: string[] = ['Assets', 'Liabilities'];
    const sectionTotals: number[] = [financialPosition.totalAssets, financialPosition.totalLiabilities];

    financialPosition.assets.forEach(assetCategory => {
      categoryLabels.push(assetCategory.name);
      categoryTotals.push(assetCategory.assets.reduce((sum, asset) => sum + asset.value, 0));
      categoryColors.push(greenColors[greenIndex++]);
    })

    financialPosition.liabilities.forEach(liabilityCategory => {
      categoryLabels.push(liabilityCategory.name);
      categoryTotals.push(liabilityCategory.liabilities.reduce((sum, liability) => sum + liability.value, 0));
      categoryColors.push(redColors[redIndex++]);
    })

    sectionTotals.forEach(total => categoryColors.push('')); // Offsets coloring of chart to match colors up


    // const assetLabels = financialPosition.assets.filter(a => a.assets.reduce((acc, val) => acc + val.value, 0) > 0).map(category => category.name);
    // const assetValues = financialPosition.assets.filter(a => a.assets.reduce((acc, val) => acc + val.value, 0) > 0).map(category => category.assets.reduce((acc, asset) => acc + asset.value, 0));
    //
    // const liabilityLabels = financialPosition.liabilities.filter(a => a.liabilities.reduce((acc, val) => acc + val.value, 0) > 0).map(category => category.name);
    // const liabilityValues = financialPosition.liabilities.filter(a => a.liabilities.reduce((acc, val) => acc + val.value, 0) > 0).map(category => category.liabilities.reduce((acc, liability) => acc + liability.value, 0));
    //
    // const totalAssets = assetValues.reduce((acc, value) => acc + value, 0);
    // const totalLiabilities = liabilityValues.reduce((acc, value) => acc + value, 0);
    //
    // const assetColors: string[] = ['', ''];
    // const liabilityColors: string[] = [];
    // financialPosition.assets.filter(a => a.assets.reduce((acc, val) => acc + val.value, 0) > 0).forEach(a => assetColors.push('#8bc34a'));
    // financialPosition.liabilities.filter(a => a.liabilities.reduce((acc, val) => acc + val.value, 0) > 0).forEach(a => liabilityColors.push('#D5212E'));
    // Dynamic colors for asset sub-categories

    return {
      labels: omitLabels ? [] : [...sectionLabels, ...categoryLabels],
      datasets: [
        {
          label: 'Total Value',
          data: categoryTotals,
          backgroundColor: categoryColors,
        },
        {
          label: 'Total Value',
          data: sectionTotals,
          backgroundColor: sectionColors,
        }
      ],
    };
  }

  generateShades(startColor: string, count: number): string[] {
    const startRed = parseInt(startColor.substring(1, 3), 16);
    const startGreen = parseInt(startColor.substring(3, 5), 16);
    const startBlue = parseInt(startColor.substring(5, 7), 16);

    const endRed = 255;
    const endGreen = 255;
    const endBlue = 255;

    let shades: string[] = [];

    for (let i = 0; i < count; i++) {
      const interpolatedRed = this.interpolateColor(startRed, endRed, i, count);
      const interpolatedGreen = this.interpolateColor(startGreen, endGreen, i, count);
      const interpolatedBlue = this.interpolateColor(startBlue, endBlue, i, count);

      shades.push(`#${this.componentToHex(interpolatedRed)}${this.componentToHex(interpolatedGreen)}${this.componentToHex(interpolatedBlue)}`);
    }

    return shades;
  }

  private interpolateColor(start: number, end: number, step: number, maxStep: number): number {
    const delta = (end - start) / (maxStep - 1) * this.factor;
    const result = start + delta * step;

    // Ensure result is within [0, 255] range
    return Math.min(255, Math.max(0, Math.round(result)));
  }

  private componentToHex(c: number): string {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
}
