import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TandemUser} from "../../models/tandem-user";
import {UserService} from "../../user.service";
import {UserSubscription} from "../../models/user-subscription";
import {DialogService, TandemDialogConfig} from "../../../tandem-core/services/dialog.service";
import {PaymentService} from "../../../stripe/services/payment-service";
import {CurrencyService} from "../../../tandem-core/services/currency.service";
import {Subscription} from "rxjs";
import {AuthService} from "../../services/auth.service";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'tandem-user-sub-management',
  templateUrl: './user-sub-management.component.html',
  styleUrls: ['./user-sub-management.component.scss']
})
export class UserSubManagementComponent implements OnInit, OnChanges {

  @Input() user: TandemUser | null = null;
  @Input() coach: TandemUser | null = null;
  @Input() userSubscription: UserSubscription | undefined;

  private subscriptionSub?: Subscription;

  public currentPageStatus: 'individualUser'
    | 'userOfRevShareCoach'
    | 'userOfLeadGenCoach'
    | 'revShareCoach'
    | 'leadGenCoach'
    | 'loading' = "loading";

  // TODO: maybe add cool timer for preventing accidental remove access clicks? 3... 2.... 1.... Cancel

  constructor(private userService: UserService,
              private auth: AuthService,
              private currencyService: CurrencyService,
              private dialogService: DialogService,
              private paymentService: PaymentService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
        if (changes['coach']?.currentValue || changes['user']?.currentValue) {
          if (this.user) {
            console.log(this.coach)
            if (this.coach) {
              this.currentPageStatus = this.coach.coachType === 'coachLeadGen' ? 'userOfLeadGenCoach' : 'userOfRevShareCoach';
            } else {
              this.currentPageStatus = this.user.accountType === 'coach' ? this.user.coachType === 'coachLeadGen' ? 'leadGenCoach' : 'revShareCoach' : 'individualUser';
            }
            // console.log('new sub!')
            // this.subscriptionSub?.unsubscribe();
            // this.subscriptionSub = this.userService.getSubscriptionInformationForUser(this.user.uid).subscribe(userSub => {
            //   this.userSubscription = userSub;
            // });
          }
        }
    }

  ngOnInit(): void {

    console.log(this)

    if (this.user?.status === 'Orphaned') {
      this.transitionToIndividual();
    }
  }

  cancelUserSubscription() {
    // Note: This is only used by users of a "user pays" (rev share) coach, for cancelling their subscription.
    this.dialogService.openConfirmDialog('Cancel Subscription', 'Are you sure you want to cancel your subscription?', 'Yes, cancel', 'No, go back').afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.dialogService.openLoadingDialog('Cancelling Subscription', 'Please wait, we\'re cancelling your subscription.')
        this.paymentService.cancelSubscription().then(res => {
          const endDate: Date = res.endDate;
          this.dialogService.openMessageDialog('Successfully Cancelled',
            `We're sad to see you go, but your subscription has successfully been cancelled. You'll still have access until ${endDate.toDateString() || 'the end of the current billing period'}.`);
        });
      }
    })
  }

  getCurrentUserCount(): number {
    return 47;
  }

  getCurrentMonthlyPrice(): number {
    return 9.99;
  }

  getCurrentMonthyTotal(): number {
    return this.getCurrentUserCount() * this.getCurrentMonthlyPrice();
  }

  openPayment() {
    this.paymentService.openStripe();
  }

  cancelCoachAccount() {
    let message = '';
    let secondMessage = '';
    if (this.user?.accountType === 'coach' && this.user.coachType === 'coachRevShare') {
      message = 'Are you sure you want to delete your coach account? You will lose access to the Tandem platform. Your existing users will still be able to use the platform, but you won\'t have any access to their data.';
      secondMessage = 'You will lose all of your data, and your users will be without their financial coach. Make sure that you\'ve communicated this major change with your users before proceeding. Do you really want to delete your Tandem account?';
    } else {
      message = 'Are you sure you want to delete your coach account? You AND your users will lose access to the Tandem platform. If any of your users wish to continue using the platform, they will be given the option to pay for their own access'
      secondMessage = `Your users will all lose access to the Tandem Platform (their data will still be there). Make sure that you've communicated this major change with your users before proceeding. Do you really want to delete your Tandem account?`
    }
    let firstDialog: TandemDialogConfig = {
      title: 'Confirm Delete',
      content: message,
      type: 'confirm',
      actions: [
        {
          text: 'Never mind',
          role: 'cancel',
          callback: () => this.dialogService.closeModal2()
        },
        {
          text: `Yes, I'm Sure`,
          role: 'confirm',
          callback: () => {
            let finalDialog: TandemDialogConfig = {
              title: 'Are You Really Sure?',
              content: secondMessage,
              type: 'confirm',
              actions: [
                {
                  text: 'No, Go Back',
                  role: 'cancel',
                  callback: () => this.dialogService.closeModal2()
                },
                {
                  text: `Yes, Delete Account`,
                  role: 'confirm',
                  callback: () => {
                    this.showLoadingDialog();
                    this.paymentService.modifyAccess({
                      userId: this.user!.uid,
                      type: 'individual',
                      outcome: 'cancel',
                      requestingActor: 'coach',
                      coachId: this.user!.uid
                    }).then(res => {
                      this.auth.onLogout(true);
                      let successDialog: TandemDialogConfig = {
                        title: 'Successfully Canceled',
                        content: `Successfully canceled your access. Your users have been notified.`,
                        type: 'success',
                        actions: [
                          {
                            text: 'Close',
                            role: 'cancel',
                            callback: () => this.dialogService.closeModal2()
                          },
                        ]
                      }
                      this.dialogService.openModal2(successDialog);
                    })
                  }
                }
              ]
            }
            this.dialogService.openModal2(finalDialog);
          }
        }
      ]
    }
    this.dialogService.openModal2(firstDialog);
  }

  pauseUserAccount() {
    let firstDialog: TandemDialogConfig = {
      title: 'Confirm Pause',
      content: `Are you sure you want to pause your access? ${this.coach?.coachType === 'coachLeadGen' ? `Your coach will have to grant you access again when you want to resume.` : ''}`,
      type: 'confirm',
      actions: [
        {
          text: 'Never mind',
          role: 'cancel',
          callback: () => this.dialogService.closeModal2()
        },
        {
          text: `Yes, I'm Sure`,
          role: 'confirm',
          callback: () => {
            this.showLoadingDialog();
            this.paymentService.modifyAccess({
              userId: this.user!.uid,
              type: 'individual',
              outcome: 'cancel',
              requestingActor: 'user',
              coachId: this.coach!.uid
            }).then(res => {
              let successDialog: TandemDialogConfig = {
                title: 'Successfully Paused',
                content: `Successfully paused your access.`,
                type: 'success',
                actions: [
                  {
                    text: 'Close',
                    role: 'cancel',
                    callback: () => this.dialogService.closeModal2()
                  },
                ]
              }
              this.dialogService.openModal2(successDialog);
            })
          }
        }
      ]
    }
    this.dialogService.openModal2(firstDialog);
  }

  deleteRevShareCoachAccount() {
    let firstDialog: TandemDialogConfig = {
      title: 'Confirm Delete',
      content: `Are you sure you want to delete your account?`,
      type: 'confirm',
      actions: [
        {
          text: 'Never mind',
          role: 'cancel',
          callback: () => this.dialogService.closeModal2()
        },
        {
          text: `Yes, I'm Sure`,
          role: 'confirm',
          callback: () => {
            this.auth.deleteCurrentUser();
          }
        }
      ]
    }
    this.dialogService.openModal2(firstDialog);
  }

  deleteUserAccount() {
    let firstDialog: TandemDialogConfig = {
      title: 'Confirm Delete',
      content: `Are you sure you want to delete your account? You will lose access to the Tandem platform, and all of your data will be deleted.`,
      type: 'confirm',
      actions: [
        {
          text: 'Never mind',
          role: 'cancel',
          callback: () => this.dialogService.closeModal2()
        },
        {
          text: `Yes, I'm Sure`,
          role: 'confirm',
          callback: () => {
            let finalDialog: TandemDialogConfig = {
              title: 'Are You Really Sure?',
              content: `If you want to come back to the platform in the future, you'll have to start from scratch. Are you sure you want to do this?`,
              type: 'confirm',
              actions: [
                {
                  text: 'No, Go Back',
                  role: 'cancel',
                  callback: () => this.dialogService.closeModal2()
                },
                {
                  text: `Yes, Remove Access`,
                  role: 'confirm',
                  callback: () => {
                    this.showLoadingDialog();
                    this.paymentService.modifyAccess({
                      userId: this.user!.uid,
                      type: 'individual',
                      outcome: 'delete',
                      requestingActor: 'user',
                      coachId: this.coach!.uid
                    }).then(res => {
                      this.auth.onLogout(false);
                      let successDialog: TandemDialogConfig = {
                        title: 'Successfully Deleted',
                        content: `Successfully deleted your access.`,
                        type: 'success',
                        actions: [
                          {
                            text: 'Close',
                            role: 'cancel',
                            callback: () => this.dialogService.closeModal2()
                          },
                        ]
                      }
                      this.dialogService.openModal2(successDialog);
                    })
                  }
                }
              ]
            }
            this.dialogService.openModal2(finalDialog);
          }
        }
      ]
    }
    this.dialogService.openModal2(firstDialog);
  }

  modifyUserStatus(status: 'cancel' | 'delete') {
    let firstDialog: TandemDialogConfig = {
      title: `Confirm ${status === 'cancel' ? 'Cancel' : 'Delete'}`,
      content: `Are you sure you want to ${status === 'cancel' ? 'cancel your subscription' : 'delete your account'}? ${this.coach?.coachType === 'coachLeadGen' ? `Your coach will have to grant you access again when you want to resume.` : ''}`,
      type: 'confirm',
      actions: [
        {
          text: 'Never mind',
          role: 'cancel',
          callback: () => this.dialogService.closeModal2()
        },
        {
          text: `Yes, I'm Sure`,
          role: 'confirm',
          callback: () => {
            this.showLoadingDialog();
            this.paymentService.modifyAccess({
              userId: this.user!.uid,
              type: 'individual',
              outcome: status,
              requestingActor: 'user',
              coachId: this.coach?.uid || ''
            }).then(res => {
              let successDialog: TandemDialogConfig = {
                title: `Successfully ${status === 'cancel' ? 'Canceled' : 'Deleted'}`,
                content: `Successfully ${status === 'cancel' ? 'canceled your subscription' : 'deleted your account.'}`,
                type: 'success',
                actions: [
                  {
                    text: 'Close',
                    role: 'cancel',
                    callback: () => {
                      this.dialogService.closeModal2();
                      if (status === 'delete') {
                        this.auth.onLogout(true);
                        location.reload();
                      }
                    }
                  },
                ]
              }
              this.dialogService.openModal2(successDialog);
            }).catch(err => {
              this.dialogService.closeModal2();
              this.dialogService.openModal2({
                title: 'Unexpected Error',
                content: 'Your request couldn\'t be completed at this time.',
                type: 'failure',
                actions: [
                  {
                    text: 'Close',
                    role: 'close',
                    callback: () => this.dialogService.closeModal2()
                  }
                ]
              })

            })
          }
        }
      ]
    }
    this.dialogService.openModal2(firstDialog);
  }

  public transitionToIndividual() {
    let firstDialog: TandemDialogConfig = {
      title: `Transition to Individual`,
      content: `Are you sure you want to transition to an individual account with the Tandem platform? You will still retain all of your current tracking tools and user data.`,
      type: 'confirm',
      actions: [
        {
          text: 'Never mind',
          role: 'cancel',
          callback: () => this.dialogService.closeModal2()
        },
        {
          text: `Yes, I'm Sure`,
          role: 'confirm',
          callback: () => {

            // Means they're probably a rev share user
            if (this.userSubscription?.status === 'SUCCESS') {
              this.showLoadingDialog();
              this.paymentService.transitionToIndividual(this.user!.uid).then(res => {
                let successDialog: TandemDialogConfig = {
                  title: `Successfully Switched`,
                  content: `Successfully switched to an individual account. You will now be logged out of your coach's area and be taken directly to the Tandem platform. You may use the same login credentials, they haven't changed.`,
                  type: 'success',
                  actions: [
                    {
                      text: 'Proceed to Tandem',
                      role: 'primary',
                      callback: () => {
                        console.log('performing callback')
                        this.auth.logoutAndRedirect()
                      }
                    },
                  ]
                }
                this.dialogService.openModal2(successDialog);
              }).catch(err => {
                this.dialogService.closeModal2();
                this.dialogService.openModal2({
                  title: 'Unexpected Error',
                  content: 'Your request couldn\'t be completed at this time.',
                  type: 'failure',
                  actions: [
                    {
                      text: 'Close',
                      role: 'close',
                      callback: () => this.dialogService.closeModal2()
                    }
                  ]
                })

              })
            } else {
              this.openPayment();
            }
          }
        }
      ]
    }
    this.dialogService.openModal2(firstDialog);
  }

  private showLoadingDialog(message?: string) {
    let dialog: TandemDialogConfig = {
      title: 'Loading',
      content: message ? message : `Please wait while we process your request`,
      type: 'loading',
      actions: [
      ]
    }
    this.dialogService.openModal2(dialog);
  }
}
