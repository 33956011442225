import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  APP_PREFIX = 'tandem-app/';

  constructor() { }

  clearAllStorage() {
    localStorage.clear();
  }

  saveItem(path: string, item: any) {
    localStorage.setItem(`${this.APP_PREFIX}${path}`, JSON.stringify(item));
  }

  getItem(path: string) {
    return JSON.parse(<string>localStorage.getItem(`${this.APP_PREFIX}${path}`));
  }

  removeItem(path: string) {
    localStorage.removeItem(`${this.APP_PREFIX}${path}`);
  }
}
