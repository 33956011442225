<div *ngIf="!userIsPaid || user?.status === 'Orphaned'" (click)="openStripe()" class="flex flex-row w-full py-3 content-center items-center gap-10 bg-red-500 text-white text-center" [ngClass]="{'hover:cursor-pointer': !disablePaymentRoute}">
  <p class="text-center w-full text-background">{{bannerText}}</p>
</div>

<header *ngIf="headerConfig && !headerConfig.hideHeader" class="py-10 px-4 sm:px-6 lg:px-8 flex flex-col items-center bg-gray-100">
  <div *ngIf="headerConfig.isDashboard" class="flex flex-col md:flex-row justify-between w-full 2xl:w-3/4">
    <div class="flex flex-col gap-4">
      <h1 class="text-2xl md:text-4xl">Welcome Back, {{headerConfig.title?.substring(13)}}!</h1>
      <h2 class="text-xl md:text-2xl" *ngIf="user">Active since {{user.dateCreated.toDate()  | tandemDate}}</h2>
      <a class="text-primary underline hover:cursor-pointer mb-4" (click)="showVideo()">Need a refresh?</a>
    </div>
    <div class="flex flex-row justify-end gap-4">
      <div class="ring-1 ring-inset ring-gray-300 rounded-md flex flex-row p-5 w-full md:max-w-lg justify-between min-w-[20rem] xl:min-w-[30rem] 2xl:min-w-[40rem]">
        <div>
          <h4 class="text-xl">My Vision</h4>
          <h5 class="text-lg">{{user?.vision || 'No vision set'}}</h5>

        </div>
        <div class="flex items-center h-full p-3">
          <tandem-button [onClick]="onVisionChange.bind(this)">Edit</tandem-button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!headerConfig.isDashboard" class="flex flex-col justify-between w-full 2xl:w-3/4">
    <div class="flex flex-col">
      <h1 class="text-2xl md:text-4xl">{{headerConfig.title}}</h1>
      <h1 *ngIf="headerConfig.titleRow2" class="text-2xl">{{headerConfig.titleRow2}}</h1>
      <h2 class="mt-4 text-lg md:text-2xl break-words">{{ headerConfig.subtitle }}</h2>
    </div>
    <div class="flex flex-col w-full mt-5">
      <ng-container *ngIf="headerConfig.optionalTemplate" [ngTemplateOutlet]="headerConfig.optionalTemplate"></ng-container>
    </div>
  </div>
</header>
<!--<ng-container *ngIf="false && headerConfig && !headerConfig.hideHeader">-->
<!--  <header class="bg-primary/10 relative flex-initial px-40">-->
<!--    <div class="margin-px-1">-->
<!--      <div class="w-full flex flex-row justify-between items-center">-->
<!--        <div class="flex flex-col gap-15">-->
<!--          <div class="flex flex-col gap-10 pb-10" *ngIf="headerConfig.isDashboard" [ngClass]="{'sm:pt-20 md:pt-30 lg:pt-50 xl:pt-75': !showUserCircle}">-->
<!--            <div class="flex flex-row lg:gutter-gap-1">-->
<!--              <h1 class="text-6xl">Welcome Back {{headerConfig.title?.substring(13)}}!</h1>-->
<!--              <div class="lg:hidden flex flex-col justify-start items-end cols-4">-->
<!--                <tandem-button class="w-fit block mt-5" color="primary" icon="edit" [onClick]="onVisionChange.bind(this)">MY VISION</tandem-button>-->
<!--              </div>-->
<!--              <div class="hidden lg:flex cols-5 xl:cols-9 auto-cols-max flex-row gap-15 items-center">-->
<!--                <h4 class="cols-1 xl:cols-2">My Vision</h4>-->
<!--                <div class="cols-4 xl:cols-9 px-15 py-10 flex flex-row justify-between items-center border border-dark/20 rounded-sm h-auto">-->
<!--                  <h5>{{user?.vision || 'No vision set'}}</h5>-->
<!--                  <tandem-button class="ml-10" [onClick]="onVisionChange.bind(this)">edit</tandem-button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <h4 class="cols-6 lg:cols-5 xl:cols-9">Active since {{user!.dateCreated.toDate()  | tandemDate}}</h4>-->
<!--            <h5 class="text-primary underline hover:cursor-pointer" (click)="showVideo()">Need a refresh?</h5>-->
<!--          </div>-->
<!--          <div *ngIf="!headerConfig.isDashboard" class="flex flex-col gap-20 sm:pt-20 md:pt-30 lg:pt-50 xl:pt-75">-->
<!--            <span class="text-12-caps uppercase font-medium">-->
<!--            <ng-container *ngFor="let crumb of headerConfig.breadcrumbs">{{crumb.label}} / </ng-container>-->
<!--            </span>-->
<!--            <h1>{{ headerConfig.title }}</h1>-->
<!--            <h5>{{headerConfig.subtitle}}</h5>-->
<!--          </div>-->
<!--&lt;!&ndash;          <h5 class="self-stretch">{{headerConfig.subtitle}}</h5>&ndash;&gt;-->
<!--        </div>-->
<!--        <div *ngIf="!headerConfig.isDashboard && showUserCircle" class="flex flex-row justify-end sm:pt-20 md:pt-30 lg:pt-50 xl:pt-75 relative">-->
<!--          <img (click)="toggleUserDropdown()" class="profile-img border-4 border-primary rounded-full z-50 bg-background mb-30 h-60 w-60"-->
<!--               [src]="user?.photoURL || 'assets/images/profileForTandem.png'" alt="profile picture">-->
<!--          <div class="absolute w-fit rounded-sm shadow-lg ring-1 ring-black ring-opacity-5 origin-top-right right-10 z-10 p-10 tandem-dropdown" style="top: 85%" [ngClass]="{'hidden': !displayUserDropdown}">-->
<!--            <div class="py-1 flex flex-col gap-10" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">-->
<!--              <div class="flex flex-row items-center gap-1 cursor-pointer" (click)="displayUserDropdown = false" [routerLink]="'auth/profile'" >-->
<!--                <tandem-icon class="duration-350 transition-quad-inout" icon="settings" color="black" [height]="20" [width]="20"></tandem-icon>-->
<!--                <a class="block px-4 py-5 text-sm" role="menuitem">My Account</a>-->
<!--              </div>-->
<!--              <div class="flex flex-row items-center gap-1 cursor-pointer" (click)="displayUserDropdown = false; onLogout()">-->
<!--                <tandem-icon class="duration-350 transition-quad-inout" icon="logout" color="black" [height]="20" [width]="20"></tandem-icon>-->
<!--                <a class="block px-4 py-5 text-sm" role="menuitem">Logout</a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--&lt;!&ndash;        <div *ngIf="headerConfig.isDashboard" class="w-fit flex flex-row gap-15 items-center">&ndash;&gt;-->
<!--&lt;!&ndash;          <h4>My Vision</h4>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="px-15 py-10 mr-40 flex flex-row justify-between items-center border border-dark/20 rounded-sm">&ndash;&gt;-->
<!--&lt;!&ndash;            <h5 class="cols-6">{{user?.vision || 'No vision set'}}</h5>&ndash;&gt;-->
<!--&lt;!&ndash;            <tandem-button [onClick]="onVisionChange.bind(this)">edit</tandem-button>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash; icons &ndash;&gt;-->
<!--    </div>-->
<!--  </header>-->
<!--</ng-container>-->
