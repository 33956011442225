<div class="bg-primary transition-all duration-300 ease-quad-out w-300 h-full flex flex-col justify-between py-30" (click)="$event.stopPropagation()" [ngClass]="{'w-50': sidenavState === 'collapsed', 'overflow-y-auto': sidenavState === 'expanded', 'hidden': sidenavState === 'closed'}">
  <div class="flex flex-col justify-start gap-40" [ngClass]="{'items-center': sidenavState === 'collapsed'}">
    <img class="transition-transform ease-quad-inout duration-1000" [src]="sidenavState === 'expanded' ? environment.whiteLogoPath : environment.whiteIconPath">
    <!--    <img class="transition-transform ease-quad-inout duration-1000" [src]="isExpanded ? 'assets/svg/logo/full_white.svg' : 'assets/svg/logo/icon_white.svg'">-->
    <tandem-nav-item class="px-20" [isExpanded]="sidenavState === 'expanded'" [navItem]="{label: 'Dashboard', icon: 'dashboard'}" [routerLink]="'dashboard'" (click)="collapseSidenav()"></tandem-nav-item>
    <div class="flex flex-col gap-20 px-20" [ngClass]="{'items-center': sidenavState === 'collapsed'}">
      <ng-container>
        <h4 *ngIf="sidenavState === 'expanded'" class="text-background transition-all duration-300 ease-quad-out">Tracking Tools</h4>
        <tandem-nav-item class="transition-all duration-300 ease-quad-out" *ngFor="let item of ttItems" [isExpanded]="sidenavState === 'expanded'" [navItem]="{label: item.label, icon: item.icon}" (click)="onRoute(item.routerLink)" [disabled]="previewTrackingTools"></tandem-nav-item>
      </ng-container>
    </div>
    <div class="flex flex-col gap-20 px-20" [ngClass]="{'items-center': sidenavState === 'collapsed'}">
      <ng-container *tandemAuth="'calculators'">
        <h4 *ngIf="sidenavState === 'expanded'" class="text-background transition-all duration-300 ease-quad-out">Calculators</h4>
        <tandem-nav-item class="transition-all duration-300 ease-quad-out" *ngFor="let item of calculatorItems" [isExpanded]="sidenavState === 'expanded'" [navItem]="{label: item.label, icon: item.icon}" [routerLink]="item.routerLink" (click)="collapseSidenav()"></tandem-nav-item>
      </ng-container>
    </div>
    <div class="flex flex-col gap-20 px-20" [ngClass]="{'items-center': sidenavState === 'collapsed'}">
      <ng-container *tandemAuth="'isCoach'">
        <h4 *ngIf="sidenavState === 'expanded'" class="text-background transition-all duration-300 ease-quad-out">Coaching</h4>
        <tandem-nav-item class="transition-all duration-300 ease-quad-out" *ngFor="let item of coachingItems" [isExpanded]="sidenavState === 'expanded'" [navItem]="{label: item.label, icon: item.icon}" [routerLink]="item.routerLink" (click)="collapseSidenav()"></tandem-nav-item>
      </ng-container>
    </div>
  </div>
  <div class="flex flex-col items-center gap-40">
    <div class="w-full flex flex-row {{sidenavState === 'expanded' ? 'justify-end px-20' : 'justify-center'}}">
      <tandem-icon class="transition-transform duration-300 ease-quad-out hover:cursor-pointer hover:opacity-70" [ngClass]="{'rotate-180': sidenavState === 'expanded'}"  [width]="25" [height]="25" icon="playRight" (click)="toggleExpanded()" color="white"></tandem-icon>
    </div>
  </div>
</div>
