import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import 'firebase/compat/firestore';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, combineLatest, filter, Subscription} from 'rxjs';
import {DialogService, TandemDialogConfig,} from './modules/tandem-core/services/dialog.service';
import {HeaderConfig, HeaderService,} from './modules/tandem-core/services/header.service';
import {AuthService, AuthStatus} from "./modules/auth/services/auth.service";
import {TandemUser} from "./modules/auth/models/tandem-user";
import {slideInAnimation} from "./modules/tandem-core/animations/route-animations";
import {map} from "rxjs/operators";
import {ThemingService} from "./modules/tandem-core/services/theming.service";
import {environment} from "../environments/environment";
import {SidenavState} from "./components/nav/sidenav2/sidenav2.component";
import {LocalStorageService} from "./modules/tandem-core/services/local-storage.service";
import {NotificationType, TandemNotification} from "./modules/tandem-core/models/tandem-notification";
import {NotificationService} from "./modules/tandem-core/services/notification.service";
import {UserService} from "./modules/auth/user.service";
import {Title} from "@angular/platform-browser";
import {StripeService} from "ngx-stripe";
import {StripeOperationsService} from "./modules/stripe/services/stripe-operations.service";
import firebase from "firebase/compat";
import {Platform} from "@angular/cdk/platform";
import {StatusBar, Style} from "@capacitor/status-bar";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    slideInAnimation
  ]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('sidePanelContent', { read: ViewContainerRef }) sidePanelContainer?: ViewContainerRef;


  user: TandemUser | null = null;
  displayHeader = false;

  displaySidenav: boolean = true;
  displayNavbar: boolean = false;

  screenIsSmall = false;

  sidenavState: SidenavState = 'collapsed';

  disableAllNav = false;
  showProfileDropdown = false;

  bannerText?: string;

  notifications: TandemNotification[] = [
    // {
    //   id: '1',
    //   userId: 'user1',
    //   sourceUserName: 'John Doe',
    //   date: Timestamp.now(),
    //   content: 'You have a new message',
    //   isRead: false,
    //   type: NotificationType.DirectMessage,
    //   dateCreated: Timestamp.now(),
    //   dateModified: Timestamp.now(),
    // },
    // {
    //   id: '2',
    //   userId: 'user1',
    //   sourceUserName: 'Jane Smith',
    //   date: Timestamp.now(),
    //   content: 'Jane mentioned you in a comment',
    //   isRead: false,
    //   type: NotificationType.MentionInComment,
    //   dateCreated: Timestamp.now(),
    //   dateModified: Timestamp.now(),
    // },
    // {
    //   id: '3',
    //   userId: 'user1',
    //   sourceUserName: 'System',
    //   date: Timestamp.now(),
    //   content: 'Your account has been verified',
    //   isRead: true,
    //   type: NotificationType.SystemAlert,
    //   dateCreated: Timestamp.now(),
    //   dateModified: Timestamp.now(),
    // },
    // Add more hardcoded notifications as needed
  ];

  showNotificationsDropdown = false;

  navSections: {
    label?: string;
    items: { label: string; routerLink: string }[];
    authProp: string | null
  }[] = [
    {
      label: 'Tracking Tools',
      items: [
        {
          label: 'Financial Positions',
          routerLink: 'financial-positions'
        },
        {
          label: 'Cash Flows',
          routerLink: 'cash-flows'
        },
        {
          label: 'Spending Plans',
          routerLink: 'spending-plans'
        },
      ],
      authProp: 'trackingTools'
    },
    {
      label: 'Calculators',
      items: [
        {
          label: 'Debt Payoff',
          routerLink: 'calculators/debt-payoff'
        },
        {
          label: 'Investment',
          routerLink: 'calculators/investment'
        },
        {
          label: 'Debt Vs. Invest',
          routerLink: 'calculators/debt-vs-invest'
        },
      ],
      authProp: null
    },
    {
      label: 'Coaching',
      items: [
        {
          label: 'User Management',
          routerLink: 'coaching/user-management'
        },
      ],
      authProp: 'isCoach'
    },
    {
      label: 'Admin',
      items: [
        {
          label: 'Coach Management',
          routerLink: 'admin/approve-coaches'
        }
      ],
      authProp: 'isAdmin'
    }
  ]
  headerConfig?: HeaderConfig;
  disableHeader = false;

  fpProp = 'Financial Positions';
  cfProp = 'Cash Flows';
  spProp = 'Spending Plans';

  currentEnvironment = environment.environmentName;

  displayDefaultLogo = true;

  currentDomain: string = 'platform.tandemfinance.app';

  private sidePanelSubscription?: Subscription;

  whiteIconPath: string = environment.whiteIconPath;
  primaryIconPath: string = environment.primaryIconPath;
  logoPath: string = environment.logoPath;
  whiteLogoPath: string = environment.whiteLogoPath;

  openedChat: string | null = null;

  openChatForUser$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  coachVideoLink: string | null = null;

  coach: TandemUser | null = null;
  claims: Record<string, any> = {};

  constructor(
    private platform: Platform,
    private router: Router,
    private dialogService: DialogService,
    private headerService: HeaderService,
    private themingService: ThemingService,
    private auth: AuthService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private userService: UserService,
    private title: Title,
    private stripeService: StripeService,
    private stripeOpsService: StripeOperationsService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    });
    this.initializeMobile();
  }
  async initializeMobile() {
    if (this.platform.IOS) {
      // Set the status bar style for iOS
      await StatusBar.setStyle({ style: Style.Light });

      // Make the status bar overlay the webview (important for safe area)
      await StatusBar.setOverlaysWebView({ overlay: false });
    }
  }

  ngOnInit(): void {

    this.auth.$customClaims.subscribe(claims => {
      this.claims = claims;
    })
    this.auth.$authState.subscribe(auth => {
      console.log(auth);
      if (auth.authStatus === AuthStatus.VerifiedAndDocExists) {
        console.log('setting everything')
        this.disableAllNav = false
        this.displayHeader = true
        this.displayNavStack = true
      }
    })
    // this.themingService.setTheme({
    //   headerFont: environment.defaultHeaderFont,
    //   bodyFont: environment.defaultBodyFont,
    //   primaryColor: environment.defaultTheme,
    // });
    // Check if the host is "platform.tandemfinance.app" and there is no user
    if (['platform.tandemfinance.app', 'tst.tandemfinance.app', 'localhost:4200'].includes(window.location.host) && !this.user) {
      this.applyDefaultTheme();
    } else {
      this.themingService.checkForThemeUpdate(window.location.host);
    }

     if (!environment.production) {
      this.bannerText = `1.1.5`;
      this.title.setTitle(`[${environment.environmentName}] - Tandem Platform`)
     }

     if (window.location.host.includes('lascarare')) {
       this.title.setTitle(`The Elite Investor`);
     }
    // this.checkScreenSize();
    combineLatest([
      this.auth.$user,
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd), // only consider NavigationEnd events
        map(() => this.router.url) // extract the URL
      )
    ]).subscribe(([user, currentUrl]) => {
      this.user = user;
      if (this.user?.primaryColor) {
        this.themingService.setTheme({
          primaryColor: this.user.primaryColor,
          bodyFont: this.user.bodyFont || environment.defaultBodyFont,
          headerFont: this.user.headerFont || environment.defaultHeaderFont,
          logoURL: this.user.logoURL || environment.logoPath,
          iconURL: this.user.iconURL || environment.primaryIconPath
        });
      }
      if (this.user?.logoURL) {
        this.whiteLogoPath = this.user.logoURL;
      }
      if (this.user?.iconURL) {
        this.whiteIconPath = this.user.iconURL;
      }
      this.navSections.forEach(section => {
        section.items.forEach(item => {
          if (item.label === 'Financial Positions') {
            item.label = user?.customTTMetadata?.fpNamePlural || 'Financial Positions';
            this.fpProp = user?.customTTMetadata?.fpNamePlural || 'Financial Positions';
          } else if (item.label === 'Cash Flows') {
            item.label = user?.customTTMetadata?.cashFlowNamePlural || 'Cash Flows';
            this.cfProp = user?.customTTMetadata?.cashFlowNamePlural || 'Cash Flows';
          } else if (item.label === 'Spending Plans') {
            item.label = user?.customTTMetadata?.spendingPlanNamePlural || 'Spending Plans';
            this.spProp = user?.customTTMetadata?.spendingPlanNamePlural || 'Spending Plans';
          }
        });
      });
      if (this.user) {
        this.notificationService.getNotificationsForUser(this.user.uid).subscribe(notifications => {
          if (this.openedChat) {
            const notificationsToDelete = notifications.filter(
              n => n.type === NotificationType.DirectMessage && n.sourceUserId === this.openedChat
            );

            notificationsToDelete.forEach(n => {
              this.notificationService.delete(n.id!);
            });

            notifications = notifications.filter(
              n => !(n.type === NotificationType.DirectMessage && n.sourceUserId === this.openedChat)
            );
          }
          this.notifications = notifications;
        });
        if (this.user.videoCallLink) {
          this.coachVideoLink = this.user.videoCallLink;
        } else if (this.user.coachId) {
          this.userService.get(this.user.coachId).subscribe(coach => {
            this.coachVideoLink = coach.videoCallLink || null;
            this.coach = coach
          })
        }
      }
      if (!this.user) {
        // this.themingService.setTheme({
        //   primaryColor: environment.defaultTheme,
        //   bodyFont: environment.defaultBodyFont,
        //   headerFont: environment.defaultHeaderFont,
        //   logoURL: environment.logoPath,
        //   iconURL: environment.primaryIconPath
        // });
      }

      const setupRoutes: string[] = [
        'auth/email-not-verified',
        'auth/additional-info',
        'auth/register',
        'auth/login',
        'auth/coach-registration',
        'stripe/payment-success',
        'file-management/preview'
      ]

      const isSetupRoute = setupRoutes.some(route => currentUrl.includes(route));



      this.displayDefaultLogo = this.route.snapshot.paramMap.get('coachId') === null;
    });

    this.headerService.getConfig().subscribe(config => {
      this.headerConfig = config;
    })

    this.sidePanelSubscription = this.dialogService.sidePanelContent$.subscribe(
      (content) => {
        this.showSidePanel = !!content;
      }
    );

    // Initial check for screen size
    // this.checkScreenSize();
  }

  // checkScreenSize(): void {
  //   if (!this.disableAllNav) {
  //     this.screenIsSmall = window.innerWidth < 1440;
  //     this.displaySidenav = window.innerWidth >= 1440;
  //     this.displayNavbar = window.innerWidth < 1440;
  //     if (this.screenIsSmall) {
  //       this.sidenavState = "closed";
  //     } else {
  //       this.sidenavState = 'collapsed';
  //     }
  //   } else {
  //     this.displaySidenav = false;
  //     this.displayHeader = false;
  //     this.displayNavbar = false;
  //   }
  // }

  ngAfterViewInit() {
    if (!!this.sidePanelContainer) {
      this.dialogService.setViewContainerRef(this.sidePanelContainer);
    }
  }

  ngOnDestroy() {
    this.sidePanelSubscription?.unsubscribe();
  }

  protected readonly environment = environment;
  showTWSidebar = false;
  displayNavStack = false;
  showTooltip: string | null = null;
  showSidePanel = true;

  logout() {

    if (this.localStorageService.getItem('createFinancialPosition')
      || this.localStorageService.getItem('createCashFlow')
      || this.localStorageService.getItem('createSpendingPlan')) {
      let modalConfig: TandemDialogConfig = {
        title: 'Sign Out',
        type: 'confirm',
        content: `Are you sure you want to sign out of your account? You will lose progress on any unsaved Tracking Tools.`,
        actions: [
          {
            text: 'Cancel',
            role: 'cancel',
            callback: () => {
              this.dialogService.closeModal2();
            }
          },
          {
            text: 'Sign Out',
            role: 'confirm',
            callback: () => {
              this.dialogService.closeModal2();
              this.auth.onLogout(true);
            }
          }
        ]
      }
      this.dialogService.openModal2(modalConfig);
    } else {
      this.auth.onLogout(true);
    }

  }

  closeDropdown() {
    this.showProfileDropdown = false;
  }

  toggleDropdown() {
    this.showProfileDropdown = !this.showProfileDropdown;
  }

  onCollapse() {
    this.sidenavState = 'collapsed';
  }

  toggleSidenavState() {
    if (this.sidenavState === 'collapsed') {
      this.sidenavState = 'expanded';
    } else {
      this.sidenavState = 'collapsed';
    }
  }

  toggleProfileDropdown() {
    this.showProfileDropdown = !this.showProfileDropdown;
    this.showNotificationsDropdown = false;
  }

  toggleNotificationsDropdown() {
    this.showNotificationsDropdown = !this.showNotificationsDropdown;
    this.showProfileDropdown = false;
  }

  closeDropdowns() {
    this.showProfileDropdown = false;
    this.showNotificationsDropdown = false;
  }

  getUnreadNotificationsCount(): number {
    return this.notifications.filter(n => !n.isRead).length;
  }

  markAsRead(notification: TandemNotification) {
    if (notification.link) {
      this.router.navigate([notification.link]).then(res => {
        notification.isRead = true;
        this.notificationService.update(notification).then(updated => {

        })
        this.closeDropdowns();

      })
    } else if (notification.type === NotificationType.DirectMessage && notification.sourceUserId) {
      this.openChatForUser$.next(notification.sourceUserId);
      this.closeDropdowns();
    }
    // Here you would typically update the notification in your backend
  }

  showTTPaymentMessage() {
    this.dialogService.openModal2({
      type: 'failure',
      title: 'Full Access Required',
      content: `Since you're on the free version of the app, you don't have access to our Tracking Tools. Click below to join!`,
      actions: [
        {
          text: 'Close',
          role: 'close',
          callback: () => this.dialogService.closeModal2()
        },
        {
          text: 'Get Full Access',
          callback: () => this.openStripe(),
          role: 'confirm'
        }
      ]
    })
  }

  openStripe() {
    if (this.user?.status === 'Orphaned') {
      this.router.navigate(['profile'])
    } else {
        if (this.user?.accountType === 'user' && this.coach?.coachType === 'coachRevShare') {
          // handle as user of Rev Share Coach
          this.stripeOpsService.openStripeForUserOfRSCoach().subscribe((response) => {
            this.stripeService.redirectToCheckout({ sessionId: response.sessionId }).subscribe(stripe => {})
          });
        } else if (this.user?.accountType === 'coach' && this.user.coachType === 'coachRevShare') {
          // handle as rev share coach
          this.stripeOpsService.openStripeForRSCoach(this.user).subscribe(() => {})
        } else if (this.user?.accountType === 'coach' && this.user.coachType === 'coachLeadGen') {
          // handle as lead gen coach
          this.stripeOpsService.openStripeForLGCoach(this.user).subscribe((response: any) => {
            this.stripeService.redirectToCheckout({ sessionId: response.sessionId }).subscribe(stripe => {})
          });
        }
        // this.router.navigate(['auth/profile']).then(routed => {
        //   this.stripeOpsService.openStripeConnectForRevShareCoach().subscribe(res => {
        //     console.log(res)
        //   })
        // })

    }
  }

  // markAsRead(notification: TandemNotification) {
  //   if (notification.link) {
  //
  //     if (notification.type === NotificationType.MentionInComment) {
  //       this.navigateToNotificationLink(notification.link);
  //     } else {
  //       this.router.navigate([notification.link]).then(res => {
  //         notification.isRead = true;
  //         this.notificationService.update(notification).then(updated => {
  //
  //         })
  //         this.closeDropdowns();
  //
  //       })
  //     }
  //   }
  //   // Here you would typically update the notification in your backend
  // }
  // TODO WIP
  // navigateToNotificationLink(notification: TandemNotification) {
  //   try {
  //     const routeConfig = JSON.parse(serializedRoute);
  //     if (routeConfig.reviewedUser) {
  //       this.router.navigate(['/financial-positions', routeConfig.financialPositionId], {
  //         queryParams: { assetCategory: routeConfig.assetCategory, asset: routeConfig.asset }
  //       }).then(res => this.resolveNotification(notification));
  //     } else {
  //       this.router.navigate(['/coaching/user-management', routeConfig.userId, 'financial-positions', routeConfig.financialPositionId], {
  //         queryParams: { assetCategory: routeConfig.assetCategory, asset: routeConfig.asset }
  //       }).then(res => this.resolveNotification(notification));
  //     }
  //
  //   } catch (error) {
  //     console.error('Error parsing notification link:', error);
  //   }
  // }
  //
  // resolveNotification(notification: TandemNotification) {
  //
  // }

  closeSidePanel() {
    this.dialogService.closeSidePanel();
  }

  markAllNotificationsAsRead() {
    if (this.user) {
      this.notificationService.markAllNotificationsAsRead(this.user.uid).then(r => {});
    }
  }

  deleteAllNotifications() {
    if (this.user) {
      this.notificationService.deleteAllNotificationsForUser(this.user.uid).then(r => {this.showNotificationsDropdown = false;});
    }
  }

  deleteNotification(notification: TandemNotification) {
    if (notification.id) {
      this.notificationService.delete(notification.id).then();
    }
  }

  applyDefaultTheme() {
    this.themingService.setTheme({
      primaryColor: environment.defaultTheme,
      bodyFont: environment.defaultBodyFont,
      headerFont: environment.defaultHeaderFont,
      logoURL: environment.logoPath,
      iconURL: environment.whiteIconPath
    });
    this.whiteLogoPath = environment.logoPath;
    this.whiteIconPath = environment.whiteIconPath;
    this.displayDefaultLogo = true;
  }

  handleChangedChat(userId: string | null) {
    this.openedChat = userId;

    if (userId) {
      // Delete all DM notifications related to the opened chat user
      this.deleteDirectMessageNotifications(userId);
    }
  }

  private deleteDirectMessageNotifications(userId: string) {
    const notificationsToDelete = this.notifications.filter(
      notification => notification.type === NotificationType.DirectMessage && notification.sourceUserId === userId
    );

    notificationsToDelete.forEach(notification => {
      // Assuming you have a service to handle notification deletions
      this.notificationService.delete(notification.id!).then();
    });
  }

  handleTrackingToolNav(ttType: 'fp' | 'cf' | 'sp') {

    if (this.showTWSidebar) {
      this.showTWSidebar = false;
    }
    if (this.user?.accountType === 'user' && this.user.status !== 'Active') {
      this.showTTPaymentMessage();
    } else {
      const route = ttType === 'fp' ? 'financial-positions' : ttType === 'cf' ? 'cash-flows' : 'spending-plans';
      this.router.navigate([`${route}`])
    }
  }
}
