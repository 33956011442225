<div class="fixed bottom-4 right-4 flex items-end z-50" *ngIf="user">
  <!-- User Selection -->
  <div *ngIf="showUserSelection" class="w-full sm:w-80 bg-white rounded-lg shadow-lg p-4 mr-2 flex flex-col" style="height: 400px;">
    <div class="flex justify-between items-center mb-3">
      <h3 class="text-lg font-semibold">New chat</h3>
      <button (click)="closeUserSelection()" class="text-gray-500 hover:text-gray-700">
        <tandem-icon [icon]="'x'" color="black"></tandem-icon>
      </button>
    </div>
    <div class="mb-3">
      <tandem-form-input [autoFocus]="true" [form]="userSearchForm" [controlName]="'username'" [placeholder]="'Name'"></tandem-form-input>
    </div>
    <div class="flex-grow overflow-y-auto">
      <div *ngFor="let user of filteredUsers"
           (click)="selectUser(user)"
           class="flex items-center p-2 hover:bg-gray-100 cursor-pointer">
        <img *ngIf="user.photoURL" [src]="user.photoURL" [alt]="user.firstName" class="w-10 h-10 rounded-full mr-3">
        <div *ngIf="!user.photoURL" class="h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center mr-3">
          <span class="text-lg font-semibold text-primary">{{ user.displayName.split(' ')[0][0] }}{{ user.displayName.split(' ')[1][0] }}</span>
        </div>
        <span>{{user.firstName}} {{user.lastName}}</span>
      </div>
    </div>
  </div>

  <!-- Chat window and user circles -->
  <div class="flex" *ngIf="chatWindowExpanded">
    <!-- Chat window -->
    <div *ngIf="selectedChat" class="w-full sm:w-96 bg-white rounded-lg shadow-lg overflow-hidden flex flex-col mr-2">
      <!-- Chat header -->
      <div class="p-4 bg-primary text-white flex items-center justify-between">
        <div class="flex items-center">
          <img *ngIf="chats[selectedChat].userPhotoURL" class="h-10 w-10 rounded-full mr-3" [src]="chats[selectedChat].userPhotoURL" [alt]="chats[selectedChat].userName">
          <div *ngIf="!chats[selectedChat].userPhotoURL" class="h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center mr-3">
            <span class="text-lg font-semibold text-primary">{{ chats[selectedChat].userName.split(' ')[0][0] }}{{ chats[selectedChat].userName.split(' ')[1][0] }}</span>
          </div>
          <div>
            <h2 class="font-semibold">{{chats[selectedChat].userName}}</h2>
            <p class="text-xs opacity-75">Last Seen {{chats[selectedChat].lastSeen | date:'MMM d, y'}}</p>
          </div>
        </div>
        <div class="flex items-center space-x-2">
          <button *ngIf="availableUsers && availableUsers.length > 1" (click)="minimizeChat(selectedChat)" class="text-white hover:text-gray-200">
            <tandem-icon [icon]="'minus'" color="white"></tandem-icon>
          </button>
          <button (click)="closeChat(selectedChat)" class="text-white hover:text-gray-200">
            <tandem-icon [icon]="'x'" color="white"></tandem-icon>
          </button>
        </div>
      </div>

      <!-- Chat messages -->
      <div #chatContainer class="flex-grow overflow-y-auto p-4" style="height: calc(100vh - 250px); max-height: 500px;">
        <div *ngFor="let message of chats[selectedChat].messages" class="mb-4 flex" [ngClass]="{'justify-end': message.senderId === user.uid}">
          <div class="max-w-[70%] flex flex-col" [ngClass]="{'items-end': message.senderId === user.uid}">
            <div class="inline-block rounded-lg py-2 px-3" [ngClass]="{
              'bg-blue-500 text-white rounded-br-none': message.senderId === user.uid,
              'bg-gray-200 text-gray-800 rounded-bl-none': message.senderId !== user.uid
            }">
              {{ message.message }}
            </div>
            <div class="text-xs text-gray-500 mt-1">
              {{ message.date.toDate() | date:'short' }}
              <span *ngIf="message.senderId === user.uid"> · {{ message.status }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Chat input -->
      <div class="border-t p-3">
        <form [formGroup]="chats[selectedChat].chatForm" (ngSubmit)="sendMessage(selectedChat)" class="flex items-center">
          <tandem-form-input class="flex-grow mr-2" [form]="chats[selectedChat].chatForm" controlName="message" placeholder="Type a message..." [hideErrorMessage]="true"></tandem-form-input>
          <tandem-button type="submit" [disabled]="chats[selectedChat].chatForm.invalid" class="px-4 py-2 text-white rounded-full">Send</tandem-button>
        </form>
      </div>
    </div>

    <!-- User circles -->
    <div class="flex flex-col justify-end space-y-2 items-center">
      <ng-container *ngIf="!coach">
        <ng-container *ngFor="let chat of chats | keyvalue">
          <div (click)="selectChat(chat.key)" class="w-12 h-12 rounded-full bg-primary text-white flex items-center justify-center cursor-pointer shadow-md hover:shadow-lg transition-shadow relative" [ngClass]="{'ring-2 ring-blue-500': selectedChat === chat.key}">
<!--            <div class="absolute top-0 right-0 w-5 h-5 bg-gray-50 text-black text-xs font-bold flex items-center justify-center rounded-full shadow-md transform border border-black translate-x-1/2 -translate-y-1/2">-->
<!--              X-->
<!--            </div>-->
            <img *ngIf="chat.value.userPhotoURL" class="w-full h-full rounded-full object-cover" [src]="chat.value.userPhotoURL" [alt]="chat.value.userName">
            <span *ngIf="!chat.value.userPhotoURL" class="text-lg font-semibold">
            {{ chat.value.userName.split(' ')[0][0] }}{{ chat.value.userName.split(' ')[1][0] }}
          </span>
          </div>
        </ng-container>
      </ng-container>
      <div (click)="showNewChatSelection()" class="w-16 h-16 rounded-full bg-primary text-white flex items-center justify-center cursor-pointer shadow-md hover:shadow-lg transition-shadow mt-2">
        <tandem-icon [icon]="showCancelForUser ? 'x' : 'chat-bubble'" color="white"></tandem-icon>
      </div>
    </div>
  </div>
</div>
