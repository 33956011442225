import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'head-item',
  templateUrl: './head-item.component.html',
})
export class HeadItemComponent implements OnInit {
  @Input() type?: 'draggable' | '';
  @Input() hasCheckbox?: boolean = false;
  @Input() hasMeatball?: boolean = true;
  @Input() sortable: boolean | undefined = false;
  @Input() sorted: boolean | undefined = false;
  @Input() sortedByDefault: boolean = false;

  sort: undefined | 'asc' | 'desc' = undefined;

  @Output() onSort: EventEmitter<'asc' | 'desc' | undefined> = new EventEmitter<'asc' | 'desc' | undefined>();

  constructor() {
  }

  ngOnInit(): void {
    if (this.sortedByDefault) {
      this.triggerSort();
    }
  }
  triggerSort() {
    if (this.sort === undefined) {
      this.sort = 'desc';
    } else if (this.sort === 'desc') {
      this.sort = 'asc';
    } else {
      this.sort = 'desc'
    }
    this.onSort.emit(this.sort);
  }
}
