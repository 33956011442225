import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {AuthService} from "../../../modules/auth/services/auth.service";
import {IconType} from "../../../modules/tandem-core/components/icons/icon-type";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {DialogService} from "../../../modules/tandem-core/services/dialog.service";
import {PaymentService} from "../../../modules/stripe/services/payment-service";

export type SidenavState = 'collapsed' | 'expanded' | 'closed';
export type NavSection = { title: string, items: { icon: IconType, label: string, routerLink: string, authProp: string }[]};
@Component({
  selector: 'tandem-sidenav2',
  templateUrl: './sidenav2.component.html',
  styleUrls: ['./sidenav2.component.scss']
})
export class Sidenav2Component implements OnInit, OnChanges {

  @Input() sidenavState: SidenavState = "collapsed";

  sections: NavSection[] = [];
  sections$: Observable<any[]> = new Observable<any[]>();

  ttItems: any[] = [];
  calculatorItems: any[] = [];
  coachingItems: any[] = [];

  previewTrackingTools: boolean = false;


  @Output() sidenavUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onToggle: EventEmitter<void> = new EventEmitter<void>();

  constructor(private auth: AuthService,
              private afAuth: AngularFireAuth,
              private router: Router,
              private dialog: DialogService,
              private paymentService: PaymentService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sidenavState']) {
      const prevValue = changes['sidenavState'].previousValue;
      const currentValue = changes['sidenavState'].currentValue;

      // Perform operations based on the change
      if (currentValue !== prevValue) {
        // Do something when sidenavState changes
        this.handleSidenavStateChange(currentValue);
      }
    }
  }

  handleSidenavStateChange(newState: SidenavState) {
    this.sidenavState = newState;
  }

  ngOnInit(): void {
    this.afAuth.currentUser.then(user => {
      user?.getIdTokenResult().then(token => {
        this.previewTrackingTools = !token.claims['trackingTools'];
      })
    });

    // this.auth.$userPerms.subscribe(newPerms => {
    //   this.afAuth.currentUser.then(user => {
    //     user?.getIdTokenResult().then(token => {
    //       this.previewTrackingTools = !token.claims['trackingTools'];
    //     })
    //   });
    // })

    this.auth.$user.subscribe(user => {
      this.collapseSidenav();
      this.ttItems = [
        {
          icon: 'financialPosition',
          label: user?.customTTMetadata?.fpNamePlural ? `${user.customTTMetadata.fpNamePlural}` : 'Financial Positions',
          routerLink: 'financial-positions',
          authProp: '*',
        },
        {
          icon: 'cashFlow',
          label: user?.customTTMetadata?.cashFlowNamePlural ? `${user.customTTMetadata.cashFlowNamePlural}` : 'Cash Flows',
          routerLink: 'cash-flows',
          authProp: '*',
        },
        {
          icon: 'spendingPlan',
          label: user?.customTTMetadata?.spendingPlanNamePlural ? `${user.customTTMetadata.spendingPlanNamePlural}` : 'Spending Plans',
          routerLink: 'spending-plans',
          authProp: '*',
        },
      ];
      this.calculatorItems = [
        {
          icon: 'graphDown',
          label: 'Debt Payoff Calculator',
          routerLink: 'calculators/debt-payoff',
          authProp: 'calculators',
        },
        {
          icon: 'graphUp',
          label: 'Investment Calculator',
          routerLink: 'calculators/investment',
          authProp: 'calculators',
        },
        {
          icon: 'lineGraph',
          label: 'Debt Vs. Invest',
          routerLink: 'calculators/debt-vs-invest',
          authProp: 'calculators',
        },
      ];
      this.coachingItems = [
        {
          icon: 'userMultiple',
          label: 'User Management',
          routerLink: 'coaching/user-management',
          authProp: 'isCoach',
        },
      ];
    })
    }

  collapseSidenav() {
    if (this.sidenavState === 'expanded') {
      this.onToggle.emit();
    }
  }

  toggleExpanded() {
    this.onToggle.emit();
    // this.sidenavUpdated.emit(this.isExpanded);
  }

  protected readonly environment = environment;

  async disableTrackingTools(section: NavSection) {
    // Check if the section title is 'Tracking Tools'
    if (section.title !== 'Tracking Tools') {
      return false;
    }

    try {
      // Get the current user
      const user = await this.afAuth.currentUser;
      if (user) {
        // Get the ID token result
        const token = await user.getIdTokenResult();
        let userClaims = token.claims;

        // Check if 'trackingTools' claim exists
        let hasTrackingTools = userClaims.hasOwnProperty('trackingTools');

        // Return true if 'trackingTools' claim does not exist
        return !hasTrackingTools;
      }
      // Return false if there is no user
      return false;
    } catch (error) {
      return false;
    }
  }

  onRoute(routerLink: string) {
    this.collapseSidenav();
    if (!this.previewTrackingTools) {
      this.router.navigate([routerLink])
    } else {
      this.dialog.openConfirmDialog('Premium Feature', `The feature you're requesting is reserved for premium users. If you're ready to invest in yourself and your financial future, click "Get Premium" to unlock access today`, 'Get Premium', 'Cancel', false, false, true).afterClosed().subscribe(confirmed => {
        if (confirmed) {
          this.router.navigate(['auth/profile']).then(routed => {
            this.paymentService.openStripe();
          })
        }
      })
    }
  }
}
