import { Component } from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'tandem-grid',
  templateUrl: './grid.html',
})
export class GridComponent {

  hidden = true;
  constructor() {
    this.createGridHotKey();
  }

  createGridHotKey() {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'g' && environment.enableGridDisplay) {
        // Click G key to toggle grid
        this.hidden = !this.hidden;
      }
    });
  }
}
