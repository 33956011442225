<div *ngIf="user" class="flex min-h-full flex-col justify-center py-12 px-5 sm:px-6 lg:px-8" style="height: 100vh">
  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
    <div class="bg-white px-6 py-12 shadow rounded-lg sm:px-12">
      <div style="min-height: 300px" class="flex flex-col justify-center items-center gap-30">
        <h2 (click)="isLoaded = !isLoaded" *ngIf="!isLoaded" class="w-full text-center mb-8">It looks like you've set up your Stripe Connect account! One moment while we activate your subscription.</h2>
        <tandem-loading-container [isLoaded]="isLoaded">
          <h4 *ngIf="errorMessage" class="text-red">{{errorMessage}}</h4>
          <h4 *ngIf="!errorMessage">Success! <a [routerLink]="'/dashboard'">Go to Dashboard</a></h4>
        </tandem-loading-container>
      </div>
    </div>
  </div>
</div>
