<tandem-loading-container [isLoaded]="!isLoading">
  <form [formGroup]="contactForm" class="flex flex-col w-full" (ngSubmit)="onFormSubmit()">
    <h2 class="text-center">Contact Support</h2>
    <tandem-form-input class="w-full"
                       [form]="contactForm"
                       [label]="'Email'"
                       [inputId]="'email'"
                       [controlName]="'email'"
                       [placeholder]="'Email'"></tandem-form-input>
    <tandem-form-input class="w-full"
                       [form]="contactForm"
                       [label]="'Subject'"
                       [inputId]="'subject'"
                       [controlName]="'subject'"
                       [placeholder]="'Subject'"></tandem-form-input>
    <tandem-form-textarea class="w-full"
                          [form]="contactForm"
                          [label]="'Message'"
                          [inputId]="'message'"
                          [controlName]="'message'"
                          [placeholder]="'Message'" [cols]="10"></tandem-form-textarea>

    <div class="flex flex-row w-full justify-center gap-4 mt-4">
      <tandem-button [onClick]="onCancel.bind(this)">Cancel</tandem-button>
      <tandem-button type="submit" theme="solid" [onClick]="onFormSubmit.bind(this)" [disabled]="contactForm.invalid">Save</tandem-button>
    </div>
  </form>
</tandem-loading-container>
