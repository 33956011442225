import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {filter, Observable, of} from 'rxjs';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {AuthService, AuthStatus} from "../services/auth.service";
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  tandemDomains: string[] = [
    'tandemfinance.app',
    'platform.tandemfinance.app',
    'tst.tandemfinance.app',
    'dev.tandemfinance.app',
    // 'localhost'
  ];
  hostedDomains: string[] = [
    'www.portal.lascarare.com',
    'portal.lascarare.com'
  ];

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.$authState.pipe(
      filter(authState => authState.authInitialized), // Ensure Firebase state is loaded
      take(1),
      map(authState => {
        const redirectPath = this.getRedirectPath();
        switch (authState.authStatus) {
          case AuthStatus.VerifiedAndDocExists:
            return true;

          case AuthStatus.DocNotCreated:
            if (!this.isOnCorrectRegistrationPage(state.url)) {
              this.router.navigate([redirectPath]);
              return false;
            }
            return true;

          case AuthStatus.EmailNotVerified:
            if (!this.isOnCorrectRegistrationPage(state.url)) {
              this.router.navigate([redirectPath]);
              return false;
            }
            return true;

          case AuthStatus.NotLoggedIn:
            if (!state.url.includes('/auth/login') && !this.isOnCorrectRegistrationPage(state.url)) {
              this.router.navigate([redirectPath]);
              return false;
            }
            return true;

          default:
            return false;
        }
      })
    );
  }

  private isTandemDomain(): boolean {
    const currentHost = window.location.hostname;
    console.log(currentHost)
    return this.tandemDomains.includes(currentHost);
  }

  private getRedirectPath(): string {
    return this.isTandemDomain() ? '/auth/new-coach-registration' : '/auth/new-registration';
  }

  private isOnCorrectRegistrationPage(currentUrl: string): boolean {
    const redirectPath = this.getRedirectPath();
    return currentUrl === redirectPath;
  }

}
