import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogService} from "../../../tandem-core/services/dialog.service";
import {ThemingService} from "../../../tandem-core/services/theming.service";
import {Subscription} from "rxjs";
import {RegistrationAttemptService} from "../../services/registration-attempt.service";
import {Timestamp} from "firebase/firestore";
import {AuthService, AuthStatus} from "../../services/auth.service";

@Component({
  selector: 'tandem-new-registration',
  templateUrl: './new-registration.component.html',
  styleUrls: ['./new-registration.component.scss']
})
export class NewRegistrationComponent implements OnInit {

  PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
  public authForm: FormGroup = new FormGroup<any>({});
  public infoForm: FormGroup = new FormGroup<any>({});

  lightMode = true;

  errorMessage: string | null = null;
  envName: string = environment.environmentName;
  coachId: string | null = null;
  preventButtonPress = false;

  passwordErrorMessage: string | null = null;

  whiteIconPath: string = environment.whiteIconPath;
  primaryIconPath: string = environment.primaryIconPath;
  logoPath: string = environment.logoPath;
  whiteLogoPath: string = environment.whiteLogoPath;

  themeLoaded= false;

  authInitialized = false

  activeTab: 'auth' | 'verify' | 'info' = 'auth';

  private authSub?: Subscription;

  currentCalculator: number = 0;
  currentTrackingTool: number = 0;

  landingPageConfig: LandingPageConfig | null = null;
  tandemDomains: string[] = [
    'tandemfinance.app',
    'platform.tandemfinance.app',
    // 'tst.tandemfinance.app',
    'dev.tandemfinance.app',
    // 'localhost'
  ];

  hostedDomains: string[] = [
    'www.portal.lascarare.com',
    'portal.lascarare.com'
  ];

  domainConfigMap = {
    'localhost': EIMG_LANDING_PAGE_CONFIG,
    'tst.tandemfinance.app': EIMG_LANDING_PAGE_CONFIG,
    'www.portal.lascarare.com': EIMG_LANDING_PAGE_CONFIG,
    'portal.lascarare.com': EIMG_LANDING_PAGE_CONFIG,
  }


  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private dialogService: DialogService,
              private themingService: ThemingService,
              private registrationAttemptService: RegistrationAttemptService,
              private fb: FormBuilder) {

    this.checkDomain();
    this.coachId = this.route.snapshot.queryParamMap.get('coachId');

    this.authSub = this.auth.$authState.subscribe(auth => {
      this.authInitialized = auth.authInitialized;
      if (auth.authStatus === AuthStatus.VerifiedAndDocExists) {
        this.router.navigate(['/dashboard']);
        this.authSub?.unsubscribe();
        return;
      }

      if (auth.authStatus === AuthStatus.NotLoggedIn) {
        this.authForm.reset();
        this.activeTab = 'auth';
      }

      if (auth.authStatus === AuthStatus.EmailNotVerified) {
        this.authForm.reset();
        this.activeTab = 'verify';
        this.auth.pollEmailVerification(5000).subscribe(verified => {
          if (verified) {
            this.auth.forceTokenRefresh();
          }
        });
      }

      if (auth.authStatus === AuthStatus.DocNotCreated) {
        this.infoForm.reset();
        this.activeTab = 'info';
      }
    })
  }

  ngOnInit() {
    this.themingService.brandingConfig.subscribe(config => {
      console.log(config)
      if (config) {
        if (config.logoURL) {
          this.logoPath = config.logoURL
        }
        this.themeLoaded = true;
      }
    })
    this.infoForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phoneNumber: [null, Validators.required],
    });
    this.authForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.pattern(this.PASSWORD_REGEX)]]
    });
    this.authForm.get('password')?.valueChanges.subscribe(pass => {
      if(this.authForm.get('password')?.dirty) {
        this.passwordErrorMessage = this.validatePassword(pass);
      }
    })
  }

  onGoogleLogin() {
    if (!this.preventButtonPress) {
      this.preventButtonPress = true;
      this.auth.signInWithGoogle().then(res => {
      }).catch(err => {
        this.preventButtonPress = false;
      }).finally(() => {
        this.preventButtonPress = false;
      });
    }
  }

  displayError(message: string): void {
    this.errorMessage = message;
    setTimeout(() => {
      this.errorMessage = "";
    }, 3000)
    this.preventButtonPress = false;
  }

  submitInfo() {
    this.dialogService.showLoadingDialog('Please wait, finalizing your account details...');
    this.auth.handleInitialAuth({
      coachId: this.getCoachId(),
      accountType: 'user',
      email: this.authForm.get('email')?.value,
      firstName: this.infoForm.get('firstName')?.value,
      lastName: this.infoForm.get('lastName')?.value,
      phoneNumber: this.infoForm.get('phoneNumber')?.value,
    }).then(res => {
      this.dialogService.closeModal2();
    }).catch(err => {
      this.dialogService.openModal2({
        title: 'Unexpected Error',
        content: `There was a problem in setting up your account. Please try again, and if the issue persists, contact support.`,
        type: 'failure',
        actions: [
          {
            text: 'Close',
            role: 'close',
            callback: this.dialogService.closeModal2
          }
        ]
      })
    })
  }

  registerWithEmail() {
    if (!this.preventButtonPress) {
      this.preventButtonPress = true;
      if (this.authForm.invalid) {
        this.displayError("Please correct the form errors before submitting.");
      } else {
        this.dialogService.showLoadingDialog(`Please wait...`);
        this.registrationAttemptService.add({
          email: this.authForm.get('email')?.value,
          dateModified: Timestamp.now(),
          dateCreated: Timestamp.now(),
          coachId: this.getCoachId(),
          accountType: 'user'
        }).then(regAttempt => {
          this.auth.registerWithEmailPass(
            this.authForm.get('email')?.value,
            this.authForm.get('password')?.value,
          ).then(res => {
            // Handle successful registration
            this.dialogService.closeModal2();
          }).catch(error => {
            this.displayError("Registration failed. Please try again.");
          }).finally(() => {
            this.preventButtonPress = false;
          });
        })
      }
    }
  }

  resend() {
    this.dialogService.showLoadingDialog('Sending another verification link...')
    this.auth.resendEmailVerification().subscribe(res => {
      this.dialogService.openModal2({
        type: 'info',
        title: 'Sent Email',
        content: `Successfully resent your verification email. Please check your inbox for the link. If you're still not seeing the email, please contact support.`,
        actions: [
          {
            text: 'Close',
            role: 'close',
            callback: this.dialogService.closeModal2
          }
        ]
      })
    }, err => {
      this.dialogService.openModal2({
        title: 'Unexpected Error',
        content: `There was a problem in resending your verification email. Please try again, and if the issue persists, contact support.`,
        type: 'failure',
        actions: [
          {
            text: 'Close',
            role: 'close',
            callback: this.dialogService.closeModal2
          }
        ]
      })
    })
  }

  validatePassword(password: string): string | null {
    if (!password) return "Password is required.";
    if (password.length < 8) return "Password must be at least 8 characters long.";
    if (!/[A-Z]/.test(password)) return "Password must contain at least one capital letter.";
    if (!/[!@#$%^&*]/.test(password)) return "Password must contain at least one special character (!@#$%^&*).";
    return null;
  }

  protected readonly environment = environment;
  currentGraph: number = 0;

  goToLogin() {
    this.router.navigate(['/auth/login'], {
      queryParams: { from: 'user' },
      queryParamsHandling: 'merge'
    });
  }


  getCoachId(): string {
    let coachIdMap: {[domain: string]: string} = {
      'www.portal.lascarare.com': 'kfHRctJZveSPQibwafx7Ept09hD2',
      'portal.lascarare.com': 'kfHRctJZveSPQibwafx7Ept09hD2',
      'coach.drbudgets.com': 'ag7SoS5sHfQoefCpAa6vi4T32Yo1',
      'ernst-coaching.tandemfinance.app': 'Ei3UegUkdmWNy8xynNh2iEQnT4z1',
      'localhost:4200': 'J30sviRSpSaAjEGDOeKoPlBELfx1',
      'tst.tandemfinance.app': 'J30sviRSpSaAjEGDOeKoPlBELfx1',
      'tandem-finance-prd.web.app': 'MYxSKUEMKWMORrRTF8lseCQYHhZ2'
    }

    if (coachIdMap[window.location.host]) {
      return coachIdMap[window.location.host];
    }

    if (this.coachId) {return this.coachId};

    return '';
  }

  scrollToElement() {
    const element = document.getElementById('info');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  scrollToSignup() {
    const element = document.getElementById('signUp');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  nextGraph(): void {
    this.currentGraph = (this.currentGraph + 1) % 3; // Loop back after the third graph
  }

  previousGraph(): void {
    this.currentGraph = (this.currentGraph + 2) % 3; // Loop back to the last graph if at the first one
  }

  nextCalculator(): void {
    this.currentCalculator = (this.currentCalculator + 1) % 3; // Loop back after the third graph
  }

  previousCalculator(): void {
    this.currentCalculator = (this.currentCalculator + 2) % 3; // Loop back to the last graph if at the first one
  }

  private checkDomain() {
    const currentHost = window.location.hostname;
    // @ts-ignore
    if (this.domainConfigMap[currentHost]) {
      // @ts-ignore
      this.landingPageConfig = this.domainConfigMap[currentHost] as LandingPageConfig;
    }
  }
}

export interface LandingPageConfig {
  whyHeaderTitle: string;
  whyHeaderBody: string;

  whySection1Title: string;
  whySection1Body: string;

  whySection2Title: string;
  whySection2Body: string;

  whySection3Title: string;
  whySection3Body: string;

  whySection4Title: string;
  whySection4Body: string;

  whySection5Title: string;
  whySection5Body: string;

  whySection6Title: string;
  whySection6Body: string;

  trackingToolHeader: string

  fpName: string
  fpDescription: string

  cfName: string
  cfDescription: string

  spName: string
  spDescription: string

  goalHeader: string;
  goal1Description: string;
  goal2Description: string;
  goal3Description: string;

  calculatorDescription: string;
}

export const DEFAULT_LANDING_PAGE_CONFIG: LandingPageConfig = {
  whyHeaderTitle: 'Here\'s Why Our Money Coaches Love Tandem',
  whyHeaderBody: 'Tandem was built by money coaches, for money coaches. While there are many reasons coaches love Tandem, here are the top reasons coaches choose to implement our software into their coaching business:',

  whySection1Title: 'Passive Income',
  whySection1Body: 'Generate monthly reoccurring subscription income from your users with our revenue sharing Pricing Model.',

  whySection2Title: 'Elevated Branding',
  whySection2Body: 'As a money coach, nothing is more important than your brand! Generate income without pushing someone else\'s product.',

  whySection3Title: 'Email List Growth',
  whySection3Body: 'Rapidly grow your email list with a lead magnet your audience can’t help but sign up for.',

  whySection4Title: 'Client Retention',
  whySection4Body: 'Get \'sticky\' clients. When your clients’ financials live on your website, they can’t help but keep coming back.',

  whySection5Title: 'Enhanced Communication',
  whySection5Body: 'No more emails & shared online folders. Communicate & share files with your clients right from the platform.',

  whySection6Title: 'Advanced Insight',
  whySection6Body: 'No more screensharing or screenshots. Manage your users, see their progress, and propose changes to their financials right from your platform.',

  trackingToolHeader: 'No more guessing for your clients when it comes to money. Right from their dashboard, your clients will see their:',

  fpName: 'Financial Position',
  fpDescription: 'Instant insight into where you stand with your assets and liabilities at a snapshot in time. Quickly determine which assets are working hard, and what debts need to be taken care of.',

  cfName: 'Recent Spending',
  cfDescription: 'See the source of every dollar that comes in the door as income and every dollar that goes out the door as investments or expenses. The ability to import transactions turns this normally tedious task for your client into a quick and easy process.',

  spName: 'Spending Plan',
  spDescription: 'Using insight from their recent cash flow summary and their vision and goals, your clients can create a prioritized spending plan for a future time period. With your guidance, your clients\' goals will systematically get crushed.',

  goalHeader: 'What good are your client\'s money goals if you have no way of tracking their progress? Help them chase down their vision with SMART goals.',
  goal1Description: 'Set short-term, mid-term, and long-term goals.',
  goal2Description: 'Stack up successful goal completions to gain financial momentum.',
  goal3Description: 'Use \'Track my Progress\' for both you and your client to see how close they are to reaching their goals',

  calculatorDescription: 'Take the guesswork out of paying off debt and investing. Both you and your clients can quickly run numbers for their specific circumstances and goals.',
}

export const EIMG_LANDING_PAGE_CONFIG: LandingPageConfig = {
  whyHeaderTitle: 'Here\'s Why Real Estate Entrepreneurs Love the EIMG Finance Portal',
  whyHeaderBody: 'This platform was built by real estate investors for real estate investors. While there are many reasons entrepreneurs love the platform, here are the top reasons investors choose to use our software to manage their business & personal finances.',

  whySection1Title: 'Know your Income',
  whySection1Body: 'If you ask an entrepreneur how much they make, they\'ll likely tell you \'good money\' because they don\'t actually know how much money they\'re putting into their pockets each month.',

  whySection2Title: 'See your Growth',
  whySection2Body: 'You know what\'s awesome? Having data that shows your growth in your top-line revenue, your take-home profit, and your net worth.',

  whySection3Title: 'Portfolio Rebalancing',
  whySection3Body: 'You\'re working hard in your business, but is your money? Propose changes to your financial position to optimize your portfolio and passive income.',

  whySection4Title: 'Plan to Pay Yourself First',
  whySection4Body: 'Are you paying yourself first? Or are you taking the leftover after you pay your vendors and your team?',

  whySection5Title: 'Debt & Invest Calculators',
  whySection5Body: 'Strategizing a plan to invest or pay off debt? Put your plan together using the calculators in the portal.',

  whySection6Title: 'Resources at the Ready',
  whySection6Body: 'Get quick access to all of the community resources and contacts right from the portal.',

  trackingToolHeader: 'No more guessing when it comes to your money. Right from your dashboard, you will see your:',

  fpName: 'Financial Position',
  fpDescription: 'Instant insight into where you stand with your assets and liabilities at a snapshot in time. Quickly determine which assets are working hard, and what debts need to be taken care of.',

  cfName: 'Recent Spending',
  cfDescription: 'See the source of every dollar that comes in the door as income and every dollar that goes out the door as investments, business expenses, or personal expenses. The ability to import transactions turns this normally tedious task into a quick and easy process.\n',

  spName: 'Spending Plan',
  spDescription: 'Using insight from your recent cash flow summary and your vision and goals, you can create a prioritized spending plan for a future time period. Follow the plan and systematically crush your goals.',

  goalHeader: 'What good are your money goals if you have no way of tracking your progress? Chase down your vision with SMART goals.',
  goal1Description: 'Set short-term, mid-term, and long-term goals.',
  goal2Description: 'Stack up successful goal completions to gain financial momentum.',
  goal3Description: 'Use \'Track my Progress\' to see how close you are to reaching your goals',

  calculatorDescription: 'Take the guesswork out of paying off debt and investing. Quickly run numbers for your specific circumstances and goals.',
}
