import { Injectable } from '@angular/core';
import {DatePipe} from "@angular/common";
import {Timestamp} from "firebase/firestore";

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(private datePipe: DatePipe) { }

  formatTimestamp(timestamp: Timestamp, includeTime = false): string {
    const format = includeTime ? 'MMM dd, yyyy, hh:mm a' : 'MMM dd, yyyy';
    const date = timestamp.toDate();
    let formatted = this.datePipe.transform(date, format);
    if (formatted) {
      return formatted;
    }
    return timestamp.toString();
  }

  formatDate(date: Date): string {
    let formatted = this.datePipe.transform(date);
    if (formatted) {
      return formatted;
    }
    return date.toDateString();
  }

  formatDateForMonthYear(date: Date) {
    let formatted = this.datePipe.transform(date, 'MMM yyyy');
    if (formatted) {
      return formatted;
    }
    return date.toDateString();
  }

  getAdjustedTimestampFromDate(date: Date): Timestamp {
    date.setMinutes(date.getMinutes() - (date.getTimezoneOffset() * -1));
    return Timestamp.fromDate(date);
  }
}
