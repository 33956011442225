<tr *ngIf="element" class="w-full">
  <td class="relative px-7 sm:w-12 sm:px-6">
    <!-- Selected row marker, only show when row is selected. -->
    <!--                 <div class="absolute inset-y-0 left-0 w-0.5 bg-primary"></div>-->

    <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary">
  </td>
  <!-- Selected: "text-primary", Not Selected: "text-gray-900" -->
  <td *ngFor="let col of cols" class="whitespace-nowrap pr-3 py-4 text-sm font-medium text-gray-900" [ngClass]="{'pl-3 pr-4 text-right text-sm font-medium sm:pr-3 flex flex-row justify-end': cols.indexOf(col) === cols.length - 1}">{{element[col.itemProperty]}}</td>
<!--    <td class="whitespace-nowrap pr-3 py-4 text-sm font-medium text-gray-900">Lindsay Walton</td>-->
<!--    <td class="whitespace-nowrap pr-3 py-4 text-sm text-gray-500">Front-end Developer</td>-->
<!--    <td class="whitespace-nowrap pr-3 py-4 text-sm text-gray-500">lindsay.walton@example.com</td>-->
<!--    <td class="whitespace-nowrap pr-3 py-4 text-sm text-gray-500">Member</td>-->
  <td class="whitespace-nowrap pl-3 pr-4 text-right text-sm font-medium sm:pr-3 flex flex-row justify-end">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
    </svg>
  </td>
</tr>
