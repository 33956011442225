import { Injectable } from '@angular/core';
import {CurrencyPipe, DatePipe, DecimalPipe, PercentPipe} from "@angular/common";
import {Timestamp} from "firebase/firestore";

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(private currencyPipe: CurrencyPipe,
              private percentPipe: PercentPipe) { }

  formatNumber(currencyNumber: number): string {
    let formatted = this.currencyPipe.transform(currencyNumber);
    if (formatted) {
      return formatted
    }
    return currencyNumber.toString();
  }

  formatPercent(percent: number | undefined): string {
    if (!!percent) {
      return this.percentPipe.transform(percent, '1.2-2')!;
    }
    return 'N/A'
  }
}
