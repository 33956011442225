import {Component, Input, OnInit} from '@angular/core';
import {ThemeType} from "../../models/theme-type";
import {ThemingService} from "../../services/theming.service";

@Component({
  selector: 'tandem-loading-container',
  templateUrl: './loading-container.component.html',
  styleUrls: ['./loading-container.component.scss']
})
export class LoadingContainerComponent implements OnInit {
  @Input() isLoaded: boolean = false;
  @Input() theme: ThemeType = 'primary';
  currentTheme: string = '#2E4057'

  constructor(private themingService: ThemingService) {}

  ngOnInit() {
    this.currentTheme = this.themingService.selectedTheme.value;
  }

  get ringStyle() {
    return {
      'border-color': `${this.currentTheme} transparent transparent transparent`
    };
  }
}
