import {Injectable} from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogService} from "../../tandem-core/services/dialog.service";
import {LocalStorageService} from "../../tandem-core/services/local-storage.service";
import {ThemingService} from "../../tandem-core/services/theming.service";
import {HeaderService} from "../../tandem-core/services/header.service";
import {CoreService} from "../../tandem-core/services/core.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import firebase from "firebase/compat/app";
import {environment} from "../../../../environments/environment";
import {ModalRef} from "../../tandem-core/models/modal-ref";
import {BehaviorSubject, from, Observable, of, switchMap} from "rxjs";
import {TandemUser} from "../models/tandem-user";
import {map, take, tap} from "rxjs/operators";

export interface InitialAuthData {
  email?: string;
  accountType?: 'user' | 'coach';
  coachType?: 'coachLeadGen' | 'coachRevShare',
  coachId?: string;
}

export interface AdditionalAuthData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  //photoURL: string; TODO maybe add this in the future (as optional)?
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  $user: Observable<TandemUser | null> = new Observable<TandemUser | null>();

  userPermsSubject: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  $userPerms: Observable<void> = this.userPermsSubject.asObservable();
  userIsPaid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private serverPath = environment.functionsPath;
  private loadingDialog?: ModalRef;

  constructor(public auth: AngularFireAuth,
              private afs: AngularFirestore,
              private route: ActivatedRoute,
              private router: Router,
              private dialogService: DialogService,
              private localStorageService: LocalStorageService,
              private themingService: ThemingService,
              private headerService: HeaderService,
              private coreService: CoreService,
              private http: HttpClient
  ) {
    this.$user = this.initUserStream();
  }

  async registerWithEmailPass(email: string, password: string, additionalData: InitialAuthData): Promise<void> {
    try {
      const credential = await this.auth.createUserWithEmailAndPassword(email, password);
      await this.triggerBackendUserSetup(credential.user, additionalData);
    } catch (e: any) {
      this.loadingDialog?.close();
      this.loadingDialog = undefined;

      let errorDisplay = this.getErrorDisplay(e.code);

      if (errorDisplay.message && errorDisplay.title) {
        this.dialogService.openConfirmDialog(errorDisplay.title, errorDisplay.message, undefined, 'Close', true, false, false, true);
      }
      throw e;
    }
  }

  async signInWithEmailPass(email: string, password: string): Promise<void> {
    try {
      const credential = await this.auth.signInWithEmailAndPassword(email, password);
      await this.triggerBackendUserSetup(credential.user, {});
    } catch (e: any) {
      this.loadingDialog?.close();
      this.loadingDialog = undefined;

      let errorDisplay = this.getErrorDisplay(e.code);

      if (errorDisplay.message && errorDisplay.title) {
        this.dialogService.openConfirmDialog(errorDisplay.title, errorDisplay.message, undefined, 'Close', true, false, false, true);
      }
      throw e;
    }
  }

  async signInWithGoogle(additionalData: InitialAuthData): Promise<void> {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const credential = await this.auth.signInWithPopup(provider);
      await this.triggerBackendUserSetup(credential.user, additionalData);
    } catch (error: any) {
      this.loadingDialog?.close();
      this.loadingDialog = undefined;

      let errorDisplay = this.getErrorDisplay(error.code);

      if (errorDisplay.message && errorDisplay.title) {
        this.dialogService.openConfirmDialog(errorDisplay.title, errorDisplay.message, undefined, 'Close', true, false, false, true);
      }
      throw error;
    }
  }

  public async getIdToken() {
    const user = await this.auth.currentUser;
    if (user) {
      return user.getIdToken();
    }
    return null;
  }

  public resetPassword(oobCode: string, newPassword: string) {
    return this.auth.confirmPasswordReset(oobCode, newPassword);
  }

  logoutAndRedirect() {
    this.localStorageService.clearAllStorage();
    this.coreService.clearAllSubscriptions();
    return this.auth.signOut().then(loggedOut => {

      window.location.href = `${environment.defaultDomain}/login`;
    })
  }

  onLogout(withReload = true) {
    this.localStorageService.clearAllStorage();
    this.coreService.clearAllSubscriptions();
    return this.auth.signOut()
      .then(res => this.router.navigate(['/auth/login']).then(res => {
        // this.themingService.setTheme({
        //   primaryColor: environment.defaultTheme,
        //   bodyFont: environment.defaultBodyFont,
        //   headerFont: environment.defaultHeaderFont
        // });
        this.headerService.resetConfig();
        location.reload();
        this.dialogService.showAlert({
          title: 'Logged Out',
          content: 'Remember, we\'re here 24/7 for all your financial needs. Come back soon!',
          dismissTime: 7500,
          position: 'bottom'
        })
      }))
  }

  async deleteCurrentUser() {
    const user = await this.auth.currentUser;
    if (user) {
      user.delete().then(() => {
      }).catch((error) => {
        if (error.code === 'auth/requires-recent-login') {
          this.onLogout(false).then(loggedOut => {
            this.dialogService.openMessageDialog('Sign In Again', `It looks like something went wrong on our end. Please try signing in again. If the error persists, please contact support`, true)
          })
        } else {
          console.error(error)
        }
      });
    }
  }

  async updatePassword(currentPassword: string, newPassword: string): Promise<void> {
    const user = await this.auth.currentUser;
    if (user) {
      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email as string,
        currentPassword
      );

      try {
        await user.reauthenticateWithCredential(credential);
        await user.updatePassword(newPassword);
      } catch (error: any) {
        throw new Error(error.message);
      }
    } else {
      throw new Error('No user is currently signed in.');
    }
  }

  public changePassword() {

  }

  public changeEmail() {

  }

  // TODO change this to use user service instead?
  public getUser(userId: string) {
    return this.afs.collection<TandemUser>('users').doc<TandemUser>(userId).snapshotChanges().pipe(map(doc => {
      if (doc.payload.exists) {
        const data = doc.payload.data() as any;
        const id = doc.payload.id;
        return {id, ...data};
      }
    }))
  }

  public verifyEmail(oobCode: string) {
    return this.auth.applyActionCode(oobCode);
    // .then(() => {
    //   // make a call to my backend that updates the user document's verification status (function will check if the user is actually verified)
    //   this.functions.httpsCallable('updateVerificationStatus').call(this,{ userId: 'user_id_here' }).subscribe(result => {
    //     console.log('Successfully updated document!');
    //   }, error => {
    //     console.error(error);
    //   })
    //   // this.http.
    // })
    // .catch(error => {
    // });
  }

  sendPasswordResetEmail(email: string) {
    const params = new HttpParams().set('email', email);
    return this.http.get<any>(`${this.serverPath}/sendPasswordReset`, {params: params});
  }

  public resendEmailVerification(uid: string) {
    const params = new HttpParams().set('uid', uid);
    return this.http.get<any>(`${this.serverPath}/resendEmailVerification`, {params: params});
    // return this.functions.httpsCallable('resendVerificationEmail').call(this, {email: email});
  }


  async updateUserVerificationStatus(uid: string): Promise<void> {
    try {
      const user = await this.auth.currentUser;
      if (!user) return;

      // Force reload the user to get latest email verification status
      await user.reload();

      if (user.emailVerified) {
        // Update Firestore document
        await this.afs.collection<TandemUser>('users').doc(uid).update({
          verified: true,
          needsTokenRefresh: true
        });

        // Force token refresh to trigger the user stream update
        await user.getIdToken(true);

        // Trigger permissions update
        this.userPermsSubject.next();
      }
    } catch (error) {
      console.error('Error updating verification status:', error);
    }
  }

  private parseJwt(token: string): any {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Error parsing JWT:', error);
      return {};
    }
  }

  public getCurrentUser() {
    return this.auth.currentUser;
  }

  private initUserStream(): Observable<TandemUser | null> {

    return this.auth.authState.pipe(
      switchMap((firebaseUser: firebase.User | null) => {
        if (firebaseUser) {
          return this.afs.doc<TandemUser>(`users/${firebaseUser.uid}`).valueChanges().pipe(
            switchMap(firestoreUser => {
              if (firestoreUser?.needsTokenRefresh) {
                return from(firebaseUser.getIdToken(true)).pipe(
                  switchMap(() => firebaseUser.getIdTokenResult()),
                  switchMap(idTokenResult => {
                    this.userPermsSubject.next();
                    return from(this.afs.doc<TandemUser>(`users/${firebaseUser.uid}`).update({
                      needsTokenRefresh: false
                    })).pipe(
                      map(() => ({ firebaseUser, firestoreUser, idTokenResult }))
                    );
                  })
                );
              } else {
                return from(firebaseUser.getIdTokenResult()).pipe(
                  map(idTokenResult => ({ firebaseUser, firestoreUser, idTokenResult }))
                );
              }
            }),
            map(({ firebaseUser, firestoreUser, idTokenResult }) => {
              this.userIsPaid.next(idTokenResult.claims['paid'] === true || idTokenResult.claims['isPaid']);
              return this.mergeUserData(firebaseUser, firestoreUser);
            }),
          );
        } else {
          this.userIsPaid.next(false);
          return of(null);
        }
      })
    );
  }

  private mergeUserData(firebaseUser: firebase.User, firestoreUser: TandemUser | undefined): TandemUser {
    return {
      uid: firebaseUser.uid,
      ...firestoreUser
    } as TandemUser;
  }

  private async triggerBackendUserSetup(user: firebase.User | null, additionalData: InitialAuthData): Promise<void> {
    if (user) {
      try {
        const userDocRef = this.afs.doc(`users/${user.uid}`);
        const userDoc = await userDocRef.get().toPromise();

        if (!additionalData.accountType) {
          additionalData.accountType = "user";
          additionalData.coachId = environment.defaultCoachId;
        }

        if (!userDoc?.exists) {

          this.dialogService.openModal2({
            type: 'loading',
            title: 'Creating Account',
            content: `Please wait while we set some things up on our end...`
          })

          // User document doesn't exist, proceed with user setup
          const idToken = await user.getIdToken();
          const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
          this.http.post(`${this.serverPath}/initialUserSetup`, additionalData, {headers, responseType: 'text'}).subscribe({
            next: (response: string) => {
              // Check if the user's email is verified
              if (user.emailVerified) {
                this.router.navigate(['/auth/additional-info']);
              } else {
                this.router.navigate(['/auth/email-not-verified']);
              }
              this.dialogService.closeModal2();
            },
            error: (err) => {
              console.error('Error during initial user setup:', err);
              const ref = this.dialogService.openMessageDialog('Unexpected Error', `It looks like something went wrong on our end. Please try again. If the error persists, contact support`, true);
            }
          });
        } else {
          this.userPermsSubject.next(); // Trigger userPerms update
          this.router.navigate(['/dashboard']);
        }
      } catch (error) {
        console.error('Error during user setup:', error);
        throw error;
      }
    }
  }

  async submitAdditionalInfo(additionalInfo: AdditionalAuthData): Promise<void> {
    const user = await this.auth.currentUser;
    if (user) {
      this.dialogService.openModal2({
        type: 'loading',
        title: 'Finishing Setup',
        content: `Please wait while we finish setting up your account...`
      })
      const idToken = await user.getIdToken();
      const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
      this.http.post(`${this.serverPath}/completeUserSetup`, additionalInfo, {headers, responseType: 'text'}).subscribe({
        next: () => {
          // Check if the user's email is verified
          this.userPermsSubject.next(); // Trigger userPerms update
          this.router.navigate(['/dashboard']);
        },
        error: (err) => {
          console.error('Error during user setup completion:', err);
          const ref = this.dialogService.openMessageDialog('Unexpected Error', `It looks like something went wrong on our end. Please try again. If the error persists, contact support`, true);
        }
      });
    }
  }
  private async completeUserSignup(user: firebase.User | null, additionalData: InitialAuthData): Promise<void> {
    if (user) {
      try {
        const userDocRef = this.afs.doc(`users/${user.uid}`);
        const userDoc = await userDocRef.get().toPromise();

        if (!userDoc?.exists) {
          // User document doesn't exist, proceed with user setup
          const idToken = await user.getIdToken();
          const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
          this.http.post(`${this.serverPath}/initialUserSetup`, additionalData, {headers}).subscribe({
            next: () => {
              // Check if the user's email is verified
              if (user.emailVerified) {
                this.router.navigate(['/auth/additional-info']);
              } else {
                this.router.navigate(['/auth/verify-email']);
              }
            },
            error: (err) => {
              console.error('Error during initial user setup:', err);
              // Handle error (e.g., show error message to user)
            }
          });
        } else {
          this.router.navigate(['/dashboard']);
        }
      } catch (error) {
        console.error('Error during user setup:', error);
        throw error;
      }
    }
  }

  private getErrorDisplay(errorCode: string): {title?: string; message?: string;} {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return {
          title: 'Email Already in Use',
          message: "Looks like the email address you entered is already in use by another account. Please use a different email address to create an account or sign in with the email you entered."
        }
      case 'auth/invalid-email':
        return {
          title: 'Invalid Email',
          message: "Whoops, looks like that email is not a valid email address. Please make sure your email is correct and try again. If you need to create an account, click the 'Sign Up Here' link."
        }
      case 'auth/operation-not-allowed':
        return {
          title: 'Operation Not Allowed',
          message: "This sign-in method isn't currently enabled. Please check with your administrator or visit the Firebase Console under the Auth tab to enable it."
        }
      case 'auth/weak-password':
        return {
          title: 'Weak Password',
          message: "Looks like you need a stronger password. Make sure your password has at least one capital letter, one special symbol, and is a minimum of 8 characters long."
        }
      case 'auth/account-exists-with-different-credential':
        return {
          title: 'Wrong Sign in Method',
          message: "It looks like an account with this email already exists but was registered using a different sign-up method. Please try signing in with the method you used to sign up."
        }
      case 'auth/auth-domain-config-required':
        return {
          title: 'Domain Configuration Error',
          message: "Oops! We're missing some important configuration details to proceed. Please ensure that the 'authDomain' configuration is correctly set. You can find guidance on this in the Firebase Console under your project settings."
        }
      case 'auth/cancelled-popup-request':
        return {
          // title: 'title',
          // message: "We noticed you've triggered several sign-in popups. To keep the site running smoothly, we only handle one sign-in popup at a time. Please try again, and remember, one popup at a time works best!"
        }
      case 'auth/popup-closed-by-user':
        return {
          // title: 'title',
          // message: "It looks like the sign-in popup was closed before completing the sign-in process. If you closed the popup by mistake, please try signing in again."
        }
      case 'auth/user-not-found':
        return {
          title: 'User Not Found',
          message: "Whoops, it looks like you don\'t have a registered account with us! Please verify the information you entered is correct and try again. If you need to create an account, click the \'Sign Up Here\' link."
        }
      case 'auth/wrong-password':
        return {
          title: 'Wrong Password',
          message: "Whoops, looks like the password you entered is incorrect. Please try again."
        }
      case 'auth/invalid-login-credentials':
        return {
          title: 'Invalid Credentials',
          message: "Whoops, looks like the email or password you entered is incorrect. Please try again."
        }
      default:
        return {
          title: 'Unexpected Error',
          message: "Whoops! An unexpected error occurred, please try again."
        }
    }
  }
}
