<!--<ng-container *ngIf="!!control">-->
<!--  <div class="inline-flex flex-col items-start gap-5 w-full">-->
<!--    <p class="w-full">{{ label }}</p>-->

<!--    <select-->
<!--      [id]="inputId"-->
<!--      [formControl]="displayControl"-->

<!--      class="flex h-35 items-center gap-8 border border-dark/20 rounded-sm w-full"-->
<!--    >-->
<!--      <option [value]="null" >Select an Option</option>-->
<!--      <option *ngFor="let option of displayOptions" [value]="option">-->
<!--        {{ option }}-->
<!--      </option>-->
<!--    </select>-->
<!--    <div *ngIf="control.invalid && (control.dirty || control.touched)" class="text-12 text-red">-->
<!--      &lt;!&ndash; You can customize this part to show specific error messages &ndash;&gt;-->
<!--      <div *ngIf="control.hasError('required')">{{ label }} is required</div>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-container>-->

<div *ngIf="control" class="mb-5">
  <div class="flex flex-row gap-1">
    <label id="listbox-label" class="block text-sm font-medium leading-6 text-gray-900">{{label}}</label>
    <tandem-tooltip *ngIf="tooltipText" [text]="tooltipText"></tandem-tooltip>
  </div>
  <div class="relative mt-2">
    <button
      type="button"
      [disabled]="control.disabled"
      class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-red-500 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
    [ngClass]="{'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-red-500': control.touched && control.invalid}"
      aria-haspopup="listbox"
      aria-expanded="true"
      aria-labelledby="listbox-label"
      (click)="toggleDropdown(); $event.stopPropagation()">
      <span class="block truncate">{{selectedOption ? selectedOption.label : 'Select an Option'}}</span>
      <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <div *ngIf="control.invalid && (control.dirty || control.touched)" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <svg class="h-5 w-5 text-red-500 mr-4" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
          </svg>
        </div>
        <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
        </svg>
      </span>
    </button>
    <ul *ngIf="displayDropdown"  clickOutside (clickOutside)="toggleDropdown(); touchControl()" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" style="z-index: 99999" tabindex="-1" role="listbox" aria-labelledby="listbox-label">
      <li *ngIf="options.length === 0" class="text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-primary hover:text-white" id="listbox-option-none-available" role="option">
        <span class="font-normal block truncate">No options available</span>
      </li>
      <li *ngFor="let option of options; let i = index" class="text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-primary hover:text-white" [id]="'listbox-option-' + i" role="option" (click)="selectOption(option)">
        <span class="block truncate font-semibold" [ngClass]="{'font-semibold': selectedOption === option}">{{option.label}}</span>
        <span *ngIf="option === selectedOption" class="text-primary absolute inset-y-0 right-0 flex items-center pr-4 hover:text-white checkbox">
          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
          </svg>
        </span>
      </li>
    </ul>
  </div>
  <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('required')" class="mt-2 text-sm text-red-600">{{label}} is required</p>
</div>
