import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ModalRef} from "../../../tandem-core/models/modal-ref";

@Component({
  selector: 'tandem-reauth-with-email-pass',
  templateUrl: './reauth-with-email-pass.component.html',
  styleUrls: ['./reauth-with-email-pass.component.scss']
})
export class ReauthWithEmailPassComponent implements OnInit {

  emailForm: FormGroup = new FormGroup<any>({});
  constructor(private fb: FormBuilder,
              @Inject(ModalRef) public modalRef: ModalRef) {
  }
  ngOnInit(): void {
    this.emailForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    })
  }

  onSubmit() {
    this.modalRef.close(this.emailForm.value);
  }

  close() {
    this.modalRef.close()
  }
}
