<ngx-charts-line-chart *ngIf="chartType === 'line'"
                       [results]="results"
                       [scheme]="colorScheme"
                        [legend]="true"
[showGridLines]="true"
[showXAxisLabel]="true"
[showYAxisLabel]="true"
                       [xAxis]="true" [yAxis]="true" [ngClass]=""
[xAxisLabel]="'Time'"
[yAxisLabel]="'Value'">
</ngx-charts-line-chart>

<ngx-charts-pie-chart *ngIf="chartType === 'pie'"
                      [view]="view"
                      [results]="results"
                      [scheme]="colorScheme">
</ngx-charts-pie-chart>

<ngx-charts-advanced-pie-chart *ngIf="chartType === 'doughnut'"
                               [view]="view"
                               [results]="results"
                               [scheme]="colorScheme">
</ngx-charts-advanced-pie-chart>

<!--<button (click)="closeModal()">close</button>-->
