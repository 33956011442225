import {AfterViewInit, Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {CurrencyPipe, DatePipe, isPlatformBrowser, NgIf, NgStyle} from '@angular/common';
import {NgxEchartsDirective, NgxEchartsModule} from 'ngx-echarts';
import {Timestamp} from "firebase/firestore";
import {ChartService} from "../../../../../tandem-core/services/chart.service";

@Component({
  selector: 'app-cash-flow-graph',
  standalone: true,
  imports: [
    NgIf,
    NgxEchartsModule
  ],
  providers: [
    CurrencyPipe,
    DatePipe,
  ],
  templateUrl: './cash-flow-graph.component.html',
  styleUrls: ['./cash-flow-graph.component.scss']
})
export class CashFlowGraphComponent implements AfterViewInit {
  @Input() useCase: string = 'general';

  showLegend: boolean = true;

  chartOption: any

  constructor(private chartService: ChartService, private currencyService: CurrencyPipe, private dateService: DatePipe, @Inject(PLATFORM_ID) private platformId: Object) {
    this.showLegend = this.useCase === 'blog';
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      let categories: any[] = [];
      
      // Handle revenue categories
      defaultCf?.revenueSections.forEach(section => {
        section.categories.forEach(cat => {
          if (cat.total > 0) {
            categories.push({
              name: cat.name,
              value: cat.total,
              isRevenue: true,
              parentSection: 'Revenue'
            });
          }
        });
      });

      // Handle expense categories
      defaultCf?.expenseSections.forEach(section => {
        section.categories.forEach(cat => {
          if (cat.total > 0) {
            categories.push({
              name: cat.name,
              value: cat.total,
              isRevenue: false,
              parentSection: 'Expenses'
            });
          }
        });
      });

      const innerSeries = {
        name: 'Items',
        data: [
          {
            name: 'Revenue',
            value: defaultCf?.revenueSections.reduce((acc, section) => 
              acc + section.categories.reduce((catAcc, cat) => catAcc + cat.total, 0), 0) || 0,
            isRevenue: true
          },
          {
            name: 'Expenses',
            value: defaultCf?.expenseSections.reduce((acc, section) => 
              acc + section.categories.reduce((catAcc, cat) => catAcc + cat.total, 0), 0) || 0,
            isRevenue: false
          }
        ]
      };

      const outerSeries = {
        name: 'Categories',
        data: categories
      };

      this.chartOption = this.chartService.generatePieChart(
        innerSeries,
        outerSeries,
        this.showLegend,
        false
      );
    });
  }

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  getTitle() {
    return `Net Cash Flow: ${this.formatNumber(defaultCf?.netCashFlow || 0)}`;
  }

  formatNumber(currencyNumber: number): string {
    let formatted = this.currencyService.transform(currencyNumber);
    if (formatted) {
      return formatted
    }
    return currencyNumber.toString();
  }

  toFirestoreTimestamp(seconds: number, nanoseconds: number) {
    return new Timestamp(seconds, nanoseconds);
  }

  formatTimestamp(timestamp: Timestamp): string {
    let formatted = this.dateService.transform(timestamp.toDate());
    if (formatted) {
      return formatted
    }
    return timestamp.toString();
  }
}

export const defaultCf = {
  "uncategorizedRevenue": [],
  "endDate": {
    "_seconds": 1703998800,
    "_nanoseconds": 0
  },
  "totalExpenses": 9275.23,
  "dateModified": {
    "_seconds": 1711312698,
    "_nanoseconds": 210000000
  },
  "revenueSections": [
    {
      "hoverHint": "Cash coming in.",
      "name": "Revenue",
      "description": "Does rev change?",
      "categories": [
        {
          "total": 9010.8,
          "hoverHint": "Earnings from directly trading time for money whether it's in a business, job, or side hustle.",
          "name": "Active Income",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Base Salary",
              "value": 6825.17
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Sales Commission",
              "value": 2185.63
            }
          ]
        },
        {
          "total": 815.87,
          "hoverHint": "Earnings in which you do not directly trade time for money.",
          "name": "Passive Income",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Rental Property Cash Flow",
              "value": 665.87
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Promissory Note",
              "value": 150
            }
          ]
        },
        {
          "total": 645.8199999999999,
          "hoverHint": "Cash flow that you don't expect to have with regularity",
          "name": "Unexpected Income",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Freelance Work",
              "value": 365.82
            },
            {
              "date": {
                "_seconds": 1703826000,
                "_nanoseconds": 0
              },
              "name": "Pet Sitting",
              "value": 280
            }
          ]
        },
        {
          "hoverHint": "Cash generated from the sale of an asset or liquidation of an account.",
          "total": 2000,
          "name": "Asset Liquidation",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Promissory Note Principal",
              "value": 2000
            }
          ]
        }
      ]
    }
  ],
  "expenseSections": [
    {
      "hoverHint": "Expenses that pay into the future of the most important person in your life, you.",
      "name": "Pay Yourself First Expenses",
      "description": "Does PSP change?",
      "categories": [
        {
          "hoverHint": "Income freely given to charitable or faith-inspired causes.",
          "total": 1297.25,
          "name": "Charitable Contributions",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Local Youth Sports Team",
              "value": 50
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Tithe",
              "value": 1247.25
            }
          ]
        },
        {
          "total": 81.36,
          "hoverHint": "Investments into your skills, knowledge, and network that will increase your future earning potential.",
          "name": "Investing In Yourself",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Taking a mentor to lunch",
              "value": 81.36
            }
          ]
        },
        {
          "total": 125.69,
          "hoverHint": "The purchase of assets that will generate wealth on your behalf such as financial securities.",
          "name": "Working Assets",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "401K Contribution",
              "value": 125.69
            }
          ]
        },
        {
          "hoverHint": "Payments to debt above and beyond the minimum payment.",
          "total": 2145.5,
          "name": "Debt Paydown",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Discover Cash Back CC",
              "value": 103.24
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Capital One QuickSilver",
              "value": 217.26
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Medical Debt Paydown",
              "value": 1325
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Chase Sapphire Paydown",
              "value": 500
            }
          ]
        }
      ]
    },
    {
      "hoverHint": "Expenses essential to your basic survival; groceries, housing, transportation, etc.",
      "name": "Essential Expenses",
      "description": "Does EE change?",
      "categories": [
        {
          "total": 419.25,
          "hoverHint": "Expenses associated with having a roof over your head and a warm bed to sleep in at night.",
          "name": "Housing",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Maintenance/yard",
              "value": 419.25
            }
          ]
        },
        {
          "hoverHint": "Transportation expenses you need to pay for the essentials in life, such as driving to work.",
          "total": 285.02,
          "name": "Transportation",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Gas",
              "value": 217.03
            },
            {
              "date": {
                "_seconds": 1703826000,
                "_nanoseconds": 0
              },
              "name": "Oil Change",
              "value": 67.99
            }
          ]
        },
        {
          "hoverHint": "Insurance and utility bills paid to maintain the essentials of life, such as the electric bill.",
          "total": 312.1,
          "name": "Insurance & Utilities",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Home Insurance",
              "value": 128.99
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Electricity Bill",
              "value": 102.64
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Gas bill",
              "value": 80.47
            }
          ]
        },
        {
          "hoverHint": "Food and household items necessary to sustain yourself.",
          "total": 495.5,
          "name": "Groceries & Toiletries",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Groceries",
              "value": 453.42
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "House goods",
              "value": 42.08
            }
          ]
        },
        {
          "total": 157.15,
          "hoverHint": "Necessary medical expenses such as prescriptions, check-ups, and walk-in visits.",
          "name": "Medical",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Prescriptions",
              "value": 62.15
            },
            {
              "date": {
                "_seconds": 1703826000,
                "_nanoseconds": 0
              },
              "name": "Walk-in Visit",
              "value": 95
            }
          ]
        },
        {
          "total": 0,
          "hoverHint": "Expenses such as daycare and diapers that are essential to a healthy childhood.",
          "name": "Childcare",
          "items": []
        },
        {
          "hoverHint": "The minimum payments made on existing debt.",
          "total": 2907.1899999999996,
          "name": "Debt Payments",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Subsidized Student Loan",
              "value": 134.96
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Unsubsidized Student Loan",
              "value": 158.51
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Parent Plus Student Loan",
              "value": 66.46
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "FHA Mortgage",
              "value": 1711.5
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Auto Loan",
              "value": 416.82
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Medical Debt",
              "value": 324.78
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Cash Sapphire CC",
              "value": 94.16
            }
          ]
        },
        {
          "hoverHint": "Any other essential expenses.",
          "total": 0,
          "name": "Other",
          "items": []
        }
      ]
    },
    {
      "hoverHint": "Expenses that are intended to improve your quality of life.",
      "name": "Lifestyle Expenses",
      "description": "Does LE change?",
      "categories": [
        {
          "hoverHint": "Expenses related to your mental and physical well-being, generally, treating yourself.",
          "total": 287.18,
          "name": "Self Care",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Art Session",
              "value": 62.18
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Chiropractor",
              "value": 125
            },
            {
              "date": {
                "_seconds": 1703826000,
                "_nanoseconds": 0
              },
              "name": "Massage",
              "value": 100
            }
          ]
        },
        {
          "total": 214.24,
          "hoverHint": "Expenses related to 'going out on the town' whether this means lunch, a night out, or a concert.",
          "name": "Going Out",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Dinner with Friends",
              "value": 89.56
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Salsa classes",
              "value": 50
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Date Night",
              "value": 74.68
            }
          ]
        },
        {
          "hoverHint": "Generally, new clothes, household items, or toys.",
          "total": 42.18,
          "name": "Shopping",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Amazon",
              "value": 42.18
            }
          ]
        },
        {
          "total": 72.19,
          "hoverHint": "Expenses for traveling or taking a vacation.",
          "name": "Travel & Vacation",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Gas for boat",
              "value": 72.19
            }
          ]
        },
        {
          "total": 114.97,
          "hoverHint": "Subscriptions that improve your quality of life such as streaming services.",
          "name": "Subscriptions",
          "items": [
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Personal Finance Software",
              "value": 15.99
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "Spotify",
              "value": 18.99
            },
            {
              "date": {
                "_seconds": 1701406800,
                "_nanoseconds": 0
              },
              "name": "TV",
              "value": 79.99
            }
          ]
        },
        {
          "total": 318.46,
          "hoverHint": "Any other expenses that are not essential, but are intended to improve your quality of life.",
          "name": "Other",
          "items": [
            {
              "date": {
                "_seconds": 1703826000,
                "_nanoseconds": 0
              },
              "name": "Misc boat stuff",
              "value": 318.46
            }
          ]
        }
      ]
    }
  ],
  "title": "Cash Flow From 12/01/2023 to 12/31/2023",
  "userId": "Vn3FWWY203Vn1JyB1NpivmxfLAJ3",
  "visibleToCoach": true,
  "dateCreated": {
    "_seconds": 1711311872,
    "_nanoseconds": 325000000
  },
  "uncategorizedExpenses": [],
  "netCashFlow": 3197.26,
  "id": "xiJ5S6HgL5I1EnvhBdHh",
  "totalRevenue": 12472.49,
  "startDate": {
    "_seconds": 1701406800,
    "_nanoseconds": 0
  }
}
