import {Component, Input} from '@angular/core';
import {DialogService, TandemDialogAction, TandemDialogConfig} from "../../services/dialog.service";

@Component({
  selector: 'tandem-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent {

  @Input() title: string = '';
  @Input() config: TandemDialogConfig = {type: 'info'};
  @Input() confirm: () => void = () => {};
  @Input() cancel: () => void = () => {};

  constructor(private dialog: DialogService) {
  }

  onClose() {
    this.cancel();
  }

  callAction(action: TandemDialogAction) {
    if (action.callback) {
      this.dialog.closeModal2();
      action.callback();
    }
  }

}
