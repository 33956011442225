import {Component, OnInit} from '@angular/core';
import {TandemUser} from "../../../auth/models/tandem-user";
import {AuthService} from "../../../auth/services/auth.service";
import {PaymentService} from "../../services/payment-service";
import {DialogService} from "../../../tandem-core/services/dialog.service";
import {HeaderService} from "../../../tandem-core/services/header.service";
import {ActivatedRoute, Router} from "@angular/router";
import {take} from "rxjs/operators";
import {StripeOperationsService} from "../../services/stripe-operations.service";

@Component({
  selector: 'tandem-connect-return',
  templateUrl: './connect-return.component.html',
  styleUrls: ['./connect-return.component.scss']
})
export class ConnectReturnComponent implements OnInit {

  user: TandemUser | null = null;
  accountId: string | null = null;
  errorMessage?: string;
  isLoaded = false;

  constructor(private auth: AuthService,
              private stripeOperationsService: StripeOperationsService,
              private dialogService: DialogService,
              private headerService: HeaderService,
              private route: ActivatedRoute,
              private router: Router) {
    this.accountId = this.route.snapshot.queryParamMap.get('account_id');
  }
  ngOnInit(): void {
    this.auth.$user.pipe(take(1)).subscribe(user => {
      this.user = user
      this.headerService.setConfig({hideHeader: true});
      if (this.accountId) {

        if (this.user?.coachType === 'coachRevShare') {
          this.stripeOperationsService.validateStripeForRSCoach(this.accountId).subscribe(res => {
            // Check if the user's email is verified
            this.auth.userPermsSubject.next(); // Trigger userPerms update
            this.router.navigate(['/dashboard']);
          }, error => {
            console.log(error.error.error)
            if (error.error.error === 'Stripe Connect account not fully set up') {
              this.errorMessage = 'Looks like your Connect Account isn\'t fully set up yet. Please click the bar at the top and try again.'
            }
            this.isLoaded = true;
          })
        }
// this.paymentService.validateAccessFromSession(this.sessionId).then(res => {
        //
        // }).catch(err => {
        //   if (err.statusText == 'Unknown Error') {
        //     this.errorMessage = 'Something went wrong on our end. Please refresh the page. If the error persists, contact customer support.'
        //   } else {
        //     this.errorMessage = err;
        //   }
        // })
      }
    });

  }

}
