import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {get} from "@angular/fire/database";

@Component({
  selector: 'tandem-form-switch',
  templateUrl: './form-switch.component.html',
  styleUrls: ['./form-switch.component.scss']
})
export class FormSwitchComponent {

  @Input() form: FormGroup = new FormGroup({});
  @Input() inputId: string = '';
  @Input() label?: string;
  @Input() isDisabled = false;
  @Input() controlName: string = '';

  @Input() control: FormControl | undefined;

  constructor() {}

  ngOnInit() {
    if (!this.control) {
      this.control = this.form.get(this.controlName) as FormControl;
    }
  }

  getControl() {
    return this.control as FormControl;
  }

  toggleSwitch() {
    let currentValue: boolean = this.control?.value;
    this.control?.setValue(!currentValue);
  }

  protected readonly get = get;

  onSwitch() {
    console.log('hi')
    this.control?.setValue(!this.control?.value);
    console.log(this.control?.value)
  }
}
