export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBcMsulSjECZulDwzccIjnSGpCxT-pAsks",
    authDomain: "tandem-finance-tst.firebaseapp.com",
    projectId: "tandem-finance-tst",
    storageBucket: "tandem-finance-tst.appspot.com",
    messagingSenderId: "353426925647",
    appId: "1:353426925647:web:0e00154410851dce01623a",
    measurementId: "G-T4RHKJ6XFY"
  },
  environmentName: 'Test',
  stripePublicKey: 'pk_test_51ODUUiEIxj4fplMbKFOzhevgkbwc2zbC57n3FHbnx1L9jbWQ3t4cF56GtXiF1x9Cv44FjwOrGaXGp4IUD9nSeN2w00FasWUWsC',
  // functionsPath: 'http://127.0.0.1:5001/tandem-finance-tst/us-central1',
  functionsPath: 'https://us-central1-tandem-finance-tst.cloudfunctions.net',
  logoPath: 'assets/svg/logo/full_white.svg',
  whiteLogoPath: 'assets/svg/logo/full_white.svg',
  whiteIconPath: 'assets/svg/logo/icon_white.svg',
  primaryIconPath: 'assets/svg/logo/icon_blue.svg',
  defaultTheme: '#4660ea',
  defaultHeaderFont: 'DM Sans',
  defaultBodyFont: 'DM Sans',
  enableGridDisplay: false,
  defaultThemeConfigurationId: 'CJkiTRESjL7AfT9kwBCf',
  defaultVideoConfig: {
    userWelcomeRev: 'https://www.youtube.com/embed/HtPZFw1Abvw?si=xqJYiaiV1iETY_A9',
    userWelcomeLead: 'https://www.youtube.com/embed/HtPZFw1Abvw?si=xqJYiaiV1iETY_A9',
    userWelcomeIndividual: 'https://www.youtube.com/embed/HtPZFw1Abvw?si=xqJYiaiV1iETY_A9',
    coachWelcomeRev: 'https://www.youtube.com/embed/M67GzX6_wFo?si=vzIZiqHSFVQN5tDn',
    coachWelcomeLead: 'https://www.youtube.com/embed/M67GzX6_wFo?si=vzIZiqHSFVQN5tDn',
    financialPosition: 'https://www.youtube.com/embed/M5kGEj9lKa8?si=wWDxKLBUAkeODQRy',
    cashFlow: 'https://www.youtube.com/embed/ka_eNvEpuvk?si=YJS2BiDP0fJm5DRS',
    spendingPlan: 'https://www.youtube.com/embed/Z3R19NOS4YE?si=wzer92zTSvwzqhfR',
    debtPayoff: 'https://www.youtube.com/embed/Qjb-Z732rn4?si=dQjpA9DUuz2L7rxs',
    investment: 'https://www.youtube.com/embed/OhNV93lqg-0?si=s2BjK6DcDlVS_5Pz',
    debtVsInvest: 'https://www.youtube.com/embed/dQpy4EJczsM?si=cIbpExh6MZwQTUWS',
    branding: 'https://www.youtube.com/embed/hDV5A0PTtL8?si=E-xWt43WczcDai9v'
  },
  defaultFPid: 'GjJqgcBzo3tLLG8IVpK6',
  defaultCFid: 'S24TCb2YD3d4SOxlje36',
  defaultSPid: 'VVwgznZUEGUvPyAfi8qF',
  defaultDomain: 'https://tst.tandemfinance.app',
  // defaultCoachId: 'bDjbMEJCKvOkdiD2giuMwMtceDs1'
  defaultCoachId: '8UiiFbfB5gSXy8I57f0SHshrr5S2',
  googleFontAPIKey: 'AIzaSyC78zxgzMm4TZJ938i6XOwdHczOcMTngC0'
};
