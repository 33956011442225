import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ModalRef} from "../../../tandem-core/models/modal-ref";

@Component({
  selector: 'tandem-change-email-dialog',
  templateUrl: './change-email-dialog.component.html',
  styleUrls: ['./change-email-dialog.component.scss']
})
export class ChangeEmailDialogComponent implements OnInit {

  emailForm: FormGroup = new FormGroup<any>({});
  constructor(private fb: FormBuilder,
              @Inject(ModalRef) public modalRef: ModalRef) {
  }
  ngOnInit(): void {
    this.emailForm = this.fb.group({email: [null, [Validators.required, Validators.email]]})
  }

  onSubmit() {
    this.modalRef.close(this.emailForm.get('email')?.value);
  }

  close() {
    this.modalRef.close()
  }
}
