import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'add-new-row',
  templateUrl: './add-new-row.component.html',
})
export class AddNewRowComponent implements OnInit {

  @Input() displayButton = false;
  @Input() total: number | undefined;
  @Input() displayTotal: boolean = false;

  @Output() onAdd: EventEmitter<void> = new EventEmitter<void>();


  constructor() {
  }

  emit() {
    this.onAdd.emit();
  }

  ngOnInit(): void {
  }
}
