<div *ngIf="control" class="mb-5">
  <!-- Labels -->
  <div class="flex justify-between">
    <div class="flex gap-2">
      <label [for]="inputId" class="block text-sm font-medium leading-6 text-gray-900">{{customLabel ? customLabel : label}}</label>
      <tandem-tooltip *ngIf="tooltipText" [text]="tooltipText"></tandem-tooltip>
    </div>
    <span *ngIf="isOptional" class="text-sm leading-6 text-gray-500 pr-2 italic">Optional</span>
  </div>

  <div class="relative mt-2 rounded-md shadow-sm">
    <!-- Prefix -->
    <div *ngIf="prefixText" class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
      <span class="text-gray-500 sm:text-sm">{{ prefixText }}</span>
    </div>

    <!-- Regular Input (No Mask) -->
    <input #input
           *ngIf="!maskType"
           [formControl]="control"
           [type]="inputType"
           [name]="inputId"
           [id]="inputId"
           [placeholder]="placeholder"
           [disabled]="isDisabled"
           [ngClass]="{
             'pl-7': prefixText,
             'pr-16': suffixText && control.touched && control.invalid,
             'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-red-500':
               control.touched && control.invalid
           }"
           class="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300
                  placeholder:text-gray-400 focus:ring-primary sm:text-sm sm:leading-6
                  disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
           [attr.aria-describedby]="inputId"
           [attr.aria-invalid]="(control.touched && control.invalid) ? true : null"
           (focus)="onFocus()">

    <!-- Masked Input -->
    <input #input
           *ngIf="maskType"
           [formControl]="control"
           [type]="inputType"
           [name]="inputId"
           [id]="inputId"
           [placeholder]="placeholder"
           [disabled]="isDisabled"
           [ngClass]="{
         'pl-7': prefixText,
         'pr-16': suffixText && control.touched && control.invalid,
         'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-red-500':
           control.touched && control.invalid
       }"
           class="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300
              placeholder:text-gray-400 focus:ring-primary sm:text-sm sm:leading-6
              disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
           [attr.aria-describedby]="inputId"
           [attr.aria-invalid]="(control.touched && control.invalid) ? true : null"
           (focus)="onFocus()"
           [mask]="mask"
           [dropSpecialCharacters]="dropSpecialCharacters"
           [thousandSeparator]="thousandSeparator"
           [decimalMarker]="decimalMarker"
           [allowNegativeNumbers]="allowNegativeNumbers">

    <!-- Suffix -->
    <div *ngIf="suffixText"
         [ngClass]="{'pr-3': ((!control.touched || control.valid) || control.invalid), 'pr-10': (control.touched && control.invalid)}"
         class="pointer-events-none absolute inset-y-0 right-0 flex items-center">
      <span class="text-gray-500 sm:text-sm">{{ suffixText }}</span>
    </div>

    <!-- Error Icon -->
    <div *ngIf="control.touched && control.invalid"
         class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
         [ngClass]="{'pr-8': inputType === 'date' || (control.touched && control.invalid)}">
      <svg class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
      </svg>
    </div>
  </div>

  <!-- Error Messages -->
  <ng-container *ngIf="!hideErrorMessage">
    <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('required')"
       class="mt-2 text-sm text-red-600">{{!!label ? label : placeholder}} is required</p>
    <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('minlength')"
       class="mt-2 text-sm text-red-600">{{getMinLengthMessage()}}</p>
    <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('maxlength')"
       class="mt-2 text-sm text-red-600">{{label}} has too many characters</p>
    <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('min')"
       class="mt-2 text-sm text-red-600">{{getMinMessage()}}</p>
    <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('max')"
       class="mt-2 text-sm text-red-600">{{getMaxMessage()}}</p>
    <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('email')"
       class="mt-2 text-sm text-red-600">Invalid email</p>
    <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('invalidColor')"
       class="mt-2 text-sm text-red-600">The provided value is not a valid color</p>
  </ng-container>
</div>
