import {Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {FormOption} from "../form-option";
import {Subscription} from "rxjs";

@Component({
  selector: 'tandem-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss']
})
export class FormSelectComponent implements OnInit, OnChanges {

  @Input() label: string = '';
  @Input() form: FormGroup | AbstractControl = new FormGroup({});
  @Input() inputId: string = '';
  @Input() controlName: string = '';
  @Input() options: FormOption[] = [];
  @Input() onFormReset?: EventEmitter<void>;
  @Input() tooltipText?: string;
  @Input() penis?: string = '';

  control: FormControl = new FormControl();
  selectedOption?: FormOption;
  displayDropdown = false;

  constructor(private fb: FormBuilder) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      const opt = this.options.find(o => o.value === this.selectedOption?.value);
      if (opt) {
        this.selectedOption = opt;
      } else {
        this.selectedOption = undefined;
        this.control.setValue(null);
      }
    }
  }

  ngOnInit() {
    this.control = this.form.get(this.controlName) as FormControl;
    this.selectedOption = this.options.find(opt => opt.value === this.control.value) || undefined;
    this.control.valueChanges.subscribe(val => {
      if (val === null) {
        this.selectedOption = undefined;
      } else {
        if (val !== this.selectedOption?.value) {
          const opt = this.options.find(o => o.value === val);
          if (opt) {
            this.selectedOption = opt;
          }
        }
      }
    });
    this.onFormReset?.subscribe(onReset => {
      this.control.setValue(null);
      this.selectedOption = undefined;
    })
  }

  // TODO is this gonna be needed?
  // compareFn(option1: any, option2: any) {
  //   if (typeof option1 === 'number' && typeof option2 === 'string') {
  //     option2 = Number(option2);
  //   } else if (typeof option2 === 'number' && typeof option1 === 'string') {
  //     option1 = Number(option1);
  //   }
  //   return option1 === option2;
  // }

  toggleDropdown() {
    this.displayDropdown = !this.displayDropdown;
  }

  selectOption(option: FormOption) {
    this.selectedOption = option;
    this.displayDropdown = false;
    this.control.setValue(option.value);
  }

  touchControl() {
    this.control.markAsTouched();
  }
}
