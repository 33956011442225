import { Component } from '@angular/core';

@Component({
  template: `

  `,
  styleUrls: ['./abstract-modal.component.scss']
})
export class AbstractModalComponent {

}
