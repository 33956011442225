import {Component, Input} from '@angular/core';
import {TableColumn} from "../../../models/table-configuration";

@Component({
  selector: 'tandem-table-row',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss']
})
export class TableRowComponent<T> {

  @Input() element?: T
  @Input() cols: TableColumn<T>[] = [];

}
