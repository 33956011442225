<ng-container *ngIf="isLoaded; else loadingTemplate">
  <ng-content></ng-content>
</ng-container>

<ng-template #loadingTemplate>
  <div class="w-full flex flex-row justify-center">
    <div class="w-full h-full lds-ring">
      <div [ngStyle]="ringStyle"></div>
      <div [ngStyle]="ringStyle"></div>
      <div [ngStyle]="ringStyle"></div>
      <div [ngStyle]="ringStyle"></div>
    </div>
  </div>
</ng-template>
