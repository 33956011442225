import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HttpClientModule} from "@angular/common/http";
import { PaymentSuccessComponent } from './components/payment-success/payment-success.component';
import { PaymentErrorComponent } from './components/payment-error/payment-error.component';
import {StripeRoutingModule} from "./stripe-routing.module";
import {TandemCoreModule} from "../tandem-core/tandem-core.module";
import {ReactiveFormsModule} from "@angular/forms";
import { ConnectRefreshComponent } from './components/connect-refresh/connect-refresh.component';
import { ConnectReturnComponent } from './components/connect-return/connect-return.component';



@NgModule({
  declarations: [
    PaymentSuccessComponent,
    PaymentErrorComponent,
    ConnectRefreshComponent,
    ConnectReturnComponent
  ],
  exports: [
  ],
    imports: [
        CommonModule,
        HttpClientModule,
        StripeRoutingModule,
        TandemCoreModule,
        ReactiveFormsModule
    ]
})
export class StripeModule { }



// 3 main fronts: bride visualizations, renewals UI, security testing/ openapi
