import {
  Component, ElementRef, EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {TableColumn, TableConfiguration} from "../../models/table-configuration";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";

@Component({
  selector: 'tandem-new-table',
  templateUrl: './new-table.component.html',
  styleUrls: ['./new-table.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({
        height: '0px',
        opacity: 0,
        overflow: 'hidden'
      })),
      state('expanded', style({
        height: '*',
        opacity: 1
      })),
      transition('expanded <=> collapsed', animate('300ms ease-out'))
    ])
  ],
})
export class NewTableComponent<T> implements OnInit, OnChanges, OnDestroy {

  @ViewChild('dropdownMenu', {read: ElementRef}) dropdownMenu: ElementRef | undefined;

  @Input() tableConfiguration: TableConfiguration<T> | null = null;
  @Input() data: T[] = [];
  @Input() tableId?: string; // TODO is this needed? Only used in cash flows
  @Input() moveOptions: string[] = []; // TODO also only used by cash flows
  @Input() filterTemplate?: TemplateRef<any>;

  @Input() convertText = 'Convert';


  selectedDataIndexes: boolean[] = [];
  selectionsForm: FormGroup = new FormGroup<any>({})

  moveDropdownVisible: boolean = false;

  massSelectControl: FormControl = new FormControl(false)
  massSelectSub?: Subscription;

  pagedData: T[] = [];
  clickedRow?: T; // this is for opening a dropdown menu for row actions

  total: number = 0;

  @Output() selectedActionPerformed: EventEmitter<{ itemIndices: number[]; action: 'move' | 'delete' | 'convert', target?: string }> =
    new EventEmitter<{ itemIndices: number[]; action: "move" | "delete" | "convert", target?: string }>();

  @Output() onAccessChanged: EventEmitter<{ approved: boolean, user: T }> = new EventEmitter<{ approved: boolean, user: T }>();
  displaySelectOptions = false;

  constructor() {
  }

  ngOnInit(): void {

    // this.sortedColumn = this.tableConfiguration?.sortColumn;
    // this.sortDirection = this.tableConfiguration?.sortDirection || 'asc';

    this.resetFormState();
  }

  private resetFormState() {
    if(this.tableConfiguration?.hasCheckbox) {
      this.massSelectSub?.unsubscribe();
      this.massSelectControl.setValue(false);

      this.selectionsForm = new FormGroup<any>({});
      this.data.forEach((item, index) => this.selectionsForm.addControl(index.toString(), new FormControl(false)));
      this.massSelectSub = this.massSelectControl.valueChanges.subscribe(val => {
        Object.keys(this.selectionsForm.controls).forEach(key => this.selectionsForm.get(key)?.setValue(val));
      });

      this.selectionsForm.valueChanges.subscribe()
      console.log(this)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].currentValue) {

      this.data = [];
      this.data = [...changes['data'].currentValue];

      this.resetFormState();
      // TODO pagination stuff
      // this.numberOfPages = Math.ceil(this.data.length / this.pageSize);
      // if (this.tableConfiguration?.paginated) {
      //   this.pagedData = this.data.slice(this.currentPageIndex * this.pageSize, Math.min((this.currentPageIndex * this.pageSize) + this.pageSize, this.data.length))
      //   this.calculateVisiblePages();
      // }
    }
    if (this.tableConfiguration?.totalProp) {
      let total = 0;
      this.data.forEach(d => {
        // @ts-ignore
        let num: number = d[this.tableConfiguration!.totalProp] as number;
        total += num;
      });
      this.total = total;
    }
  }

  ngOnDestroy(): void {
  }

  toggleCollapse() {
    if (this.tableConfiguration?.collapsible) {
      this.tableConfiguration.collapsed = !this.tableConfiguration.collapsed;
    }
  }

  onMoveSelectedItems(opt: string) {
    this.selectedActionPerformed.emit({
      itemIndices: Object.keys(this.selectionsForm.controls).filter(ctrl => this.selectionsForm.get(ctrl)?.value).map(i => Number(i)),
      action: 'move',
      target: opt
    });
    this.moveDropdownVisible = false;
  }

  onDeleteSelectedItems() {
    this.selectedActionPerformed.emit({
      itemIndices: Object.keys(this.selectionsForm.controls).filter(ctrl => this.selectionsForm.get(ctrl)?.value).map(i => Number(i)),
      action: 'delete'
    });
  }

  isIndeterminate() {
    const selectedCount = Object.keys(this.selectionsForm.controls).filter(ctrl => this.selectionsForm.get(ctrl)?.value).length;
    return selectedCount !== 0 && selectedCount !== Object.keys(this.selectionsForm.controls).length;
  }

  toggleSort(col: TableColumn<T>) {
    if (this.tableConfiguration) {
      if (this.tableConfiguration.sortColumn === col.itemProperty) {
        this.tableConfiguration.sortDirection = this.tableConfiguration.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.tableConfiguration.sortColumn = col.itemProperty;
        this.tableConfiguration.sortDirection = 'asc';
      }
      this.pagedData = this.data.sort((a, b) => {
        let valueA = a[this.tableConfiguration!.sortColumn!];
        let valueB = b[this.tableConfiguration!.sortColumn!];

        // Determine if ascending or descending
        if (this.tableConfiguration!.sortDirection === 'asc') {
          return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        } else {
          return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
        }
      });
      // TODO implement if we still do pagination in this component
      // if (this.tableConfiguration?.paginated) {
      //   this.pagedData = this.data.slice(this.currentPageIndex * this.pageSize, Math.min((this.currentPageIndex * this.pageSize) + this.pageSize, this.data.length))
      //   this.setPage(0)
      // }
    }
  }

  onRowClick(row: any) {
    if (!!this.tableConfiguration?.onRowClick) {
      this.tableConfiguration.onRowClick(row);
    }
  }

  getControlForRow(i: number): FormControl {
    return this.selectionsForm.get(i.toString()) as FormControl;
  }

  onCheckboxToggle($event: boolean, row: T) {

  }

  onConvertSelectedItems() {
    this.selectedActionPerformed.emit({
      itemIndices: this.getSelectedIndices(),
      action: 'convert'
    });
  }

  getSelectedIndices(): number[] {
    return Object.keys(this.selectionsForm.controls)
      .filter(ctrl => this.selectionsForm.get(ctrl)?.value) // Get checked controls
      .map(i => Number(i)); // Convert keys to numbers
  }

  getDisplayForElement(row: T, col: TableColumn<T>) {
    if (!!col.displayMask) {
      return col.displayMask(row);
    } else {
      return String(row[col.itemProperty]);
    }
  }

  getImageAltInitials(row: T, col: TableColumn<T>) {
    let displayName = String(row[col.itemProperty]);

    // TODO is this dumb?
    let initials = `${displayName.split(' ')[0]?.at(0)?.toUpperCase()}${displayName.split(' ')[1]?.at(0)?.toUpperCase()}`
    return !!initials ? initials : 'N/A';  }

  manageAccess(row: T, b: boolean) {
    this.onAccessChanged.emit({approved: b, user: row});
  }

  toggleRowDropdown(row: T) {
    if (!this.clickedRow) {
      this.clickedRow = row;
    } else if (this.clickedRow !== row) {
      this.clickedRow = row;
    } else {
      this.clickedRow = undefined;
    }
  }

  onTrigger(row: T, type: string) {
    if (type === 'delete' && this.tableConfiguration?.deleteRowFunction) {
      this.tableConfiguration.deleteRowFunction(row);
    }
    if (type === 'edit' && this.tableConfiguration?.editRowFunction) {
      this.tableConfiguration.editRowFunction(row);
    }
    if (type === 'download' && this.tableConfiguration?.downloadRowFunction) {
      this.tableConfiguration.downloadRowFunction(row);
    }
    if (type === 'move' && this.tableConfiguration?.moveRowFunction) {
      this.tableConfiguration.moveRowFunction(row);
    }
    if (type === 'comment' && this.tableConfiguration?.commentRowFunction) {
      this.tableConfiguration.commentRowFunction(row);
    }
    this.closeDropdown();
    // this.coreService.dataChange.next(); TODO
  }

  closeDropdown() {
    this.clickedRow = undefined;
  }

  shouldDisplayTotal() {
    return !!this.tableConfiguration?.totalProp &&
      (this.tableConfiguration.collapsed || this.total > 0);
  }

  @HostListener('document:click', ['$event'])
  clickout(event: { target: any; }) {
    if (this.dropdownMenu && !this.dropdownMenu.nativeElement.contains(event.target)) {
      this.moveDropdownVisible = false;
    }
  }

  public get selectedControlCount(): number {
    return Object.keys(this.selectionsForm.controls).filter(ctrl => this.selectionsForm.get(ctrl)?.value).length
  }

  displayCommentIcon(row: T) {
    // @ts-ignore
    return row['comments']?.length > 0;
  }
}
