<tandem-loading-container [isLoaded]="authInitialized && themeLoaded">
  <div id="signUp" class="flex min-h-[100vh] flex-col justify-center pb-12 px-5 sm:px-6 lg:px-8 relative bg-primary">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img [src]="logoPath" class="mx-auto h-40 w-auto">
      <h2 class=" text-center text-2xl font-bold leading-9 tracking-tight text-gray-50">{{activeTab === 'auth' ? 'Sign up for an account' : activeTab === 'verify' ? 'Verify your email' : 'Enter additional info'}}</h2>
    </div>

    <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-[480px]">
      <div class="bg-white px-6 py-8 shadow rounded-lg sm:px-12">
        <ng-container *ngIf="activeTab === 'auth'">
          <form class="space-y-6">
            <tandem-form-input [form]="authForm" controlName="email" label="Email Address" placeholder="Email Address" inputType="email"></tandem-form-input>
            <tandem-form-input [form]="authForm" controlName="password" label="Password" placeholder="Password" inputType="password" [hideErrorMessage]="true"></tandem-form-input>
            <div *ngIf="passwordErrorMessage" class="text-red-500 text-sm mt-1">
              {{ passwordErrorMessage }}
            </div>
            <div>
              <tandem-button class="w-full mt-2" [onClick]="registerWithEmail.bind(this)" [disabled]="authForm.invalid || preventButtonPress || !!passwordErrorMessage">Sign Up</tandem-button>
            </div>
          </form>

          <div>
            <div class="relative mt-10">
              <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-200"></div>
              </div>
              <div class="relative flex justify-center text-sm font-medium leading-6">
                <span class="bg-white px-6 text-gray-900">Or continue with</span>
              </div>
            </div>

            <div class="mt-6 flex justify-center gap-4">
              <a (click)="onGoogleLogin()" class="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:cursor-pointer focus-visible:ring-transparent">
                <svg class="h-5 w-5" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z" fill="#EA4335" />
                  <path d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z" fill="#4285F4" />
                  <path d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z" fill="#FBBC05" />
                  <path d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z" fill="#34A853" />
                </svg>
                <span class="text-sm font-semibold leading-6">Google</span>
              </a>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="activeTab === 'verify'">
          <div class="flex flex-col items-center justify-center gap-4">
            <h4>Thank you for signing up! We're excited to have you as part of our community, and we hope you're excited
              to be here!
              To complete your registration and ensure the security of your account, we've sent a verification link to
              the email address you provided during sign-up. After clicking that link, come back to this screen. You
              might have to refresh your screen after verifying.</h4>
            <div class="my-8 flex flex-col gap-4">
              <h4>Didn't receive an email? </h4>
              <div class="flex flex-row justify-center">
                <tandem-button class="inline-block" theme="outline" color="primary" [onClick]="resend.bind(this)">Resend
                  verification
                </tandem-button>
                <!--        <tandem-button class="inline-block" theme="solid" color="primary" [onClick]="refresh.bind(this)">Refresh</tandem-button>-->
              </div>
            </div>

            <div class="flex flex-row justify-center items-center gap-4">
              <h5>Need to use a different email?</h5>
              <tandem-button theme="solid" color="primary">Logout</tandem-button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="activeTab === 'info'">
          <h4 class="w-full text-center mb-8">Almost done! We just need a bit more information about you in order to provide you with the best experience. Please fill out the form below.</h4>
          <form class="space-y-6">
            <tandem-form-input [form]="infoForm"
                               [label]="'First Name'"
                               [controlName]="'firstName'"
                               [inputId]="'firstName'"
                               [placeholder]="'First Name'"></tandem-form-input>
            <tandem-form-input [form]="infoForm"
                               [label]="'Last Name'"
                               [controlName]="'lastName'"
                               [inputId]="'lastName'"
                               [placeholder]="'Last Name'"></tandem-form-input>
<!--            <tandem-form-input *ngIf="user?.accountType === 'coach'"-->
<!--                               [form]="infoForm"-->
<!--                               [label]="'Business Name'"-->
<!--                               [controlName]="'coachBusinessName'"-->
<!--                               [inputId]="'coachBusinessName'"-->
<!--                               [placeholder]="'Business Name'"></tandem-form-input>-->
            <tandem-form-input [form]="infoForm"
                               [label]="'Phone Number'"
                               [controlName]="'phoneNumber'"
                               [inputId]="'phoneNumber'"
                               [placeholder]="'Phone Number'"
                               [maskType]="'phone'"></tandem-form-input>
            <div>
              <tandem-button class="w-full mt-2" [onClick]="submitInfo.bind(this)" [disabled]="infoForm.invalid" color="primary">Submit</tandem-button>
            </div>
          </form>
        </ng-container>
      </div>

      <p *ngIf="activeTab === 'auth'" class="mt-4 text-center text-sm text-gray-50">
        Already a member?
        <a class="font-semibold leading-6 hover:cursor-pointer" (click)="goToLogin()">Sign In</a>
      </p>
    </div>
    <tandem-button *ngIf="activeTab === 'auth'" color="white" icon="down-circle" class="w-fit mx-auto mt-6 animate-slowBounce" [onClick]="scrollToElement.bind(this)">Learn More</tandem-button>
<!--    <button type="button" class=" w-20 h-16 mx-auto mt-8 rounded-full  p-2 text-white shadow-sm flex items-center">-->
<!--      Learn More-->
<!--      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 w-20 h-16">-->
<!--        <path stroke-linecap="round" stroke-linejoin="round" d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />-->
<!--      </svg>-->
<!--    </button>-->
  </div>
  <ng-container *ngIf="activeTab === 'auth' && landingPageConfig">
    <div id="info" class="bg-white py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:text-center">
          <h3 class="mt-2 text-3xl font-bold tracking-tight text-black sm:text-4xl">{{landingPageConfig.whyHeaderTitle}}</h3>
          <p class="mt-6 text-lg leading-8 text-gray-700">{{landingPageConfig.whyHeaderBody}}</p>
        </div>
        <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl class="grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            <div class="flex flex-col bg-primary rounded-xl p-4 mx-4">
              <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                <img class="h-8 w-8 text-primary" src="assets/svg/features/passive-income.svg" alt="{{landingPageConfig.whySection1Title}}">
                {{landingPageConfig.whySection1Title}}
              </dt>
              <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-white">
                <p class="flex-auto">{{landingPageConfig.whySection1Body}}</p>
                <p class="mt-6">
                  <a [routerLink]="['/']" fragment="tandem-coaching-experience" class="text-sm font-semibold leading-6 text-primary-500">Learn more <span aria-hidden="true">→</span></a>
                </p>
              </dd>
            </div>
            <div class="flex flex-col bg-primary rounded-xl p-4 mx-4">
              <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                <img class="h-8 w-8 text-primary" src="assets/svg/features/elevated-branding.svg" alt="{{landingPageConfig.whySection2Title}}">
                {{landingPageConfig.whySection2Title}}
              </dt>
              <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-white">
                <p class="flex-auto">{{landingPageConfig.whySection2Body}}</p>
                <p class="mt-6">
                  <a [routerLink]="['/']" fragment="tandem-coaching-experience" class="text-sm font-semibold leading-6 text-primary-500">Learn more <span aria-hidden="true">→</span></a>
                </p>
              </dd>
            </div>
            <div class="flex flex-col bg-primary rounded-xl p-4 mx-4">
              <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                <img class="h-8 w-8 text-primary" src="assets/svg/features/email-list-growth.svg" alt="{{landingPageConfig.whySection3Title}}">
                {{landingPageConfig.whySection3Title}}
              </dt>
              <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-white">
                <p class="flex-auto">{{landingPageConfig.whySection3Body}}</p>
                <p class="mt-6">
                  <a [routerLink]="['/']" fragment="tandem-coaching-experience" class="text-sm font-semibold leading-6 text-primary-500">Learn more <span aria-hidden="true">→</span></a>
                </p>
              </dd>
            </div>
          </dl>
        </div>
        <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl class="grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            <div class="flex flex-col bg-primary rounded-xl p-4 mx-4">
              <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                <img class="h-8 w-8 text-primary" src="assets/svg/features/client-retention.svg" alt="{{landingPageConfig.whySection4Title}}">
                {{landingPageConfig.whySection4Title}}
              </dt>
              <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-white">
                <p class="flex-auto">{{landingPageConfig.whySection4Body}}</p>
                <p class="mt-6">
                  <a [routerLink]="['/']" fragment="tandem-coaching-experience" class="text-sm font-semibold leading-6 text-primary-500">Learn more <span aria-hidden="true">→</span></a>
                </p>
              </dd>
            </div>
            <div class="flex flex-col bg-primary rounded-xl p-4 mx-4">
              <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                <img class="h-8 w-8 text-primary" src="assets/svg/features/enhanced-communication.svg" alt="{{landingPageConfig.whySection5Title}}">
                {{landingPageConfig.whySection5Title}}
              </dt>
              <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-white">
                <p class="flex-auto">{{landingPageConfig.whySection5Body}}</p>
                <p class="mt-6">
                  <a [routerLink]="['/']" fragment="tandem-coaching-experience" class="text-sm font-semibold leading-6 text-primary-500">Learn more <span aria-hidden="true">→</span></a>
                </p>
              </dd>
            </div>
            <div class="flex flex-col bg-primary rounded-xl p-4 mx-4">
              <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                <img class="h-8 w-8 text-primary" src="assets/svg/features/advanced-insight.svg" alt="{{landingPageConfig.whySection6Title}}">
                {{landingPageConfig.whySection6Title}}
              </dt>
              <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-white">
                <p class="flex-auto">{{landingPageConfig.whySection6Body}}</p>
                <p class="mt-6">
                  <a [routerLink]="['/']" fragment="tandem-coaching-experience" class="text-sm font-semibold leading-6 text-primary-500">Learn more <span aria-hidden="true">→</span></a>
                </p>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>

    <div class="overflow-hidden bg-primary py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8" appFadeIn>
        <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div class="lg:ml-auto lg:pl-4 lg:pt-4">
            <div class="lg:max-w-lg">
              <h3 class="text-base font-semibold leading-7 text-white">Financial Clarity</h3>
              <h2 class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Tracking Tools</h2>
              <p class="mt-6 text-lg leading-8 text-gray-300">{{landingPageConfig.trackingToolHeader}}</p>
              <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                <div class="relative pl-9">
                  <dt class="inline font-semibold text-white">
                    <svg class="absolute left-1 top-1 h-5 w-5 text-primary-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                      <path fill-rule="evenodd" d="M5.5 17a4.5 4.5 0 0 1-1.44-8.765 4.5 4.5 0 0 1 8.302-3.046 3.5 3.5 0 0 1 4.504 4.272A4 4 0 0 1 15 17H5.5Zm3.75-2.75a.75.75 0 0 0 1.5 0V9.66l1.95 2.1a.75.75 0 1 0 1.1-1.02l-3.25-3.5a.75.75 0 0 0-1.1 0l-3.25 3.5a.75.75 0 1 0 1.1 1.02l1.95-2.1v4.59Z" clip-rule="evenodd" />
                    </svg>
                    {{landingPageConfig.fpName}}.
                  </dt>
                  <dd class="inline">{{landingPageConfig.fpDescription}}</dd>
                </div>
                <div class="relative pl-9">
                  <dt class="inline font-semibold text-white">
                    <svg class="absolute left-1 top-1 h-5 w-5 text-primary-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                      <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 0 0-4.5 4.5V9H5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2h-.5V5.5A4.5 4.5 0 0 0 10 1Zm3 8V5.5a3 3 0 1 0-6 0V9h6Z" clip-rule="evenodd" />
                    </svg>
                    {{landingPageConfig.cfName}}.
                  </dt>
                  <dd class="inline">{{landingPageConfig.cfDescription}}</dd>
                </div>
                <div class="relative pl-9">
                  <dt class="inline font-semibold text-white">
                    <svg class="absolute left-1 top-1 h-5 w-5 text-primary-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                      <path d="M4.632 3.533A2 2 0 0 1 6.577 2h6.846a2 2 0 0 1 1.945 1.533l1.976 8.234A3.489 3.489 0 0 0 16 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234Z" />
                      <path fill-rule="evenodd" d="M4 13a2 2 0 1 0 0 4h12a2 2 0 1 0 0-4H4Zm11.24 2a.75.75 0 0 1 .75-.75H16a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75h-.01a.75.75 0 0 1-.75-.75V15Zm-2.25-.75a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 0 0 .75-.75V15a.75.75 0 0 0-.75-.75h-.01Z" clip-rule="evenodd" />
                    </svg>
                    {{landingPageConfig.spName}}.
                  </dt>
                  <dd class="inline">{{landingPageConfig.spDescription}}</dd>
                </div>
              </dl>
              <div class="max-sm:hidden mt-4 py-6 flex justify-center">
                <a (click)="scrollToSignup()" class="cursor-pointer -mx-3 block rounded-lg px-3 py-2.5 text-xl font-semibold leading-7 text-primary bg-white w-fit px-8">Get Started</a>
              </div>
            </div>
          </div>
          <div class="flex items-start justify-end lg:order-first">

            <div  class="relative bg-primary-500 w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] h-[40rem] " >
              <div class="w-full h-full absolute right-2 top-2 rounded-xl bg-white shadow-xl ring-1 ring-gray-400/10 flex flex-col order-first items-center pt-32">
                <h3 class="text-2xl font-semibold text-gray-800">{{ currentGraph === 0 ? 'Financial Position' : currentGraph === 1 ? 'Cash Flow' : 'Spending Plan' }}</h3>
                <div *ngIf="currentGraph === 0" class="h-[22rem] flex flex-col items-center justify-center">
                  <app-financial-position-graph [useCase]="'blog'"></app-financial-position-graph>
                </div>
                <div *ngIf="currentGraph === 1" class="h-[22rem] flex flex-col items-center justify-center">
                  <app-cash-flow-graph [useCase]="'blog'"></app-cash-flow-graph>
                </div>
                <div *ngIf="currentGraph === 2" class="h-[22rem] flex flex-col items-center justify-center">
                  <app-spending-plan-graph [useCase]="'blog'"></app-spending-plan-graph>
                </div>

                <div class="flex space-x-2 mt-4 items-center">
                  <button (click)="previousGraph()" class="z-50 h-8 w-8 rounded-xl bg-gray-300 cursor-pointer mx-1">
                    &lt;
                  </button>
                  <button (click)="currentGraph = 0" class="z-50 h-4 w-4 rounded-full bg-gray-300 cursor-pointer mx-1" [ngClass]="{'bg-gray-500': currentGraph === 0}"></button>
                  <button (click)="currentGraph = 1" class="z-50 h-4 w-4 rounded-full bg-gray-300 cursor-pointer mx-1" [ngClass]="{'bg-gray-500': currentGraph === 1}"></button>
                  <button (click)="currentGraph = 2" class="z-50 h-4 w-4 rounded-full bg-gray-300 cursor-pointer mx-1" [ngClass]="{'bg-gray-500': currentGraph === 2}"></button>
                  <button (click)="nextGraph()" class="z-50 h-8 w-8 rounded-xl bg-gray-300 cursor-pointer mx-1">
                    &gt;
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="sm:hidden py-6 flex justify-center">
            <a (click)="scrollToSignup()" class="cursor-pointer -mx-3 block rounded-lg px-3 py-2.5 text-xl font-semibold leading-7 text-primary bg-white w-fit px-8">Get Started</a>
          </div>
        </div>
      </div>
    </div>


    <div class="overflow-hidden bg-white py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8" appFadeIn>
        <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div class="lg:pr-8 lg:pt-4">
            <div class="lg:max-w-lg">
              <h3 class="text-base font-semibold leading-7 text-primary-600">Goal Tracking</h3>
              <h2 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Set, Track, and Crush Goals</h2>
              <p class="mt-6 text-lg leading-8 text-gray-600">{{landingPageConfig.goalHeader}}</p>
              <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                <div class="relative pl-9">
                  <dt class="inline font-semibold text-gray-900">
                    <svg class="absolute left-1 top-1 h-5 w-5 text-primary-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                      <path fill-rule="evenodd" d="M5.5 17a4.5 4.5 0 0 1-1.44-8.765 4.5 4.5 0 0 1 8.302-3.046 3.5 3.5 0 0 1 4.504 4.272A4 4 0 0 1 15 17H5.5Zm3.75-2.75a.75.75 0 0 0 1.5 0V9.66l1.95 2.1a.75.75 0 1 0 1.1-1.02l-3.25-3.5a.75.75 0 0 0-1.1 0l-3.25 3.5a.75.75 0 1 0 1.1 1.02l1.95-2.1v4.59Z" clip-rule="evenodd" />
                    </svg>
                    Different Timelines.
                  </dt>
                  <dd class="inline">{{landingPageConfig.goal1Description}}</dd>
                </div>
                <div class="relative pl-9">
                  <dt class="inline font-semibold text-gray-900">
                    <svg class="absolute left-1 top-1 h-5 w-5 text-primary-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                      <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 0 0-4.5 4.5V9H5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2h-.5V5.5A4.5 4.5 0 0 0 10 1Zm3 8V5.5a3 3 0 1 0-6 0V9h6Z" clip-rule="evenodd" />
                    </svg>
                    Track Completions.
                  </dt>
                  <dd class="inline">{{landingPageConfig.goal2Description}}</dd>
                </div>
                <div class="relative pl-9">
                  <dt class="inline font-semibold text-gray-900">
                    <svg class="absolute left-1 top-1 h-5 w-5 text-primary-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                      <path d="M4.632 3.533A2 2 0 0 1 6.577 2h6.846a2 2 0 0 1 1.945 1.533l1.976 8.234A3.489 3.489 0 0 0 16 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234Z" />
                      <path fill-rule="evenodd" d="M4 13a2 2 0 1 0 0 4h12a2 2 0 1 0 0-4H4Zm11.24 2a.75.75 0 0 1 .75-.75H16a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75h-.01a.75.75 0 0 1-.75-.75V15Zm-2.25-.75a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 0 0 .75-.75V15a.75.75 0 0 0-.75-.75h-.01Z" clip-rule="evenodd" />
                    </svg>
                    Visualize Progress.
                  </dt>
                  <dd class="inline">{{landingPageConfig.goal3Description}}</dd>
                </div>
              </dl>
              <div class="max-sm:hidden mt-4 py-6 flex justify-center">
                <a (click)="scrollToSignup()" class="cursor-pointer -mx-3 block rounded-lg px-3 py-2.5 text-xl font-semibold leading-7 bg-primary text-white w-fit px-8">Get Started</a>
              </div>
            </div>
          </div>
          <div class="flex items-start justify-end lg:order-last">

            <div  class="relative bg-primary w-[32rem] max-w-lg rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[40rem] h-[30rem] " >

              <app-goal-card></app-goal-card>
<!--              <div class="absolute w-fit top-8 left-8">-->
<!--          <span class="text-lg font-semibold text-primary-600 z-40 flex items-center gap-2">-->
<!--                  <svg class="w-7 h-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M14.4444 3H16.5C17.3284 3 18 3.67157 18 4.5V6C18 6.82843 17.3284 7.5 16.5 7.5H14.4444M14.4444 3H11C6.02943 3 2 7.02944 2 12C2 16.9706 6.02944 21 11 21H14.4444M14.4444 3V7.5M14.4444 7.5H10.9444C8.45916 7.5 6.44444 9.51472 6.44444 12C6.44444 14.4853 8.45916 16.5 10.9444 16.5H14.4444M14.4444 16.5H16.5C17.3284 16.5 18 17.1716 18 18V19.5C18 20.3284 17.3284 21 16.5 21H14.4444M14.4444 16.5V21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M21.5 6C21.5 6 23 7.8 23 12C23 16.2 21.5 18 21.5 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>-->
<!--                  <path d="M19.5 9C19.5 9 20 9.9 20 12C20 14.1 19.5 15 19.5 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>-->
<!--                  </svg>-->
<!--                    Lead Magnet-->
<!--          </span>-->
<!--              </div>-->
            </div>
          </div>
          <div class="sm:hidden py-6 flex justify-center">
            <a (click)="scrollToSignup()" class="cursor-pointer -mx-3 block rounded-lg px-3 py-2.5 text-xl font-semibold leading-7 bg-primary text-white w-fit px-8">Get Started</a>
          </div>
        </div>
      </div>
    </div>

    <div class="overflow-hidden bg-primary py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8" appFadeIn>
        <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div class="lg:ml-auto lg:pl-4 lg:pt-4">
            <div class="lg:max-w-lg">
              <h3 class="text-base font-semibold leading-7 text-white">Future Planning</h3>
              <h2 class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Compounding Calculators</h2>
              <p class="mt-6 text-lg leading-8 text-gray-300">{{landingPageConfig.calculatorDescription}}</p>
              <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                <div class="relative pl-9">
                  <dt class="inline font-semibold text-white">
                    <svg class="absolute left-1 top-1 h-5 w-5 text-primary-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                      <path fill-rule="evenodd" d="M5.5 17a4.5 4.5 0 0 1-1.44-8.765 4.5 4.5 0 0 1 8.302-3.046 3.5 3.5 0 0 1 4.504 4.272A4 4 0 0 1 15 17H5.5Zm3.75-2.75a.75.75 0 0 0 1.5 0V9.66l1.95 2.1a.75.75 0 1 0 1.1-1.02l-3.25-3.5a.75.75 0 0 0-1.1 0l-3.25 3.5a.75.75 0 1 0 1.1 1.02l1.95-2.1v4.59Z" clip-rule="evenodd" />
                    </svg>
                    Debt Payoff
                  </dt>
                  <dd class="inline">Visualize the savings of snowballing down debt</dd>
                </div>
                <div class="relative pl-9">
                  <dt class="inline font-semibold text-white">
                    <svg class="absolute left-1 top-1 h-5 w-5 text-primary-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                      <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 0 0-4.5 4.5V9H5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2h-.5V5.5A4.5 4.5 0 0 0 10 1Zm3 8V5.5a3 3 0 1 0-6 0V9h6Z" clip-rule="evenodd" />
                    </svg>
                    Investment
                  </dt>
                  <dd class="inline">Witness the power of the 8th Wonder of the World</dd>
                </div>
                <div class="relative pl-9">
                  <dt class="inline font-semibold text-white">
                    <svg class="absolute left-1 top-1 h-5 w-5 text-primary-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                      <path d="M4.632 3.533A2 2 0 0 1 6.577 2h6.846a2 2 0 0 1 1.945 1.533l1.976 8.234A3.489 3.489 0 0 0 16 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234Z" />
                      <path fill-rule="evenodd" d="M4 13a2 2 0 1 0 0 4h12a2 2 0 1 0 0-4H4Zm11.24 2a.75.75 0 0 1 .75-.75H16a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75h-.01a.75.75 0 0 1-.75-.75V15Zm-2.25-.75a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 0 0 .75-.75V15a.75.75 0 0 0-.75-.75h-.01Z" clip-rule="evenodd" />
                    </svg>
                    Debt vs. Invest
                  </dt>
                  <dd class="inline">Pay off debt or invest? See what the numbers say!</dd>
                </div>
              </dl>
              <div class="max-sm:hidden mt-4 py-6 flex justify-center">
                <a (click)="scrollToSignup()" class="cursor-pointer -mx-3 block rounded-lg px-3 py-2.5 text-xl font-semibold leading-7 text-primary bg-white w-fit px-8">Get Started</a>
              </div>
            </div>
          </div>
          <div class="flex items-start justify-end lg:order-first">
            <div class="relative bg-primary-500 w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] h-[40rem] ">
              <div class="w-full h-full absolute right-2 top-2 rounded-xl bg-white shadow-xl ring-1 ring-gray-400/10 flex flex-col order-first items-center pt-16">
                <h3 class="text-2xl font-semibold text-primary mb-16">{{ currentCalculator === 0 ? 'Debt Payoff' : currentCalculator === 1 ? 'Investment' : 'Debt Vs. Invest' }}</h3>
                <div *ngIf="currentCalculator === 0" class="h-[22rem] flex flex-col items-center justify-center">
                  <app-debt-payoff [useCase]="'home'"></app-debt-payoff>
                </div>
                <div *ngIf="currentCalculator === 1" class="h-[22rem] flex flex-col items-center justify-center">
                  <app-investment [useCase]="'home'"></app-investment>
                </div>
                <div *ngIf="currentCalculator === 2" class="h-[22rem] flex flex-col items-center justify-center">
                  <app-debt-vs-invest [useCase]="'home'"></app-debt-vs-invest>
                </div>

                <div class="flex space-x-2 mt-4 items-center">
                  <button (click)="previousCalculator()" class="z-50 h-8 w-8 rounded-xl bg-gray-300 cursor-pointer mx-1">
                    &lt;
                  </button>
                  <button (click)="currentCalculator = 0" class="z-50 h-4 w-4 rounded-full bg-gray-300 cursor-pointer mx-1" [ngClass]="{'bg-gray-500': currentCalculator === 0}"></button>
                  <button (click)="currentCalculator = 1" class="z-50 h-4 w-4 rounded-full bg-gray-300 cursor-pointer mx-1" [ngClass]="{'bg-gray-500': currentCalculator === 1}"></button>
                  <button (click)="currentCalculator = 2" class="z-50 h-4 w-4 rounded-full bg-gray-300 cursor-pointer mx-1" [ngClass]="{'bg-gray-500': currentCalculator === 2}"></button>
                  <button (click)="nextCalculator()" class="z-50 h-8 w-8 rounded-xl bg-gray-300 cursor-pointer mx-1">
                    &gt;
                  </button>
                </div>
              </div>
<!--              <app-lead-magnet></app-lead-magnet>-->
            </div>
          </div>
          <div class="sm:hidden py-6 flex justify-center">
            <a (click)="scrollToSignup()" class="cursor-pointer -mx-3 block rounded-lg px-3 py-2.5 text-xl font-semibold leading-7 text-primary bg-white w-fit px-8">Get Started</a>
          </div>
        </div>
      </div>
    </div>



  </ng-container>

</tandem-loading-container>
