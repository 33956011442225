import { Injectable } from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {of} from "rxjs";
import {LinkData} from "../models/link-data";
import {LinkedInstitution} from "../models/linked-institution";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PlaidService {

   private SERVER_PATH = environment.functionsPath;

  constructor(private afAuth: AngularFireAuth,
              private http: HttpClient) { }

  public async getLinkToken() {
    const idToken = await this.getIdToken();
    if (idToken) {
      const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
      return this.http.get<{link_token: string}>(`${this.SERVER_PATH}/getLinkToken`, { headers }).toPromise();
    }
    // Handle error or throw an error if no user or ID token
    throw new Error('User is not currently signed in.');
  }

  public async processLinkData(linkData: LinkData) {
    const idToken = await this.getIdToken();
    if (idToken) {
      const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
      return this.http.post<{message: string}>(`${this.SERVER_PATH}/processLinkData`, linkData,{ headers }).toPromise();
    }
    // Handle error or throw an error if no user or ID token
    throw new Error('User is not currently signed in.');
  }

  public async getLinkedInstitutions() {
    const idToken = await this.getIdToken();
    if (idToken) {
      const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
      return this.http.get<LinkedInstitution[]>(`${this.SERVER_PATH}/getInstitutionsForUser`,{ headers }).toPromise();
    }
    // Handle error or throw an error if no user or ID token
    throw new Error('User is not currently signed in.');
  }

  public async getAccountBalance(accountId: string) {
    const idToken = await this.getIdToken();
    if (idToken) {
      const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
      const params = new HttpParams().set('accountId', accountId);
      return this.http.get<LinkedInstitution[]>(`${this.SERVER_PATH}/getAccountBalance`,{ headers, params }).toPromise();
    }
    // Handle error or throw an error if no user or ID token
    throw new Error('User is not currently signed in.');
  }

  private async getIdToken() {
    const user = await this.afAuth.currentUser;
    if (user) {
      return user.getIdToken();
    }
    return null;
  }
}
