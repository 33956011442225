import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {environment} from "../../../../../environments/environment";
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogService} from "../../../tandem-core/services/dialog.service";
import {RegistrationAttemptService} from "../../services/registration-attempt.service";
import {ThemingService} from "../../../tandem-core/services/theming.service";

@Component({
  selector: 'tandem-register',
  templateUrl: './coach-register.component.html',
  styleUrls: ['./coach-register.component.scss']
})
export class CoachRegisterComponent implements OnInit {

  PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
  public registerForm: FormGroup = new FormGroup<any>({});

  lightMode = true;

  errorMessage: string | null = null;
  envName: string = environment.environmentName;
  coachId: string | null = null;
  registrationMode: string | null = null; // coachRevShare || coachLeadGen
  preventButtonPress = false;

  coachModel: 'coachRevShare' | 'coachLeadGen' = 'coachRevShare';

  passwordErrorMessage: string | null = null;

  whiteIconPath: string = environment.whiteIconPath;
  primaryIconPath: string = environment.primaryIconPath;
  logoPath: string = environment.logoPath;
  whiteLogoPath: string = environment.whiteLogoPath;

  themeLoaded = false;

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private dialogService: DialogService,
              private themingService: ThemingService,
              private registrationAttemptService: RegistrationAttemptService,
              private fb: FormBuilder) {
    this.coachId = this.route.snapshot.queryParamMap.get('coachId');
    if (['coachRevShare', 'coachLeadGen'].includes(this.route.snapshot.queryParamMap.get('coachModel') || '')) {
      this.coachModel = this.route.snapshot.queryParamMap.get('coachModel') as 'coachRevShare' | 'coachLeadGen';
    }
  }

  ngOnInit() {
    this.themingService.brandingConfig.subscribe(config => {
      console.log(config)
      if (config) {
        if (config.logoURL) {
          this.logoPath = config.logoURL
        }
        this.themeLoaded = true;
      }
    })
    this.registerForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.pattern(this.PASSWORD_REGEX)]]
    });
    this.registerForm.get('password')?.valueChanges.subscribe(pass => {
      if(this.registerForm.get('password')?.dirty) {
        this.passwordErrorMessage = this.validatePassword(pass);
      }
    })
  }

  onGoogleLogin() {
    if (!this.preventButtonPress) {
      this.preventButtonPress = true;
      this.authService.signInWithGoogle({coachType: this.coachModel, accountType: 'coach'}).then(res => {
        this.router.navigate(['auth/additional-info']) // TODO already routing in the auth service, not sure why this is necessary
      }).catch(err => {
        this.preventButtonPress = false;
      }).finally(() => {
        this.preventButtonPress = false;
      });
    }
  }

  displayError(message: string): void {
    this.errorMessage = message;
    setTimeout(() => {
      this.errorMessage = ""
    }, 3000)
    this.preventButtonPress = false;
  }

  registerWithEmail() {
    if (!this.preventButtonPress) {
      this.preventButtonPress = true;
      if (this.registerForm.invalid) {
        this.displayError("Please correct the form errors before submitting.");
      } else {
        this.authService.registerWithEmailPass(
          this.registerForm.get('email')?.value,
          this.registerForm.get('password')?.value,
          {coachType: this.coachModel, accountType: 'coach', email: this.registerForm.get('email')?.value},
        ).then(res => {
          // Handle successful registration
          this.router.navigate(['/auth/email-not-verified']) // TODO already routing in the auth service, not sure why this is necessary
        }).catch(error => {
          this.displayError("Registration failed. Please try again.");
        }).finally(() => {
          this.preventButtonPress = false;
        });
      }
    }
  }

  validatePassword(password: string): string | null {
    if (!password) return "Password is required.";
    if (password.length < 8) return "Password must be at least 8 characters long.";
    if (!/[A-Z]/.test(password)) return "Password must contain at least one capital letter.";
    if (!/[!@#$%^&*]/.test(password)) return "Password must contain at least one special character (!@#$%^&*).";
    return null;
  }

  protected readonly environment = environment;

  setCoachModel(coachModel: 'coachRevShare' | 'coachLeadGen') {
    this.coachModel = coachModel;
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { coachModel: coachModel },
        queryParamsHandling: 'merge' // This will preserve other query params
      }
    );
  }

  goToLogin() {
    this.router.navigate(['/login'], {
      queryParams: { from: 'coach' },
      queryParamsHandling: 'merge'
    });
  }

  goToEndUserRegistration() {
    this.router.navigate(['/register'], {
      queryParams: { from: 'coach' },
      queryParamsHandling: 'merge'
    });
  }
}
