import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthService } from "../services/auth.service";
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private authRoutes = [
    '/auth/login',
    '/auth/register',
    '/auth/coach-registration'
  ];
  private accountSetupRoutes = [
    '/auth/email-not-verified',
    '/auth/additional-info'
  ];
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const requiresAuth = next.data['requiresAuth'] as boolean ?? true;
    const requiredRole = next.data['requiredRole'] as string | undefined;
    const currentUrl = state.url;

    return this.authService.auth.user.pipe(
      switchMap(firebaseUser => {
        // Case 1: No user, but route requires auth
        if (!firebaseUser && requiresAuth) {
          this.router.navigate(['/login']);
          return of(false);
        }

        // Case 2: No user, route doesn't require auth (login/register pages)
        if (!firebaseUser && !requiresAuth) {
          return of(true);
        }

        // At this point, we have a user

        // Case 3: User is trying to access auth routes (login/register)
        if (this.authRoutes.some(route => currentUrl.includes(route))) {
          this.router.navigate(['/dashboard']);
          return of(false);
        }

        // Case 4: Get user claims and check status/roles
        return this.validateUserAccess(firebaseUser!, requiredRole, currentUrl);
      }),
      // tap(isAllowed => console.log(`Guard decision for ${currentUrl}: ${isAllowed}`))
    );
  }

  private validateUserAccess(
    firebaseUser: firebase.User,
    requiredRole: string | undefined,
    currentUrl: string
  ): Observable<boolean> {
    return new Observable<boolean>(observer => {
      firebaseUser.getIdTokenResult(true).then(idTokenResult => {
        const claims = idTokenResult.claims;
        const status = claims['status'] as string;

        // Handle AccountCreation status
        if (status === 'AccountCreation') {
          if (!firebaseUser.emailVerified) {
            // User needs to verify email
            if (currentUrl === '/auth/email-not-verified') {
              observer.next(true);
            } else {
              this.router.navigate(['/auth/email-not-verified']);
              observer.next(false);
            }
          } else {
            // Email is verified, need additional info
            if (currentUrl === '/auth/additional-info') {
              observer.next(true);
            } else {
              this.router.navigate(['/auth/additional-info']);
              observer.next(false);
            }
          }
        }
        // Handle role requirements
        else if (requiredRole && !claims[requiredRole]) {
          this.router.navigate(['/dashboard']);
          observer.next(false);
        }
        // All checks passed
        else {
          observer.next(true);
        }

        observer.complete();
      }).catch(error => {
        // console.error('Error validating user access:', error);
        this.router.navigate(['/login']);
        observer.next(false);
        observer.complete();
      });
    });
  }
}
