import { Injectable } from '@angular/core';
import {
  ArcElement,
  CategoryScale,
  Chart,
  ChartData,
  DoughnutController,
  Legend,
  LegendItem,
  Title,
  Tooltip
} from "chart.js";
import {CurrencyService} from "./currency.service";
import {EChartsOption} from "echarts";
import {DecimalPipe} from "@angular/common";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {InvestmentDataPoint} from "../../calculators/models/investment-data-point";
import {DebtDataPoint} from "../../calculators/models/debt-data-point";

export interface DoughnutChartData {
  labels: string[];
  datasets: {
    label: string;
    data: any[];
    backgroundColor?: string[];
  }[];
}

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  SHADING_FACTOR: number = 1.7; // Adjust this factor to change the step difference

  constructor(private currencyService: CurrencyService, private breakpointObserver: BreakpointObserver,
              private decimalPipe: DecimalPipe) {
    Chart.register(DoughnutController, ArcElement, CategoryScale, Title, Tooltip, Legend);
  }

  public generateLineChart(xAxis: string, yAxis: string, labels: string[], dataSeries: {name: string, data: number[], color: string; disableByDefault?: boolean}[]): EChartsOption {
    let dataRanges: any[] = [];

    dataSeries.forEach(series => {
      dataRanges.push({
        name: series.name,
        data: series.data,
        type: 'line',
        smooth: true,
        symbolSize: 0,
        itemStyle: {
          color: series.color, // Assuming each series object has a color property
        },
        label: {
          show: false,
          position: 'top',
        },
      })
    })

    return {
      renderer: 'svg',
      xAxis: {
        type: 'category',
        data: labels,
        name: xAxis,
        nameLocation: 'middle',
        nameGap: 45,
      },
      yAxis: {
        type: 'value',
        name: yAxis,
        nameLocation: 'middle',
        nameGap: 75,
        nameRotate: 90,
        axisLabel: {
          formatter: (value: any) => `$${this.decimalPipe.transform(value)}`
        }
      },
      grid: {
        left: '5%', // or a small value like '10'
        right: '5%', // or a small value
        top: '10%', // or a small value
        bottom: this.breakpointObserver.isMatched(Breakpoints.Handset) ? '30%' : '20%', // or a small value
        containLabel: true // This ensures the labels are still visible within the container
      },
      legend: {
        show: true, // Set to true to display the legend
        data: dataSeries.map(ds => ds.name), // Names of the series
        left: 'center', // You can position the legend in the center
        top: '0', // You can position the legend at the top of the chart,
        icon: 'rect', // Use a built-in rectangle shape
        itemWidth: 14, // Width of the icon
        itemHeight: 14, // Height of the icon
        padding: 1,
        // Customize other properties like textStyle to match the series color if needed
        textStyle: {
          color: '#333', // Example text color
          // Apply additional text styles as needed
        },
        // You can customize the legend appearance further with additional properties
        selected: dataSeries.reduce((selectedObj, series) => {
          // @ts-ignore
          selectedObj[series.name] = !series.disableByDefault;
          return selectedObj;
        }, {})
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
        formatter: (params) => {
          if (Array.isArray(params)) {
            // Assuming all series share the same x-axis label, we take it from the first item
            let tooltipHeader = `<strong>${params[0].name}</strong><br/>`;

            let seriesInfo = params.map((item) => {
              const seriesName = item.seriesName;
              const valueFormattedAsCurrency = `${item.data.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
              const colorDot = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${item.color};"></span>`;

              return `${colorDot}${seriesName}: ${valueFormattedAsCurrency}`;
            }).join('<br/>');

            return tooltipHeader + seriesInfo;
          }

          return '';
        }
      },
      series: dataRanges,
    }
  }

  // public generatePieChart(dataSeries: {name: string, data: { name: string, value: number }[]}): EChartsOption {
  //   return {
  //     tooltip: {
  //       trigger: 'item',
  //       formatter: '{a} <br/>{b}: {c} ({d}%)'
  //     },
  //     series: dataSeries.map(ds => {
  //       return {
  //         name: ds.name,
  //         type: 'pie',
  //         selectedMode: 'single',
  //         radius: dataSeries.indexOf(ds) === 0 ? [0, '55%'] : ['70%', '85%'],
  //         label: dataSeries.indexOf(ds) === 0 ? {
  //           position: 'inner',
  //           fontSize: 14
  //         } : undefined,
  //         labelLine: {
  //           length: 30,
  //           show: dataSeries.indexOf(ds) !== 0
  //         },
  //         data: ds.data
  //       }
  //     })
  //   };
  // }

  public generatePieChart(innerSeries: {name: string, data: { name: string, value: number }[]}, outerSeries: {name: string, data: { name: string, value: number }[], greenItemCount: number, yellowItemCount: number, orangeItemCount: number, redItemCount: number}, showLegend: boolean, omitLabelLines: boolean): EChartsOption {

    // const greenShades = this.generateColorShades(85, 139, 47, 10);
    // const redShades = this.generateColorShades(161, 0, 0, 10);

    const redShades = this.generateShades('#F44336', 30);
    // const redShades = this.generateShades('#A10000', 30);
    const orangeShades = this.generateShades('#e69138', 30);
    const yellowShades = this.generateShades('#f1c232', 30);
    const greenShades = this.generateShades('#4CAF50', 30);
    // const greenShades = this.generateShades('#558b2f', 30);

    const coloredOuterSeriesData = outerSeries.data.map(item => {
      let i = outerSeries.data.indexOf(item);
      let color = '';
      if (i + 1 > outerSeries.greenItemCount) {
        if (i + 1 > outerSeries.greenItemCount + outerSeries.yellowItemCount) {
          if (i + 1 > outerSeries.greenItemCount + outerSeries.yellowItemCount + outerSeries.orangeItemCount) {
            color = redShades[i - (outerSeries.greenItemCount + outerSeries.yellowItemCount + outerSeries.orangeItemCount)];
          } else {
            color = orangeShades[i - (outerSeries.greenItemCount + outerSeries.yellowItemCount)];
          }
        } else {
          color = yellowShades[i - outerSeries.greenItemCount];
        }
      } else {
        color = greenShades[i];
      }
      return {
        name: item.name,
        value: item.value,
        itemStyle: {
          // color: i + 1 > outerSeries.greenItemCount ? redShades[i - outerSeries.greenItemCount] : greenShades[i]
          color: color
        }
      }
    });

    return {
      // title: {
      //   text: chartTitle, // Title text
      //   subtext: chartSubtitle ? chartSubtitle : undefined, // Subtitle text
      //   left: 'center', // Alignment: 'center', 'left', or 'right'
      //   bottom: 0,
      //   textStyle: {
      //     color: '#333', // Title text color
      //     fontSize: 20, // Title font size
      //     fontWeight: 'bold' // Title font weight
      //   },
      //   subtextStyle: {
      //     color: '#666', // Subtitle text color
      //     fontSize: 14, // Subtitle font size
      //     // Additional style options can be added here
      //   }
      // },
      legend: {
        show: showLegend,
        orient: 'vertical', // Align items vertically
        right: '5%',
        top: 'middle', // Align top of the legend with the middle of the container
        align: 'left', // Align text to the left
        data: innerSeries.data.map(item => item.name),
        // title: {
        //   text: 'Legend', // Title for the legend
        //   textStyle: {
        //     fontWeight: 'bold',
        //     fontSize: 14
        //   }
        // },
        backgroundColor: '#f5f5f5', // Background color of the legend (change as needed)
        borderRadius: 5, // Rounded corners
        padding: 10, // Padding inside the legend
        itemGap: 10, // Gap between legend items
        borderWidth: 1, // Border width
        borderColor: '#ccc', // Border color
        selectedMode: false, // Disable the toggle functionality
      },
      grid: {
        right: '90%', // Give some space on the right for the legend
        containLabel: true // Ensure labels are within the grid
      },
      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          // Use Intl.NumberFormat to format the number as currency
          const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            // You can adjust minimumFractionDigits and maximumFractionDigits as needed
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
          // Format the value 'params.value' as currency
          const valueFormatted = currencyFormatter.format(params.value);
          // Return the formatted string
          return `${params.name}: ${valueFormatted} (${params.percent}%)`;
          // return `${params.seriesName} ${params.name}: ${valueFormatted} (${params.percent}%)`;
        }
      },
      series: [
        {
          name: innerSeries.name,
          type: 'pie',
          center: ['50%', '50%'], // Adjust the second value to move the pie chart up
          itemStyle: {
            borderRadius: 5,
            borderColor: '#fff',
            borderWidth: 1
          },
          selectedMode: 'single',
          radius: [0, '55%'],
          startAngle: 90,
          clockwise: false,
          label: undefined,
          // label: {
          //   position: 'inner',
          //   fontSize: 18
          // },
          labelLine: {
            show: false
          },
          data: (() => {
            const colorCounts = [
              { color: greenShades[0], count: outerSeries.greenItemCount > 0},
              { color: yellowShades[0], count: outerSeries.yellowItemCount > 0 },
              { color: orangeShades[0], count: outerSeries.orangeItemCount > 0 },
              { color: redShades[0], count: outerSeries.redItemCount > 0 }
            ];

            return innerSeries.data.map(item => {
              let assignedColor = 'defaultColor'; // Replace with your default color

              for (let colorInfo of colorCounts) {
                if (colorInfo.count) {
                  assignedColor = colorInfo.color;
                  colorInfo.count = false;
                  break;
                }
              }

              return ({
                ...item,
                itemStyle: {
                  color: assignedColor
                }
              });
            });
          })()
        },
        {
          name: outerSeries.name,
          type: 'pie',
          center: ['50%', '50%'], // Adjust the second value to move the pie chart up
          itemStyle: {
            borderRadius: 5,
            borderColor: '#fff',
            borderWidth: 1
          },
          radius: ['70%', '85%'],
          startAngle: 90,
          clockwise: false,
          label: undefined,
          labelLine: undefined,
          // labelLine: omitLabelLines ? undefined : {
          //   length: 30
          // },
          data: coloredOuterSeriesData
        }
      ]
    };
  }

  generateShades(startColor: string, count: number): string[] {
    const startRed = parseInt(startColor.substring(1, 3), 16);
    const startGreen = parseInt(startColor.substring(3, 5), 16);
    const startBlue = parseInt(startColor.substring(5, 7), 16);

    const endRed = 255;
    const endGreen = 255;
    const endBlue = 255;

    let shades: string[] = [];

    for (let i = 0; i < count; i++) {
      const interpolatedRed = this.interpolateColor(startRed, endRed, i, count);
      const interpolatedGreen = this.interpolateColor(startGreen, endGreen, i, count);
      const interpolatedBlue = this.interpolateColor(startBlue, endBlue, i, count);

      shades.push(`#${this.componentToHex(interpolatedRed)}${this.componentToHex(interpolatedGreen)}${this.componentToHex(interpolatedBlue)}`);
    }

    return shades;
  }

  private interpolateColor(start: number, end: number, step: number, maxStep: number): number {
    const delta = (end - start) / (maxStep - 1) * this.SHADING_FACTOR;
    const result = start + delta * step;

    // Ensure result is within [0, 255] range
    return Math.min(255, Math.max(0, Math.round(result)));
  }

  private componentToHex(c: number): string {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
  private generateColorShades(baseRed: number, baseGreen: number, baseBlue: number, shadeCount: number): string[] {
    const shades: string[] = [];
    for (let i = 0; i < shadeCount; i++) {
      // Calculate the increase in green and blue components to get lighter shades
      const redValue = Math.round(baseRed + (i / shadeCount) * (255 - baseRed));
      const greenValue = Math.round(baseGreen + (i / shadeCount) * (255 - baseGreen));
      const blueValue = Math.round(baseBlue + (i / shadeCount) * (255 - baseBlue));
      shades.push(`rgb(${redValue}, ${greenValue}, ${blueValue})`);
    }
    return shades;
  }

  public generateDoughnutChart(chartId: string, centerText: {label: string, value: number}, data: any, numOuterLabels: number, omitLegend: boolean = false): Chart<"doughnut"> {

    const chartValuePlugin = {
      id: `${chartId}Plugin`,
      afterDraw: (chart: any) => {
        let ctx = chart.ctx;
        let width = chart.width,
          height = chart.height;
        ctx.restore();

        // Manually set the styles based on your Tailwind config
        const fontSizePx = omitLegend ? 24 : 36; // From fontSize['28']
        let primaryColor = 'black'; // Replace with your actual primary color value


        ctx.font = `${fontSizePx}px ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`;
        ctx.fillStyle = primaryColor;

        let firstLine = centerText.label;
        let secondLine = this.currencyService.formatNumber(centerText.value);
        // if (centerText.value > 0) {
        //   ctx.fillStyle = 'green';
        // } else if (centerText.value < 0) {
        //   ctx.fillStyle = 'red';
        // }

        let firstLineX = Math.round((width - ctx.measureText(firstLine).width) / 2);
        let secondLineX = Math.round((width - ctx.measureText(secondLine).width) / 2);
        let firstLineY = height / (omitLegend ? 1.8 : 2.1) - fontSizePx; // Adjust this value to control the vertical spacing
        let secondLineY = height / (omitLegend ? 1.8 : 2.1);

        ctx.fillText(firstLine, firstLineX, firstLineY);
        ctx.fillText(secondLine, secondLineX, secondLineY);

        ctx.save();
      }
    };

    return new Chart(chartId, {
      type: 'doughnut',
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: !omitLegend,
            position: 'bottom',
            labels: {
              filter(item: LegendItem, data: ChartData): boolean {
                return data.labels!.indexOf(item.text) < numOuterLabels;
              }
            }
          },
          title: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function(context) {

                let label = context.dataset.label || '';
                //
                // if (context.datasetIndex === 0) {
                //   label = context.dataset.label[context.dataIndex];
                // }

                if (label) {
                  label += ': ';
                }
                if (context.parsed) {
                  label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed);
                }
                return label;
              },
              title: function(context) {
                if (context[0].datasetIndex == 0) {
                  return data.labels[context[0].dataIndex + data.datasets[1].data.length]
                } else {
                  return context[0].label;
                }
              }
            }
          },
        },
      },
      plugins: [chartValuePlugin]
    })
  }

  private frequencyConversionMap: Map<number, number> = new Map([
    [ 52, 12 / 52 ],  // Weekly
    [ 26, 12 / 26 ],  // Biweekly
    [ 12, 1 ],        // Monthly
    [ 4, 3 ],         // Quarterly
    [ 2, 6 ],         // Semiannually
    [ 1, 12 ]         // Annually
  ]);

  public generateInvestmentDataPoints(currentAge: number,
                                      targetAge: number,
                                      beginningBalance: number,
                                      initialContribution: number,
                                      investment: number,
                                      annualReturn: number,
                                      paymentFrequency: number,
                                      data: InvestmentDataPoint[],
                                      lastDataPoint: InvestmentDataPoint | null,
                                      monthLabels: string[]): {data: InvestmentDataPoint[], lastDataPoint: InvestmentDataPoint | null, monthLabels: string[]} {
    const currentDate = new Date();

    data = [];
    monthLabels = [`${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`]
    const firstPoint: InvestmentDataPoint = {period: 0, months: 0, dateLabel: new Date(), age: currentAge, beginningBalance: beginningBalance, contribution: initialContribution, capitalGains: 0, endingBalance: beginningBalance + initialContribution};
    data.push(firstPoint);
    lastDataPoint = firstPoint;

    const maxIterations = 1000;  // for example
    let iterations = 0;

    while (targetAge > lastDataPoint.age && iterations < maxIterations) {
      let months = lastDataPoint.months + this.frequencyConversionMap.get(paymentFrequency)!;
      let capitalGains: number = lastDataPoint.endingBalance * (1 + (annualReturn / paymentFrequency)) - lastDataPoint.endingBalance;

      const labelDate = new Date();
      labelDate.setMonth(labelDate.getMonth() + months);
      monthLabels.push(`${labelDate.getMonth() + 1}/${labelDate.getFullYear()}`);

      const dataPoint:InvestmentDataPoint = {
        period: ++lastDataPoint.period,
        months: months,
        dateLabel: labelDate,
        age: lastDataPoint.age + ((months - lastDataPoint.months) / 12),
        beginningBalance: lastDataPoint.endingBalance,
        contribution: investment,
        capitalGains: capitalGains,
        endingBalance: lastDataPoint.endingBalance + investment + capitalGains
      };
      data.push(dataPoint);
      lastDataPoint = dataPoint;

      iterations += 1;

      if (iterations === maxIterations) {
        console.warn("Maximum iterations reached. Please review your logic.");
        break;
      }
    }

    return {
      data, lastDataPoint, monthLabels
    };
  }

  public generateDebtDataPoints(beginningBalance: number,
                                annualInterestRate: number,
                                initialContribution: number,
                                payment: number,
                                paymentFrequency: number,
                                data: DebtDataPoint[],
                                lastDataPoint: DebtDataPoint | null,
                                monthLabels: string[], maxNumberOfPoints = 1000): {data: DebtDataPoint[], lastDataPoint: DebtDataPoint | null, monthLabels: string[]} {
    const currentDate = new Date();

    data = [];
    monthLabels = [`${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`]

    const firstPoint: DebtDataPoint = {period: 0, months: 0, dateLabel: new Date(), beginningBalance: beginningBalance, payment: initialContribution, interest: 0, principal: 0, endingBalance: beginningBalance - initialContribution};
    data.push(firstPoint);
    lastDataPoint = firstPoint;

    let iterations = 0;

    while (lastDataPoint!.endingBalance > 0 && iterations < maxNumberOfPoints) {

      let interest: number = lastDataPoint.endingBalance * (annualInterestRate / paymentFrequency);
      let principal: number = payment - interest;
      let months = lastDataPoint.months + this.frequencyConversionMap.get(paymentFrequency)!;

      const labelDate = new Date();
      labelDate.setMonth(labelDate.getMonth() + months);
      monthLabels.push(`${labelDate.getMonth() + 1}/${labelDate.getFullYear()}`);

      const dataPoint:DebtDataPoint = {
        period: ++lastDataPoint.period,
        months: months,
        dateLabel: labelDate,
        beginningBalance: lastDataPoint.endingBalance,
        payment: payment,
        interest: interest,
        principal: principal,
        endingBalance: lastDataPoint.endingBalance - principal
      };

      if (dataPoint.endingBalance < 0) {
        dataPoint.endingBalance = 0;
      }

      data.push(dataPoint);
      lastDataPoint = dataPoint;

      if (iterations++ === maxNumberOfPoints) {
        console.warn("Maximum iterations reached. Please review your logic.");
      }
    }
    return {
      data, lastDataPoint, monthLabels
    };
  }
}
