import { Injectable } from '@angular/core';
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AbstractDatabaseService } from "./abstract-database.service";
import { CurrencyService } from "./currency.service";
import { TandemNotification } from "../models/tandem-notification";
import { map } from "rxjs/operators";
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends AbstractDatabaseService<TandemNotification> {

  constructor(protected override afs: AngularFirestore,
              private currencyService: CurrencyService) {
    super('notifications', afs);
  }

  public getNotificationsForUser(userId: string) {
    return this.afs.collection<TandemNotification>(this.path, ref => ref.where('userId', '==', userId).orderBy("date", "desc"))
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a.payload.doc.data() as TandemNotification;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }

  public markAllNotificationsAsRead(userId: string) {
    const batch = this.afs.firestore.batch();

    return this.afs.collection<TandemNotification>(this.path,
      ref => ref.where('userId', '==', userId).where('isRead', '==', false))
      .get()
      .toPromise()
      .then(querySnapshot => {
        querySnapshot?.forEach(doc => {
          batch.update(doc.ref, { isRead: true });
        });
        return batch.commit();
      });
  }

  public deleteAllNotificationsForUser(userId: string) {
    const batch = this.afs.firestore.batch();

    return this.afs.collection<TandemNotification>(this.path,
      ref => ref.where('userId', '==', userId))
      .get()
      .toPromise()
      .then(querySnapshot => {
        querySnapshot?.forEach(doc => {
          batch.delete(doc.ref);
        });
        return batch.commit();
      });
  }
}
