import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogService} from "../../../tandem-core/services/dialog.service";
import {ThemingService} from "../../../tandem-core/services/theming.service";
import {Subscription} from "rxjs";
import {RegistrationAttemptService} from "../../services/registration-attempt.service";
import {Timestamp} from "firebase/firestore";
import {AuthService, AuthStatus} from "../../services/auth.service";

@Component({
  selector: 'tandem-new-coach-registration',
  templateUrl: './new-coach-registration.component.html',
  styleUrls: ['./new-coach-registration.component.scss']
})
export class NewCoachRegistrationComponent implements OnInit, OnDestroy {

  PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
  public authForm: FormGroup = new FormGroup<any>({});
  public infoForm: FormGroup = new FormGroup<any>({});

  lightMode = true;

  errorMessage: string | null = null;
  envName: string = environment.environmentName;
  coachId: string | null = null;
  preventButtonPress = false;

  passwordErrorMessage: string | null = null;

  whiteIconPath: string = environment.whiteIconPath;
  primaryIconPath: string = environment.primaryIconPath;
  logoPath: string = environment.logoPath;
  whiteLogoPath: string = environment.whiteLogoPath;

  coachModel: 'coachRevShare' | 'coachLeadGen' = 'coachRevShare';

  themeLoaded= false;

  authInitialized = false

  activeTab: 'auth' | 'verify' | 'info' = 'auth';

  private authSub?: Subscription;
  private brandingConfigSubscription: Subscription | undefined;
  private passwordChangesSubscription: Subscription | undefined;
  private emailVerificationSubscription: Subscription | undefined;

  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private dialogService: DialogService,
              private themingService: ThemingService,
              private registrationAttemptService: RegistrationAttemptService,
              private fb: FormBuilder) {

    this.authSub = this.auth.$authState.subscribe(auth => {
      this.authInitialized = auth.authInitialized;
      if (auth.authStatus === AuthStatus.VerifiedAndDocExists) {
        this.router.navigate(['/dashboard']);
        this.authSub?.unsubscribe();
        return;
      }

      if (auth.authStatus === AuthStatus.NotLoggedIn) {
        this.authForm.reset();
        this.activeTab = 'auth';
      }

      if (auth.authStatus === AuthStatus.EmailNotVerified) {
        this.authForm.reset();
        this.activeTab = 'verify';
        this.emailVerificationSubscription = this.auth.pollEmailVerification(5000).subscribe(verified => {
          if (verified) {
            this.auth.forceTokenRefresh();
          }
        });
      }

      if (auth.authStatus === AuthStatus.DocNotCreated) {
        this.infoForm.reset();
        this.activeTab = 'info';
      }
    })

    if (['coachRevShare', 'coachLeadGen'].includes(this.route.snapshot.queryParamMap.get('coachModel') || '')) {
      this.coachModel = this.route.snapshot.queryParamMap.get('coachModel') as 'coachRevShare' | 'coachLeadGen';
    }
  }

  ngOnInit() {
    this.brandingConfigSubscription = this.themingService.brandingConfig.subscribe(config => {
      console.log(config)
      if (config) {
        if (config.logoURL) {
          this.logoPath = config.logoURL
        }
        this.themeLoaded = true;
      }
    })
    this.infoForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phoneNumber: [null, Validators.required],
      coachBusinessName: [null, Validators.required],
    });
    this.authForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.pattern(this.PASSWORD_REGEX)]]
    });
    this.passwordChangesSubscription = this.authForm.get('password')?.valueChanges.subscribe(pass => {
      if(this.authForm.get('password')?.dirty) {
        this.passwordErrorMessage = this.validatePassword(pass);
      }
    })
  }

  ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.brandingConfigSubscription) {
      this.brandingConfigSubscription.unsubscribe();
    }
    if (this.passwordChangesSubscription) {
      this.passwordChangesSubscription.unsubscribe();
    }
    if (this.emailVerificationSubscription) {
      this.emailVerificationSubscription.unsubscribe();
    }
  }

  onGoogleLogin() {
    if (!this.preventButtonPress) {
      this.preventButtonPress = true;
      this.auth.signInWithGoogle().then(res => {
      }).catch(err => {
        this.preventButtonPress = false;
      }).finally(() => {
        this.preventButtonPress = false;
      });
    }
  }

  displayError(message: string): void {
    this.errorMessage = message;
    setTimeout(() => {
      this.errorMessage = "";
    }, 3000)
    this.preventButtonPress = false;
  }

  submitInfo() {
    this.dialogService.showLoadingDialog('Please wait, finalizing your account details...');
    this.auth.handleInitialAuth({
      coachType: this.coachModel,
      accountType: 'coach',
      email: this.authForm.get('email')?.value,
      firstName: this.infoForm.get('firstName')?.value,
      lastName: this.infoForm.get('lastName')?.value,
      phoneNumber: this.infoForm.get('phoneNumber')?.value,
      coachBusinessName: this.infoForm.get('coachBusinessName')?.value,
    }).then(res => {
      this.dialogService.closeModal2();
    }).catch(err => {
      this.dialogService.openModal2({
        title: 'Unexpected Error',
        content: `There was a problem in setting up your account. Please try again, and if the issue persists, contact support.`,
        type: 'failure',
        actions: [
          {
            text: 'Close',
            role: 'close',
            callback: this.dialogService.closeModal2
          }
        ]
      })
    })
  }

  registerWithEmail() {
    if (!this.preventButtonPress) {
      this.preventButtonPress = true;
      if (this.authForm.invalid) {
        this.displayError("Please correct the form errors before submitting.");
      } else {
        this.dialogService.showLoadingDialog(`Please wait...`);
        this.registrationAttemptService.add({
          email: this.authForm.get('email')?.value,
          dateModified: Timestamp.now(),
          dateCreated: Timestamp.now(),
          coachId: this.getCoachId(),
          accountType: 'user'
        }).then(regAttempt => {
          this.auth.registerWithEmailPass(
            this.authForm.get('email')?.value,
            this.authForm.get('password')?.value,
          ).then(res => {
            // Handle successful registration
            this.dialogService.closeModal2();
          }).catch(error => {
            this.displayError("Registration failed. Please try again.");
          }).finally(() => {
            this.preventButtonPress = false;
          });
        })
      }
    }
  }

  resend() {
    this.dialogService.showLoadingDialog('Sending another verification link...')
    this.auth.resendEmailVerification().subscribe(res => {
      this.dialogService.openModal2({
        type: 'info',
        title: 'Sent Email',
        content: `Successfully resent your verification email. Please check your inbox for the link. If you're still not seeing the email, please contact support.`,
        actions: [
          {
            text: 'Close',
            role: 'close',
            callback: this.dialogService.closeModal2
          }
        ]
      })
    }, err => {
      this.dialogService.openModal2({
        title: 'Unexpected Error',
        content: `There was a problem in resending your verification email. Please try again, and if the issue persists, contact support.`,
        type: 'failure',
        actions: [
          {
            text: 'Close',
            role: 'close',
            callback: this.dialogService.closeModal2
          }
        ]
      })
    })
  }

  validatePassword(password: string): string | null {
    if (!password) return "Password is required.";
    if (password.length < 12) return "Password must be at least 12 characters long.";
    if (!/[a-z]/.test(password)) return "Password must contain at least one lowercase letter.";
    if (!/[A-Z]/.test(password)) return "Password must contain at least one capital letter.";
    if (!/\d/.test(password)) return "Password must contain at least one number.";
    if (!/[!@#$%^&*]/.test(password)) return "Password must contain at least one special character (!@#$%^&*).";
    return null;
  }

  protected readonly environment = environment;

  goToLogin() {
    this.router.navigate(['/auth/login'], {
      queryParams: { from: 'user' },
      queryParamsHandling: 'merge'
    });
  }


  getCoachId(): string {
    let coachIdMap: {[domain: string]: string} = {
      'www.portal.lascarare.com': 'kfHRctJZveSPQibwafx7Ept09hD2',
      'portal.lascarare.com': 'kfHRctJZveSPQibwafx7Ept09hD2',
      'coach.drbudgets.com': 'ag7SoS5sHfQoefCpAa6vi4T32Yo1',
      'ernst-coaching.tandemfinance.app': 'Ei3UegUkdmWNy8xynNh2iEQnT4z1',
      'localhost:4200': 'J30sviRSpSaAjEGDOeKoPlBELfx1',
      'tst.tandemfinance.app': 'J30sviRSpSaAjEGDOeKoPlBELfx1'
    }

    if (coachIdMap[window.location.host]) {
      return coachIdMap[window.location.host];
    }

    if (this.coachId) {return this.coachId};

    return '';
  }

  scrollToElement() {
    const element = document.getElementById('info');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  setCoachModel(coachModel: 'coachRevShare' | 'coachLeadGen') {
    this.coachModel = coachModel;
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { coachModel: coachModel },
        queryParamsHandling: 'merge' // This will preserve other query params
      }
    );
  }
}
