import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AbstractDatabaseService} from "../../tandem-core/services/abstract-database.service";
import {RegistrationAttempt} from "../models/registration-attempt";

@Injectable({
  providedIn: 'root'
})
export class RegistrationAttemptService extends AbstractDatabaseService<RegistrationAttempt> {

  constructor(protected override afs: AngularFirestore,
              private afAuth: AngularFireAuth) {
    super('registrationAttempts', afs);
  }
}
