import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewChecked,
  Input
} from '@angular/core';
import { AuthService } from "../../../../auth/services/auth.service";
import { TandemUser } from "../../../../auth/models/tandem-user";
import { Chat } from "../comment-panel/comment-panel.component";
import { Timestamp } from "firebase/firestore";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {UserService} from "../../../../auth/user.service";
import {ChatMessageService} from "../../../services/chat-message.service";
import {ChatMessage} from "../../../models/chat-message";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'tandem-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @Input() openChatObservable: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  user: TandemUser | null = null;
  chatWindowExpanded = true;
  selectedChat: string | null = null;
  availableUsers: TandemUser[] = [];
  filteredUsers: TandemUser[] = [];
  showUserSelection = false;

  showCancelForUser = false;

  chats: {[key: string]: {
      userId: string;
      userName: string;
      userPhotoURL: string | null;
      lastSeen: Date;
      chatForm: FormGroup;
      messages: ChatMessage[];
      initialLoad: boolean;
    }} = {};

  userSearchForm: FormGroup = this.fb.group({username: null})

  coach?: TandemUser;
  private shouldScrollToBottom = false;

  @ViewChild('chatContainer') chatContainer?: ElementRef;
  private pingSound = new Audio('assets/mp3/chat-notification2.mp3'); // Path to your audio file

  @Output() changeSelectedChat: EventEmitter<string | null> = new EventEmitter<string | null>();

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private userService: UserService,
    private chatMessageService: ChatMessageService
  ) {}

  ngOnInit(): void {
    this.auth.$user.subscribe(user => {
      this.user = user;
      if (user) {
        if (user.accountType === 'coach') {
          this.userService.getUsersByCoachId(user.uid).subscribe(users => {
            this.availableUsers = users;
            this.filteredUsers = users;
          });
        } else if (user.coachId) {
          this.userService.get(user.coachId).subscribe(coach => {
            this.coach = coach;
          })
        }
      }
    });
    this.userSearchForm.get('username')?.valueChanges.subscribe(val => {
      this.onSearchInput(val);
    })
    this.openChatObservable.subscribe(userId => {

      if (this.coach && userId === this.coach.id) {
        if (!this.showCancelForUser) {
          this.selectUser(this.coach);
          this.showCancelForUser = true;
        } else {
          this.showCancelForUser = false;
          this.selectedChat = null;
        }
      } else {
        const user = this.availableUsers.find(user => user.id === userId);
        if (user) {
          this.selectUser(user);
        }
      }
    })
  }

  ngAfterViewInit(): void {
    // Scroll to bottom initially when the chat window opens
    this.shouldScrollToBottom = true;
  }

  ngAfterViewChecked(): void {
    if (this.shouldScrollToBottom) {
      this.scrollToBottom();
      this.shouldScrollToBottom = false;
    }
  }

  private scrollToBottom(): void {
    try {
      if (this.chatContainer?.nativeElement) {
        this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
      }
    } catch(err) {
      console.error('Could not scroll to bottom:', err);
    }
  }

  selectChat(chatId: string) {
    this.selectedChat = chatId;
    this.chatWindowExpanded = true;
    this.showUserSelection = false;
    this.changeSelectedChat.emit(this.chats[chatId].userId);
    setTimeout(() => this.scrollToBottom(), 5);
  }

  toggleChatExpanded() {
    this.chatWindowExpanded = !this.chatWindowExpanded;
    if (!this.chatWindowExpanded) {
      this.showUserSelection = false;
    }
  }

  showNewChatSelection() {
    if (this.coach) {
      if (!this.showCancelForUser) {
        this.selectUser(this.coach);
        this.showCancelForUser = true;
      } else {
        this.showCancelForUser = false;
        this.selectedChat = null;
      }
    } else {
      this.selectedChat = null;
      this.userSearchForm.reset();
      this.showUserSelection = true;
      this.filteredUsers = this.availableUsers;
    }
  }

  closeUserSelection() {
    this.showUserSelection = false;
    this.filteredUsers = this.availableUsers;
    this.userSearchForm.reset();
  }

  onSearchInput(val: string) {
    this.filteredUsers = this.availableUsers.filter(user =>
      user.firstName?.toLowerCase().includes(val) ||
      user.lastName?.toLowerCase().includes(val)
    );
  }

  selectUser(user: TandemUser) {
    if (!this.chats[user.uid]) {
      // Initialize chat window
      this.chats[user.uid] = {
        userId: user.uid,
        userName: `${user.firstName} ${user.lastName}`,
        userPhotoURL: user.photoURL || null,
        lastSeen: new Date(),
        chatForm: this.fb.group({message: ['', Validators.required]}),
        messages: [],
        initialLoad: true // Track if it's the initial load
      };

      // Listen to chat changes
      this.chatMessageService.getMessagesForMembers(this.user!.uid, user.uid).subscribe(chats => {
        const previousMessagesCount = this.chats[user.uid].messages.length;
        this.chats[user.uid].messages = chats;

        // Play ping sound only if it's not the initial load and new messages have arrived
        if (!this.chats[user.uid].initialLoad && chats.length > previousMessagesCount) {
          // this.playPingSound();
        }

        // Set initialLoad to false after the first update
        this.chats[user.uid].initialLoad = false;
      });
    }

    this.selectedChat = user.uid;
    this.showUserSelection = false;
    this.userSearchForm.reset();
    this.changeSelectedChat.emit(user.uid);
    this.scrollToBottom();
  }


  private playPingSound() {
    this.pingSound.play().catch((error: any) => {
      console.error('Could not play ping sound:', error);
    });
  }

  closeChat(userId: string) {
    delete this.chats[userId];
    if (this.selectedChat === userId) {
      this.selectedChat = null;
    }
    if (this.showCancelForUser) {
      this.showCancelForUser = false;
    }
    this.changeSelectedChat.emit(null);
  }

  minimizeChat(chatId: string) {
    this.selectedChat = null;
    this.showCancelForUser = false;
  }

  sendMessage(chatId: string) {
    if (this.chats[chatId].chatForm.valid) {
      const message = this.chats[chatId].chatForm.get('message')!.value;
      this.chatMessageService.add({
        date: Timestamp.now(),
        status: 'Delivered',
        message: message,
        recipientId: this.chats[this.selectedChat || ''].userId,
        senderId: this.user?.uid || '',
        senderName: this.user?.displayName || '',
        dateCreated: Timestamp.now(),
        dateModified: Timestamp.now(),
      })
      this.chats[chatId].messages.push();
      this.chats[chatId].chatForm.reset();
      this.scrollToBottom();
    }
  }
}
