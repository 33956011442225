import {Component, Inject} from '@angular/core';
import {DialogService} from "../../services/dialog.service";
import {ModalRef} from "../../models/modal-ref";

@Component({
  selector: 'tandem-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss']
})
export class LoadingDialogComponent {

  title: string = '';
  message: string = '';
  isLoading = true;

  constructor(dialogService: DialogService,
              @Inject(ModalRef) public modalRef: ModalRef) {
    this.title = modalRef.data?.title;
    this.message = modalRef.data?.message;
  }
}
