import {AbstractDatabaseObject} from "./abstract-database-object";
import {Timestamp} from "firebase/firestore";

export interface TandemNotification extends AbstractDatabaseObject {
  userId: string;
  sourceUserId?: string; // Optionally an indicator of whom the notification was created by
  sourceUserName?: string; // Same thing
  sourcePhotoURL?: string;
  date: Timestamp;
  type: NotificationType; // Enum to categorize different types of notifications
  content: string; // Brief description or preview of the notification content
  isRead: boolean; // Flag to mark if the notification has been read
  link?: string; // Optional link to direct the user when they click the notification
  metadata?: Record<string, any>; // Optional field for additional data specific to certain notification types
  priority?: 'low' | 'medium' | 'high'; // Optional priority level
  expirationDate?: Timestamp; // Optional expiration date for the notification
  groupId?: string; // Optional field for grouping related notifications
}

export enum NotificationType {
  DirectMessage = 'directMessage',
  MentionInComment = 'mentionInComment',
  NewFollower = 'newFollower',
  SystemAlert = 'systemAlert',
  // Add other types as needed
}
