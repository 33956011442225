import {Component, Input} from '@angular/core';
import {IconType} from "../../../modules/tandem-core/components/icons/icon-type";

interface NavItem {
  icon: IconType;
  label: string;
}

@Component({
  selector: 'tandem-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.css']
})
export class NavItemComponent {
  @Input() navItem: NavItem = {icon: 'plus', label: 'plus'};
  @Input() isExpanded: boolean = false;
  @Input() disabled: boolean = false;
}
