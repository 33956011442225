import {Component, Inject, OnInit} from '@angular/core';
import {ModalRef} from "../../../tandem-core/models/modal-ref";

@Component({
  selector: 'tandem-attempt-auth-dialog',
  templateUrl: './attempt-auth-dialog.component.html',
  styleUrls: ['./attempt-auth-dialog.component.scss']
})
export class AttemptAuthDialogComponent implements OnInit {

  isLoading = true;
  errorMessage?: string;
  public modalRef?: ModalRef;

  constructor() {
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.errorMessage = `We were unable to either create your account or log you in. If you are a returning user signing in, please contact support to get access to your account.`
      this.isLoading = false;
    }, 30000)
  }

  onClose() {
    this.modalRef?.close(true);
  }
}
