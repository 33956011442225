<div class="bg-white shadow sm:rounded-lg">
  <div class="px-4 py-5 sm:p-6">
    <ng-container [ngSwitch]="currentPageStatus">
      <ng-container *ngSwitchCase="'individualUser'">
        <ng-container [ngSwitch]="user?.status">
          <ng-container *ngIf="userSubscription && userSubscription.status === 'SUCCESS'">
            <h3 class="text-base font-semibold leading-6 text-gray-900">{{user?.status === 'Orphaned' ? 'Active Subscription - Removed From Coach Platform' : 'Active Subscription'}}</h3>
            <div class="mt-2 max-w-3xl text-sm text-gray-500">
              <p *ngIf="user?.status !== 'Orphaned'">Active subscription since {{user?.dateCreated?.toDate()  | tandemDate }}</p>
              <p *ngIf="user?.status === 'Orphaned'" class="text-red-500">Oh no! It looks like your coach has either removed your account from their platform or has left Tandem altogether. If you believe this is a mistake, please contact your coach or Tandem support immediately.</p>
              <p>Your price: {{ userSubscription.amount / 100 | currency }} per month</p>
              <p>Next billing date: {{userSubscription.nextBillingDate.toDate()  | tandemDate}}</p>
            </div>
            <div class="mt-5 flex flex-row items-start gap-4">
              <tandem-button *ngIf="user?.status === 'Orphaned'" color="primary" [onClick]="transitionToIndividual.bind(this)">Transition to individual account</tandem-button>
              <tandem-button color="red" class="w-full sm:w-fit" [onClick]="modifyUserStatus.bind(this, 'cancel')">Cancel Subscription</tandem-button>
              <tandem-button color="red" class="w-full sm:w-fit" [onClick]="modifyUserStatus.bind(this, 'delete')">Delete Account</tandem-button>
            </div>
          </ng-container>
          <ng-container *ngIf="userSubscription && userSubscription.status !== 'SUCCESS'">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Inactive Subscription</h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <p>Inactive subscription since {{userSubscription.endDate.toDate()  | tandemDate }}</p>
            </div>
            <div class="mt-5 flex flex-row items-start gap-4">
              <tandem-button color="primary" class="w-full sm:w-fit" [onClick]="openPayment.bind(this)">Resume Subscription</tandem-button>
              <tandem-button color="red" class="w-full sm:w-fit" [onClick]="modifyUserStatus.bind(this, 'delete')">Delete Account</tandem-button>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'Pending'">
            <h3 class="text-base font-semibold leading-6 text-gray-900">User Subscription - Access Pending</h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <p>You must enter your payment information to start using the Tandem platform.</p>
            </div>
            <div class="mt-5 flex flex-col items-start">
              <tandem-button color="primary" class="w-full sm:w-fit" [onClick]="openPayment.bind(this)">Subscribe</tandem-button>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="user?.status === 'Denied'">
          <h3 class="text-base font-semibold leading-6 text-gray-900">User Subscription - Access Denied</h3>
          <div class="mt-2 max-w-xl text-sm text-gray-500">
            <p>Whoops, looks like your coach has denied your account. If you believe this is a mistake, reach out to your coach or Tandem Support.</p>
          </div>
        </ng-container>
        <ng-container *ngIf="user?.status === 'Orphaned' && (!userSubscription || userSubscription.status !== 'SUCCESS')">
          <h3 class="text-base font-semibold leading-6 text-gray-900">Inactive Subscription</h3>
          <div class="mt-2 max-w-xl text-sm text-gray-500">
            <p>It looks like you've been removed from your coach's platform. If you'd like to transition to an individual account that isn't under your coach, please contact support.</p>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'userOfRevShareCoach'">
        <ng-container [ngSwitch]="user?.status">
          <ng-container *ngIf="userSubscription && userSubscription.status === 'SUCCESS'">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Active Subscription</h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <p>Active subscription since {{user?.dateCreated?.toDate()  | tandemDate }}</p>
              <p>Your price: {{ userSubscription.amount / 100 | currency }} per month</p>
              <p>Next billing date: {{userSubscription.nextBillingDate.toDate()  | tandemDate}}</p>
            </div>
            <div class="mt-5 flex flex-row items-start gap-4">
              <tandem-button color="red" class="w-full sm:w-fit" [onClick]="modifyUserStatus.bind(this, 'cancel')">Cancel Subscription</tandem-button>
              <tandem-button color="red" class="w-full sm:w-fit" [onClick]="modifyUserStatus.bind(this, 'delete')">Delete Account</tandem-button>
            </div>
          </ng-container>
          <ng-container *ngIf="userSubscription && userSubscription.status !== 'SUCCESS'">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Inactive Subscription</h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <p>Inactive subscription since {{userSubscription.endDate.toDate()  | tandemDate }}</p>
            </div>
            <div class="mt-5 flex flex-row items-start gap-4">
              <tandem-button color="primary" class="w-full sm:w-fit" [onClick]="openPayment.bind(this)">Resume Subscription</tandem-button>
              <tandem-button color="red" class="w-full sm:w-fit" [onClick]="modifyUserStatus.bind(this, 'delete')">Delete Account</tandem-button>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'Pending'">
            <h3 class="text-base font-semibold leading-6 text-gray-900">User Subscription - Access Pending</h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <p>You must enter your payment information to start using the Tandem platform.</p>
            </div>
            <div class="mt-5 flex flex-col items-start">
              <tandem-button color="primary" class="w-full sm:w-fit" [onClick]="openPayment.bind(this)">Subscribe</tandem-button>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'userOfLeadGenCoach'">
        <ng-container [ngSwitch]="user?.status">
          <ng-container *ngSwitchCase="'Active'">
            <h3 class="text-base font-semibold leading-6 text-gray-900">User Subscription - Active</h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <p>Active subscription since {{user?.dateCreated?.toDate()  | tandemDate }}</p>
              <p>Your price - Free ({{coach?.firstName}} manages your access)</p>
            </div>
            <div class="mt-5 flex flex-row items-start gap-4">
              <tandem-button color="red" class="w-full sm:w-fit" [onClick]="pauseUserAccount.bind(this)">Pause Membership</tandem-button>
              <tandem-button color="red" class="w-full sm:w-fit" [onClick]="deleteUserAccount.bind(this)">Delete Account</tandem-button>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'Pending'">
            <h3 class="text-base font-semibold leading-6 text-gray-900">User Subscription - Access Pending</h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <p>Your coach must approve your account before you can access the platform.</p>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'Paused'">
            <h3 class="text-base font-semibold leading-6 text-gray-900">User Subscription - Access Paused</h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <p>Your coach must resume your access before you can use the platform.</p>
            </div>
            <div class="mt-5 flex flex-row items-start gap-4">
              <tandem-button color="red" class="w-full sm:w-fit" [onClick]="deleteUserAccount.bind(this)">Delete Account</tandem-button>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'Denied'">
            <h3 class="text-base font-semibold leading-6 text-gray-900">User Subscription - Access Denied</h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <p>Whoops, looks like your coach has denied your account. If you believe this is a mistake, reach out to your coach or Tandem Support.</p>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'revShareCoach'">
        <ng-container [ngSwitch]="user?.status">
          <ng-container *ngSwitchCase="'Active'">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Coach Rev Share - Active Subscription</h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <p>Active subscription since {{user?.dateCreated?.toDate()  | tandemDate }}</p>
              <p>Current user count: 43</p>
              <p>Estimated monthly revenue: $10.99 x 43 = {{472.57 | currency}}</p>
            </div>
            <div class="mt-5 flex flex-col items-start">
              <tandem-button color="red" class="w-full sm:w-fit" [onClick]="cancelCoachAccount.bind(this)">Delete Account</tandem-button>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'Pending'">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Coach Rev Share - Access Pending</h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <p>A Tandem administrator must approve your account before you can access the platform.</p>
            </div>
            <div class="mt-5 flex flex-col items-start">
              <tandem-button color="red" class="w-full sm:w-fit" [onClick]="deleteRevShareCoachAccount.bind(this)">Delete Account</tandem-button>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'AwaitingAdminApproval'">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Awaiting Admin Approval</h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <p>You must complete the agreement and get approval from an admin before accessing the platform.</p>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'leadGenCoach'">
        <ng-container [ngSwitch]="user?.status">
          <ng-container *ngSwitchCase="'Active'">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Coach Lead Gen - Active Subscription</h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <p>Active subscription since {{user?.dateCreated?.toDate()  | tandemDate }}</p>
              <p>Current user count: {{ getCurrentUserCount() }}</p>
              <p>Estimated monthly revenue: {{getCurrentMonthlyPrice() | currency}} x {{ getCurrentUserCount() }} = {{getCurrentMonthyTotal() | currency}}</p>
            </div>
            <div class="mt-5 flex flex-col items-start">
              <tandem-button color="red" class="w-full sm:w-fit" [onClick]="cancelCoachAccount.bind(this)">Delete Account</tandem-button>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'Pending'">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Coach Lead Gen - Pending Access</h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <p>Your account is pending because you haven't entered your payment information yet. We need this info in order to bill your account based on the usage of your users.</p>
            </div>
            <div class="mt-5 flex flex-col items-start">
              <tandem-button color="primary" class="w-full sm:w-fit" [onClick]="openPayment.bind(this)">Enter payment information</tandem-button>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'AwaitingAdminApproval'">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Awaiting Admin Approval</h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <p>You must complete the agreement and get approval from an admin before accessing the platform.</p>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'Paused'">

          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'loading'">
        <tandem-loading-container [isLoaded]="false"></tandem-loading-container>
      </ng-container>
    </ng-container>
  </div>
</div>



<!--<div class="bg-white shadow sm:rounded-lg" *ngIf="user?.accountType === 'coach' && user?.coachType === 'coachRevShare'">-->
<!--  <div class="px-4 py-5 sm:p-6">-->
<!--    <h3 class="text-base font-semibold leading-6 text-gray-900">Active Subscription - Coach Rev Share</h3>-->
<!--    <div class="mt-2 max-w-xl text-sm text-gray-500">-->
<!--      <p>Active subscription since {{user?.dateCreated?.toDate()  | tandemDate }}</p>-->
<!--      <p>Current user count: 43</p>-->
<!--      <p>Estimated monthly revenue: $10.99 x 43 = {{472.57 | currency}}</p>-->
<!--    </div>-->
<!--    <div class="mt-5 flex flex-col items-start">-->
<!--      <tandem-button color="red" class="w-full sm:w-fit">Cancel Subscription</tandem-button>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="bg-white shadow sm:rounded-lg" *ngIf="user?.accountType === 'coach' && user?.coachType === 'coachLeadGen'">-->
<!--  <div class="px-4 py-5 sm:p-6">-->
<!--    <h3 class="text-base font-semibold leading-6 text-gray-900">Active Subscription - Coach Lead Gen</h3>-->
<!--    <div class="mt-2 max-w-xl text-sm text-gray-500">-->
<!--      <p>Active subscription since {{user?.dateCreated?.toDate()  | tandemDate }}</p>-->
<!--      <p>Your price: $10.99 per user per month</p>-->
<!--      <p>Current maximum user count: 43</p>-->
<!--      <p>Estimated total due on Mar 7th, 2024: $10.99 x 43 users = {{472.57 | currency}}</p>-->
<!--    </div>-->
<!--    <div class="mt-5 flex flex-col items-start">-->
<!--      <tandem-button color="red" class="w-full sm:w-fit">Cancel Subscription</tandem-button>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="bg-white shadow sm:rounded-lg" *ngIf="user?.accountType === 'user'">-->
<!--  <div class="px-4 py-5 sm:p-6">-->
<!--    <ng-container *ngIf="userSubscription && userSubscription.status === 'SUCCESS'">-->
<!--      <h3 class="text-base font-semibold leading-6 text-gray-900">Active Subscription</h3>-->
<!--      <div class="mt-2 max-w-xl text-sm text-gray-500">-->
<!--        <p>Active subscription since {{user?.dateCreated?.toDate()  | tandemDate }}</p>-->
<!--        <p>Your price: {{ userSubscription.amount / 100 | currency }} per month</p>-->
<!--        <p>Next billing date: {{userSubscription.nextBillingDate.toDate()  | tandemDate}}</p>-->
<!--      </div>-->
<!--      <div class="mt-5 flex flex-col items-start">-->
<!--        <tandem-button color="red" class="w-full sm:w-fit">Cancel Subscription</tandem-button>-->
<!--      </div>-->
<!--    </ng-container>-->
<!--    <ng-container *ngIf="coach?.coachType === 'coachLeadGen' && user?.status === 'Active'">-->
<!--      <h3 class="text-base font-semibold leading-6 text-gray-900">Active Subscription</h3>-->
<!--      <div class="mt-2 max-w-xl text-sm text-gray-500">-->
<!--        <p>Active subscription</p>-->
<!--        <p>Your subscription status is managed by your coach.</p>-->
<!--      </div>-->
<!--      <div class="mt-5 flex flex-col items-start">-->
<!--        <tandem-button color="red" class="w-full sm:w-fit">Cancel Subscription</tandem-button>-->
<!--      </div>-->
<!--    </ng-container>-->
<!--    <ng-container *ngIf="coach?.coachType === 'coachLeadGen' && user?.status === 'Pending'">-->
<!--      <h3 class="text-base font-semibold leading-6 text-gray-900">Pending Subscription</h3>-->
<!--      <div class="mt-2 max-w-xl text-sm text-gray-500">-->
<!--        <p>Pending subscription</p>-->
<!--        <p>Your coach must grant you access to the Tandem platform</p>-->
<!--      </div>-->
<!--    </ng-container>-->
<!--    <ng-container *ngIf="coach?.coachType === 'coachLeadGen' && (user?.status === 'Paused' || user?.status === 'Denied')">-->
<!--      <h3 class="text-base font-semibold leading-6 text-gray-900">Inactive Subscription</h3>-->
<!--      <div class="mt-2 max-w-xl text-sm text-gray-500">-->
<!--        <p>Inactive subscription</p>-->
<!--        <p>Your coach has either paused or denied your access. If you believe this is a mistake, please reach out to your coach or Tandem Support.</p>-->
<!--      </div>-->
<!--    </ng-container>-->

<!--  </div>-->
<!--</div>-->
