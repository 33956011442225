import {
  ChangeDetectorRef,
  Component, ElementRef, HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  HeaderConfig,
  HeaderService,
} from '../../../../modules/tandem-core/services/header.service';
import { AuthService } from '../../../../modules/auth/services/auth.service';
import { TandemUser } from '../../../../modules/auth/models/tandem-user';
import {Router} from "@angular/router";
import {ThemingService} from "../../../../modules/tandem-core/services/theming.service";
import {CoreService} from "../../../../modules/tandem-core/services/core.service";
import {PaymentService} from "../../../../modules/stripe/services/payment-service";
import {DialogService} from "../../../../modules/tandem-core/services/dialog.service";
import {VideoPlayerComponent} from "../../../../modules/tandem-core/components/video-player/video-player.component";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {combineLatest, of, switchMap} from "rxjs";
import {UserService} from "../../../../modules/auth/user.service";
import {VideoType} from "../../../../modules/tandem-core/services/video-player.service";
import {StripeOperationsService} from "../../../../modules/stripe/services/stripe-operations.service";
import {StripeService} from "ngx-stripe";

@Component({
  selector: 'tandem-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() headerConfig?: HeaderConfig;
  @Input() showUserCircle: boolean = true;
  user: TandemUser | null = null;
  coach: TandemUser | null = null;

  themes = [
    'purple',
    'blue',
    'fuscia',
    'teal',
    'navy',
    'spring-green',
    'sea-green',
    'forest',
    'orange',
  ];
  userIsPaid = false;
  // showHeader = false;
  // bannerMessage = 'Your account does not have an active subscription. Only the calculator tools are available to use for free. Click to activate your subscription.';
  displayUserDropdown = false;

  bannerText?: string
  disablePaymentRoute = false;

  constructor(
    private headerService: HeaderService,
    private auth: AuthService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private themingService: ThemingService,
    private paymentService: PaymentService,
    private stripeOpsService: StripeOperationsService,
    private dialogService: DialogService,
    private _elementRef: ElementRef,
    private userService: UserService,
    private stripeService: StripeService
  ) {}

  ngOnInit(): void {
    this.auth.userIsPaid.subscribe(isPaid => this.userIsPaid = isPaid);
    this.auth.$user.pipe(
      switchMap(user => {
        if (user) {
          if (user.coachId) {
            const coachDataObservable = this.userService.get(user.coachId);
            return combineLatest([of(user), coachDataObservable]);
          } else {
            return of([user, null]);
          }
        } else {
          return of([null, null]);
        }
      })
    ).subscribe(([user, coachData]) => {
      this.user = user;
      this.coach = coachData;
      this.bannerText = this.getBannerMessage();
    });
    this.headerService.getConfig().subscribe(config => {
      this.headerConfig = config
      this.cdr.detectChanges();
    });
    this.themingService.screenSize.subscribe(size => {
      // this.showHeader = ['lg', 'xl', 'xxl'].includes(size);
    });
    // this.coreService.addSubscription('headerPaidSub', this.auth.userIsPaid.subscribe(isPaid => this.userIsPaid = isPaid));
    // this.coreService.addSubscription('headerAuthSub', this.auth.$user.subscribe((user) => (this.user = user)));
    // this.coreService.addSubscription('headerConfigSub', this.headerService.getConfig().subscribe(config => {
    //   this.headerConfig = config
    //   this.cdr.detectChanges();
    // }));
    // this.coreService.addSubscription('headerThemingSub', this.themingService.screenSize.subscribe(size => {
    //   console.log(size)
    //   // this.showHeader = ['lg', 'xl', 'xxl'].includes(size);
    // }))

  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  onVisionChange() {
    this.headerService.visionChanged.emit();
  }

  openStripe() {
    if (this.user?.status === 'Orphaned') {
      this.router.navigate(['profile'])
    } else {
      if (!this.disablePaymentRoute) {
        if (this.user?.accountType === 'user' && this.coach?.coachType === 'coachRevShare') {
          // handle as user of Rev Share Coach
          this.stripeOpsService.openStripeForUserOfRSCoach().subscribe((response) => {
            this.stripeService.redirectToCheckout({ sessionId: response.sessionId }).subscribe(stripe => {})
          });
        } else if (this.user?.accountType === 'coach' && this.user.coachType === 'coachRevShare') {
          // handle as rev share coach
          this.stripeOpsService.openStripeForRSCoach(this.user).subscribe(() => {})
        } else if (this.user?.accountType === 'coach' && this.user.coachType === 'coachLeadGen') {
          // handle as lead gen coach
          this.stripeOpsService.openStripeForLGCoach(this.user).subscribe((response: any) => {
            this.stripeService.redirectToCheckout({ sessionId: response.sessionId }).subscribe(stripe => {})
          });
        }
        // this.router.navigate(['auth/profile']).then(routed => {
        //   this.stripeOpsService.openStripeConnectForRevShareCoach().subscribe(res => {
        //     console.log(res)
        //   })
        // })
      }
    }
  }

  showVideo() {
    let vidType: VideoType = 'userWelcomeIndividual';
    if (this.coach) {
      vidType = this.coach.coachType === 'coachRevShare' ? 'userWelcomeRev' : 'userWelcomeLead';
    } else {
      vidType = this.user?.accountType === 'coach' && this.user.coachType === 'coachRevShare' ? 'coachWelcomeRev' : 'coachWelcomeLead';
    }

    let width: string | undefined;
    if (vidType.includes('Welcome')) {
      width = 'sm:w-[75vw]';
    }
    this.dialogService.openModal(VideoPlayerComponent, {videoType: vidType}, undefined, width);
  }

  onLogout() {
    this.auth.onLogout();
  }

  toggleUserDropdown() {
    this.displayUserDropdown = !this.displayUserDropdown;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: MouseEvent): void {
    if (this._elementRef.nativeElement.querySelector('tandem-icon[icon="userCircle"]')) {
      const clickedInsideDropdown = this._elementRef.nativeElement.querySelector('.absolute.top-full').contains(event.target);
      const clickedOnIcon = this._elementRef.nativeElement.querySelector('tandem-icon[icon="userCircle"]').contains(event.target);

      if (!clickedInsideDropdown && !clickedOnIcon) {
        this.displayUserDropdown = false;
      }
    }
  }

  getBannerMessage(): string {
    if (!this.user) {
      this.disablePaymentRoute = false;
      return '';
    }

    if (this.user.status === 'Denied') {
      return `Oh no, looks like your account has been denied! If you believe this is a mistake, please contact ${this.user.accountType === 'coach' ? 'Tandem Support.' : 'your coach or Tandem Support.'}`
    } else if (this.user.status === 'Orphaned') {
      return `Your coach has removed you from their platform. Click here to transition to an individual account.`;
    } else if (this.user.status === 'AwaitingAdminApproval') {
      this.disablePaymentRoute = true;
      return `Account pending admin approval. You currently have full access to the platform other than inviting users.`;
    } else {
      if (this.user.accountType === 'user') {
        if (this.coach?.coachType === 'coachRevShare') {
          this.disablePaymentRoute = false;
          return `You're using the free version, upgrade to premium to unlock all the tools you need to have clarity in your finances.`
        } else if (this.coach?.coachType === 'coachLeadGen') {
          this.disablePaymentRoute = true;
          return `You've signed up, but your coach must approve your account before you can access the platform.`
        }
      } else if (this.user.accountType === 'coach') {
        if (this.user.coachType === 'coachRevShare') {
          this.disablePaymentRoute = false;
          return `Account approved, click here to set up your Stripe Connect so you can get passive income`
        }
        if (this.user.coachType === 'coachLeadGen') {
          this.disablePaymentRoute = false;
          return `Account approved, click here to set up your billing information via Stripe`
        }
      }
      this.disablePaymentRoute = false;
      return '';
    }
  }
}
