import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from "@angular/common";

@Pipe({
  name: 'tandemDate'
})
export class TandemDatePipe extends DatePipe implements PipeTransform {

  override transform(value: Date | any, format: string = 'MMM dd, yyyy'): any {
    const date = super.transform(value, 'MMM d, yyyy');
    if (!date) return null;

    const day = new Date(value).getDate();
    const suffix = this.getOrdinalSuffix(day);
    return date.replace(/(\d{1,2}),/, `$1${suffix},`);
  }

  private getOrdinalSuffix(day: number): string {
    if (day > 3 && day < 21) return 'th'; // Handles 11th, 12th, 13th
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

}
