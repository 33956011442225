import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'tandem-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss']
})
export class ChangelogComponent implements OnInit {
  changelogContent: string = '';

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.loadChangelog();
  }

  loadChangelog(): void {
    this.http.get('assets/CHANGELOG.md', { responseType: 'text' })
      .subscribe((data: string) => {
        this.changelogContent = data;
      });
  }
}
