<div class="w-full" style="min-height: 100vh">
  <div class="flex items-start justify-between bg-primary p-8 sticky top-0 w-full z-50">
    <div class="flex flex-col gap-y-4 text-white">
      <ng-container [ngSwitch]="chatType">
        <ng-container *ngSwitchCase="'financial-position'">
          <h2 class="font-semibold text-lg text-white">Comment on {{getTTName('fp')}}</h2>
          <h3 class="font-semibold text-md text-white">{{ !financialPosition ? 'N/A' : financialPosition.positionDate.toDate() | tandemDate}}</h3>
        </ng-container>
        <ng-container *ngSwitchCase="'cash-flow'">
          <h2 class="font-semibold text-lg text-white">Comment on {{getTTName('cf')}}</h2>
          <h3 class="font-semibold text-md text-white">{{cashFlow?.title}}</h3>
        </ng-container>
        <ng-container *ngSwitchCase="'spending-plan'">
          <h2 class="font-semibold text-lg text-white">Comment on {{getTTName('sp')}}</h2>
          <h3 class="font-semibold text-md text-white">Item Info</h3>
        </ng-container>
        <ng-container *ngSwitchCase="'asset'">
          <h2 class="font-semibold text-lg text-white">Comment on {{asset?.name || 'Asset'}}</h2>
          <div class="pl-4">
            <p class="text-base"><strong class="font-semibold">Value:</strong> {{asset?.value | currency}}</p>
            <p class="text-base"><strong class="font-semibold">Annual Percentage Return:</strong> {{asset?.annualPercentageReturn | percent:'1.2-2'}}</p>
            <p class="text-base"><strong class="font-semibold">Liquidity:</strong> {{asset?.liquidityQuantity === 0 ? 'Instant' : asset?.liquidityQuantity + ' ' + asset?.liquidityUnits}}</p>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'liability'">
          <h2 class="font-semibold text-lg text-white">Comment on {{liability?.name || 'Liability'}}</h2>
          <div class="pl-4">
            <p class="text-base"><strong class="font-semibold">Value:</strong> {{liability?.value | currency}}</p>
            <p class="text-base"><strong class="font-semibold">Interest Rate:</strong> {{liability?.interestRate | percent:'1.2-2'}}</p>
            <p class="text-base"><strong class="font-semibold">Term:</strong> {{liability?.termQuantity === 0 ? 'No Term' : liability?.termQuantity + ' ' + liability?.termUnits}}</p>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'revenue'">
          <h2 class="font-semibold text-lg text-white">Comment on {{statementItem?.name || 'Item'}}</h2>
          <div class="pl-4">
            <p class="text-base"><strong class="font-semibold">Value:</strong> {{statementItem?.value | currency}}</p>
            <p class="text-base"><strong class="font-semibold">Date:</strong> {{!statementItem || !statementItem.date ? 'N/A' : statementItem!.date!.toDate() | tandemDate}}</p>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'expense'">
          <h2 class="font-semibold text-lg text-white">Comment on {{statementItem?.name || 'Item'}}</h2>
          <div class="pl-4">
            <p class="text-base"><strong class="font-semibold">Value:</strong> {{statementItem?.value | currency}}</p>
            <p class="text-base"><strong class="font-semibold">Date:</strong> {{!statementItem || !statementItem.date ? 'N/A' : statementItem!.date!.toDate() | tandemDate}}</p>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="ml-3 flex h-7 items-center">
      <button type="button" class="relative rounded-md bg-primary text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2" (click)="closeSidePanel()">
        <span class="absolute -inset-2.5"></span>
        <span class="sr-only">Close panel</span>
        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFF" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  </div>
  <div class="relative mt-6 flex-1 px-4 sm:px-6">
    <div class="flex-1 overflow-y-auto px-4 py-6">
      <div class="space-y-6">
        <ng-container *ngFor="let chat of chats">
          <div [ngClass]="{'flex items-end': true, 'justify-end': chat.userId === currentUserId}">
            <div [ngClass]="{'flex flex-col space-y-2 text-sm max-w-xs mx-2': true, 'order-1 items-end': chat.userId === currentUserId, 'order-2 items-start': chat.userId !== currentUserId}">
              <div>
            <span class="break-words" [ngClass]="{
              'px-4 py-2 rounded-lg inline-block shadow-sm': true,
              'rounded-br-none bg-blue-500 text-white': chat.userId === currentUserId,
              'rounded-bl-none bg-gray-100 text-gray-800 border border-gray-200': chat.userId !== currentUserId
            }">{{ chat.message }}</span>
              </div>
              <div class="text-xs text-gray-500">
                {{ chat.date.toDate() | date:'short' }}
                <span *ngIf="chat.userId === currentUserId"> · {{ chat.status }}</span>
              </div>
            </div>
            <div class="h-8 w-8 flex-shrink-0" [ngClass]="{'order-2': chat.userId === currentUserId, 'order-1': chat.userId !== currentUserId}">
              <img *ngIf="chat.userId === this.otherUser?.uid && otherUser?.photoURL" class="h-8 w-8 rounded-full" [src]="otherUser?.photoURL" alt="{{otherUser?.displayName}}">
              <img *ngIf="chat.userId === this.user?.uid && user?.photoURL" class="h-8 w-8 rounded-full" [src]="user?.photoURL" alt="{{user?.displayName}}">
              <span *ngIf="chat.userId === this.otherUser?.uid && !otherUser?.photoURL" class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-primary">
                <span class="text-xs font-medium leading-none text-white">{{ otherUser?.firstName?.at(0)?.toUpperCase() }}{{otherUser?.lastName?.at(0)?.toUpperCase()}}</span>
              </span>
              <span *ngIf="chat.userId === this.user?.uid && !user?.photoURL" class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-primary">
                <span class="text-xs font-medium leading-none text-white">{{ user?.firstName?.at(0)?.toUpperCase() }}{{user?.lastName?.at(0)?.toUpperCase()}}</span>
              </span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="chats.length === 0">
          <h4 class="text-lg text-gray-400">No messages yet.</h4>
        </ng-container>
      </div>
    </div>
    <div class="px-4 py-6 bg-white">
      <div [formGroup]="chatForm"
           class="flex flex-row items-start"
           (ngSubmit)="sendMessage()"
           [appEnterKeySubmit]="chatForm"
           [onSubmit]="sendMessage.bind(this)">
        <tandem-form-input class="w-full" [form]="chatForm" controlName="message" placeholder="Type a message..."></tandem-form-input>
<!--        <input type="text" placeholder="Type a message..."-->
<!--               class="w-full px-4 py-2 border-none focus:outline-none focus:ring-0"-->
<!--               formControlName="message">-->
        <tandem-button class="px-4 py-2  text-white"
                type="submit"
                [onClick]="sendMessage.bind(this)"
                [disabled]="chatForm.invalid">
          Send
        </tandem-button>
      </div>
    </div>
  </div>
</div>

