import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {FormOption} from "../form-option";
import {Subscription} from "rxjs";
import {DropdownService} from "../../../services/dropdown.service";

@Component({
  selector: 'tandem-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss']
})
export class FormSelectComponent implements OnInit, OnChanges, OnDestroy {

  @Input() label: string = '';
  @Input() form: FormGroup | AbstractControl = new FormGroup({});
  @Input() inputId: string = '';
  @Input() controlName: string = '';
  @Input() options: FormOption[] = [];
  @Input() onFormReset?: EventEmitter<void>;
  @Input() tooltipText?: string;

  @ViewChild('selectContainer') selectContainer!: ElementRef;

  control: FormControl = new FormControl();
  selectedOption?: FormOption;
  displayDropdown = false;
  private dropdownId: string;
  private activeDropdownSub: Subscription;

  constructor(
    private fb: FormBuilder,
    private dropdownService: DropdownService
  ) {
    this.dropdownId = Math.random().toString(36).substring(7);
    this.activeDropdownSub = this.dropdownService.getActiveDropdown().subscribe(id => {
      this.displayDropdown = id === this.dropdownId;
    });
  }

  ngOnDestroy() {
    this.activeDropdownSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      const opt = this.options.find(o => o.value === this.selectedOption?.value);
      if (opt) {
        this.selectedOption = opt;
      } else {
        this.selectedOption = undefined;
        this.control.setValue(null);
      }
    }
  }

  ngOnInit() {
    this.control = this.form.get(this.controlName) as FormControl;
    this.selectedOption = this.options.find(opt => opt.value === this.control.value) || undefined;
    this.control.valueChanges.subscribe(val => {
      if (val === null) {
        this.selectedOption = undefined;
      } else {
        if (val !== this.selectedOption?.value) {
          const opt = this.options.find(o => o.value === val);
          if (opt) {
            this.selectedOption = opt;
          }
        }
      }
    });
    this.onFormReset?.subscribe(onReset => {
      this.control.setValue(null);
      this.selectedOption = undefined;
    })
  }

  // TODO is this gonna be needed?
  // compareFn(option1: any, option2: any) {
  //   if (typeof option1 === 'number' && typeof option2 === 'string') {
  //     option2 = Number(option2);
  //   } else if (typeof option2 === 'number' && typeof option1 === 'string') {
  //     option1 = Number(option1);
  //   }
  //   return option1 === option2;
  // }

  toggleDropdown() {
    if (this.displayDropdown) {
      this.dropdownService.setActiveDropdown(null);
    } else {
      this.dropdownService.setActiveDropdown(this.dropdownId);
    }
  }

  selectOption(option: FormOption) {
    this.selectedOption = option;
    this.displayDropdown = false;
    this.control.setValue(option.value);
  }

  touchControl() {
    this.control.markAsTouched();
  }
}
