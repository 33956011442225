import {AfterViewInit, Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {CurrencyPipe, DatePipe, isPlatformBrowser, NgIf, NgStyle} from '@angular/common';
import {NgxEchartsDirective, NgxEchartsModule} from 'ngx-echarts';
import {Timestamp} from "firebase/firestore";
import {ChartService} from "../../../../../tandem-core/services/chart.service";


@Component({
  selector: 'app-financial-position-graph',
  standalone: true,
  imports: [
    NgStyle,
    NgxEchartsModule,
    CurrencyPipe,
    NgIf
  ],
  templateUrl: './financial-position-graph.component.html',
  styleUrls: ['./financial-position-graph.component.scss'],
  providers: [
    CurrencyPipe,
    DatePipe,
  ]
})
export class FinancialPositionGraphComponent implements AfterViewInit {

  @Input() useCase: string = 'general';

  showLegend: boolean = true;

  chartOption: any

  constructor(private chartService: ChartService, private currencyService: CurrencyPipe, private dateService: DatePipe, @Inject(PLATFORM_ID) private platformId: Object) {
    this.showLegend = this.useCase === 'blog';
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      let categories: any[] = [];
      defaultFp?.assets.forEach(cat => {
        let total = cat.assets.reduce((accumulator, asset) => accumulator + asset.value, 0)
        if (total > 0) {
          categories.push({
            name: cat.name,
            value: total
          })
        }
      })
      defaultFp?.liabilities.forEach(cat => {
        let total = cat.liabilities.reduce((accumulator, liability) => accumulator + liability.value, 0)
        if (total > 0) {
          categories.push({
            name: cat.name,
            value: total
          })
        }
      })

      const innerSeries = {
        name: 'Items',
        data: [
          {
            name: 'Assets',
            value: defaultFp.totalAssets || 0
          },
          {
            name: 'Liabilities',
            value: defaultFp.totalLiabilities || 0
          },
        ]
      };
      const outerSeries = {
        name: 'Categories',
        data: categories,
        greenItemCount: defaultFp.assets.filter(cat => cat.assets.reduce((accumulator, asset) => accumulator + asset.value, 0) > 0).length || 0,
        redItemCount: defaultFp.liabilities.filter(cat => cat.liabilities.reduce((accumulator, liability) => accumulator + liability.value, 0) > 0).length || 0,
        yellowItemCount: 0,
        orangeItemCount: 0
      };

      this.chartOption = this.chartService.generatePieChart(innerSeries,
        outerSeries,
        this.showLegend,
        false
      );

      // if (this.financialPosition) {
      //   this.chart = this.chartService
      //     .generateDoughnutChart('fpChartCanvas',
      //       {label: 'Net Worth: ',value: this.financialPosition!.netWorth},
      //       this.fpService.convertFinancialPositionToChartData(this.financialPosition!), 2, this.omitLegend);
      // }
    });
  }

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  getTitle() {
    return `Net Worth: ${this.formatNumber(defaultFp.netWorth || 0)}`;
  }

  formatNumber(currencyNumber: number): string {
    let formatted = this.currencyService.transform(currencyNumber);
    if (formatted) {
      return formatted
    }
    return currencyNumber.toString();
  }

  toFirestoreTimestamp(seconds: number, nanoseconds: number) {
    return new Timestamp(seconds, nanoseconds);
  }

  formatTimestamp(timestamp: Timestamp): string {
    let formatted = this.dateService.transform(timestamp.toDate());
    if (formatted) {
      return formatted
    }
    return timestamp.toString();
  }
}

export const defaultFp = {
  "shortTermLiabilities": 35918.43,
  "totalAssets": 851402.88,
  "liabilityDetails": {},
  "totalMinimumPayment": 4955.4,
  "liabilityDescription": "A monetary amount you owe",
  "totalLiabilities": 673249.2200000001,
  "dateModified": {
    "_seconds": 1711220177,
    "_nanoseconds": 778000000
  },
  "averageInterestRate": 0.05602745564265191,
  "averageReturn": 0.06029761332261409,
  "assetDescription": "Something you own that has value",
  "title": "Thu Feb 01 2024",
  "userId": "Vn3FWWY203Vn1JyB1NpivmxfLAJ3",
  "liquidAssets": 26645.83,
  "assetDetails": {},
  "visibleToCoach": true,
  "dateCreated": {
    "_seconds": 1711220177,
    "_nanoseconds": 778000000
  },
  "assets": [
    {
      "hoverHint": "Liquid funds readily available for use",
      "assets": [
        {
          "associatedExpenses": null,
          "name": "Cash on Hand",
          "liquidityQuantity": 0,
          "annualPercentageReturn": 0,
          "value": 209,
          "liquidityUnits": "Instant"
        },
        {
          "associatedExpenses": null,
          "name": "Bank of America",
          "liquidityQuantity": 0,
          "annualPercentageReturn": 0.0025,
          "value": 8216.08,
          "liquidityUnits": "Instant"
        }
      ],
      "name": "Cash"
    },
    {
      "hoverHint": "Money set aside for a rainy day, ideally in a high-yield cash account",
      "assets": [
        {
          "associatedExpenses": null,
          "name": "High-Yield Emergency Fund",
          "liquidityQuantity": 0,
          "annualPercentageReturn": 0.042,
          "value": 12157.11,
          "liquidityUnits": "Instant"
        },
        {
          "associatedExpenses": null,
          "name": "Vacation Fund",
          "liquidityQuantity": 0,
          "annualPercentageReturn": 0.031,
          "value": 703.64,
          "liquidityUnits": "Instant"
        }
      ],
      "name": "Emergency Funds"
    },
    {
      "hoverHint": "Financial products such as stocks, bonds, mutual funds, and ETFs",
      "assets": [
        {
          "associatedExpenses": "0.25% trading fee",
          "name": "Fidelity Stocks",
          "liquidityQuantity": 6,
          "annualPercentageReturn": 0.0771,
          "value": 5920.27,
          "liquidityUnits": "Months"
        },
        {
          "associatedExpenses": "0.35% trading fee",
          "name": "Morgan Stanley ETF",
          "liquidityQuantity": 6,
          "annualPercentageReturn": 0.124,
          "value": 2510.64,
          "liquidityUnits": "Months"
        }
      ],
      "name": "Financial Securities"
    },
    {
      "hoverHint": "Assets in accounts designed for retirement or tax deferment purposes",
      "assets": [
        {
          "associatedExpenses": "0.1% expense ratio",
          "name": "Roth IRA",
          "liquidityQuantity": 38,
          "annualPercentageReturn": 0.0981,
          "value": 18779.39,
          "liquidityUnits": "Years"
        },
        {
          "associatedExpenses": "0.5% expense ratio",
          "name": "401K",
          "liquidityQuantity": 38,
          "annualPercentageReturn": 0.0704,
          "value": 51914.81,
          "liquidityUnits": "Years"
        },
        {
          "associatedExpenses": "Management fees",
          "name": "SDIRA",
          "liquidityQuantity": 38,
          "annualPercentageReturn": 0.1435,
          "value": 13912.52,
          "liquidityUnits": "Years"
        }
      ],
      "name": "Retirement & Tax Deferred"
    },
    {
      "hoverHint": "Real assets such as land and buildings",
      "assets": [
        {
          "associatedExpenses": "~ $3,000 a month in mortgage & upkeep",
          "name": "Primary residence",
          "liquidityQuantity": 1,
          "annualPercentageReturn": 0.05,
          "value": 358000,
          "liquidityUnits": "Years"
        },
        {
          "associatedExpenses": "~ $2,000 a month in mortgage & upkeep",
          "name": "Rental Property",
          "liquidityQuantity": 6,
          "annualPercentageReturn": 0.08,
          "value": 317000,
          "liquidityUnits": "Months"
        }
      ],
      "name": "Real Estate"
    },
    {
      "hoverHint": "Assets your business owns such as business bank accounts, inventory, and intellectual property",
      "assets": [
        {
          "associatedExpenses": null,
          "name": "Promissory Note",
          "liquidityQuantity": 4,
          "annualPercentageReturn": 0.12,
          "value": 7619.42,
          "liquidityUnits": "Months"
        }
      ],
      "name": "Business"
    },
    {
      "hoverHint": "Other assets you own with marketable value such as a vehicle, boat, or collectible items",
      "assets": [
        {
          "associatedExpenses": "~ $800 a month",
          "name": "Car",
          "liquidityQuantity": 3,
          "annualPercentageReturn": -0.05,
          "value": 17100,
          "liquidityUnits": "Months"
        },
        {
          "associatedExpenses": null,
          "name": "Collectible baseball cards",
          "liquidityQuantity": 1,
          "annualPercentageReturn": 0.15,
          "value": 5360,
          "liquidityUnits": "Months"
        },
        {
          "associatedExpenses": "~ $800 a month",
          "name": "Boat",
          "liquidityQuantity": 3,
          "annualPercentageReturn": -0.05,
          "value": 32000,
          "liquidityUnits": "Months"
        }
      ],
      "name": "Personal & Other"
    }
  ],
  "positionDate": {
    "_seconds": 1706763600,
    "_nanoseconds": 0
  },
  "netWorth": 178153.65999999992,
  "liabilities": [
    {
      "hoverHint": "Short-term unsecured debt, typically with high interest rates",
      "liabilities": [
        {
          "interestRate": 0.2345,
          "termUnits": "N/A",
          "termQuantity": 0,
          "minimumPayment": 67.15,
          "name": "Chase Sapphire",
          "value": 1642.28
        },
        {
          "interestRate": 0.24,
          "termUnits": "N/A",
          "termQuantity": 0,
          "minimumPayment": 0,
          "name": "Discover Cash Back",
          "value": 0
        },
        {
          "interestRate": 0.22,
          "termUnits": "N/A",
          "termQuantity": 0,
          "minimumPayment": 0,
          "name": "Capital One Quicksilver",
          "value": 0
        }
      ],
      "name": "Credit Card Debt"
    },
    {
      "hoverHint": "Funds borrowed to pay for higher education",
      "liabilities": [
        {
          "interestRate": 0.042,
          "termUnits": "Months",
          "termQuantity": 116,
          "minimumPayment": 134.96,
          "name": "Subsidized",
          "value": 13552.94
        },
        {
          "interestRate": 0.043,
          "termUnits": "Months",
          "termQuantity": 128,
          "minimumPayment": 158.51,
          "name": "Unsubsidized",
          "value": 18524.15
        },
        {
          "interestRate": 0.085,
          "termUnits": "Months",
          "termQuantity": 140,
          "minimumPayment": 66.46,
          "name": "Parent Plus",
          "value": 6648.17
        }
      ],
      "name": "Student Debt"
    },
    {
      "hoverHint": "Debt secured by a real asset",
      "liabilities": [
        {
          "interestRate": 0.047,
          "termUnits": "Months",
          "termQuantity": 352,
          "minimumPayment": 1711.5,
          "name": "FHA Loan",
          "value": 318906.31
        },
        {
          "interestRate": 0.0675,
          "termUnits": "Months",
          "termQuantity": 337,
          "minimumPayment": 1653.93,
          "name": "DSCR Rental Loan",
          "value": 247911.39
        }
      ],
      "name": "Real Estate Debt / Mortgages"
    },
    {
      "hoverHint": "Funds borrowed to support the growth of a business",
      "liabilities": [],
      "name": "Business Debt"
    },
    {
      "hoverHint": "Other amounts owed such as a personal loan",
      "liabilities": [
        {
          "interestRate": 0.071,
          "termUnits": "Months",
          "termQuantity": 56,
          "minimumPayment": 416.82,
          "name": "Auto Loan",
          "value": 17800.4
        },
        {
          "interestRate": 0.043,
          "termUnits": "N/A",
          "termQuantity": 0,
          "minimumPayment": 324.78,
          "name": "Medical Debt",
          "value": 34276.15
        },
        {
          "interestRate": 0.0675,
          "termUnits": "Months",
          "termQuantity": 47,
          "minimumPayment": 421.29,
          "name": "Boat Debt",
          "value": 13987.43
        }
      ],
      "name": "Personal Loans & Other Liabilities"
    }
  ]
}
