
<!--<tandem-icon-->
<!--    icon="question"-->
<!--    class="relative " [tooltip]="text" [maxWidth]="maxWidth">-->
<!--</tandem-icon>-->

<!--&lt;!&ndash;<div class="relative">&ndash;&gt;-->
<!--&lt;!&ndash;  &lt;!&ndash; Question-mark Icon &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;  <tandem-icon&ndash;&gt;-->
<!--&lt;!&ndash;    (mouseenter)="tooltipExpanded = true"&ndash;&gt;-->
<!--&lt;!&ndash;    (mouseleave)="tooltipExpanded = false"&ndash;&gt;-->
<!--&lt;!&ndash;    icon="question"&ndash;&gt;-->
<!--&lt;!&ndash;    class="relative z-10">&ndash;&gt;-->
<!--&lt;!&ndash;  </tandem-icon>&ndash;&gt;-->

<!--&lt;!&ndash;  &lt;!&ndash; Tooltip &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;  <div class="absolute bottom-35 z-20">&ndash;&gt;-->
<!--&lt;!&ndash;    <div class="relative inline-flex p-9 items-start gap-10 rounded-sm bg-primary w-500">&ndash;&gt;-->
<!--&lt;!&ndash;      <h6 class="text-background">{{text}}</h6>&ndash;&gt;-->
<!--&lt;!&ndash;      &lt;!&ndash; Tooltip Arrow &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="w-15 h-15 rotate-45 bg-primary absolute bottom-3 top-27"></div>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;  </div>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->


<div class="relative flex items-center">
  <span class="cursor-help" (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false">
    <!-- Tooltip Icon -->
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
</svg>

  </span>
  <div *ngIf="showTooltip" class="absolute bottom-full mb-2 px-2 py-1 bg-white text-black border border-primary text-sm rounded-md w-fit" style="width: max-content; max-width: 250px">
    <!-- Tooltip Text -->
    {{ text }}
  </div>
</div>
