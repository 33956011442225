<tandem-loading-container [isLoaded]="!isLoading">
  <h2 class="text-xl font-bold mb-4 text-center">Reset Password</h2>
  <div *ngIf="!requestSent" class="w-full flex flex-col justify-center items-center mt-4">
    <tandem-form-input class="w-full"
                       [form]="emailForm"
                       [label]="'Email'"
                       [controlName]="'email'"
                       [inputId]="'email'"
                       [placeholder]="'Email'"></tandem-form-input>
    <div class="flex flex-row gap-4 justify-center">
      <tandem-button class="inline-block w-fit" theme="outline" color="white" [onClick]="close.bind(this)">Cancel</tandem-button>
      <tandem-button [disabled]="emailForm.invalid" class="inline-block w-fit" theme="solid" color="primary" [onClick]="onSubmit.bind(this)">Reset</tandem-button>
    </div>
  </div>
  <ng-container *ngIf="requestSent && !isLoading">
    <ng-container *ngIf="!errors">
      <h4 class="mb-8 text-center">Successfully sent an email to {{emailForm.get('email')?.value}}. Please check your inbox.</h4>
      <div class="flex flex-row justify-center">
        <tandem-button class="inline-block" theme="solid" color="primary" [onClick]="close.bind(this)">Close</tandem-button>
      </div>
    </ng-container>
    <ng-container *ngIf="errors">
      <h4 class="mb-8 text-center">An unexpected error occurred. We apologize for the inconvenience. Try again, or contact support.</h4>
      <div class="flex flex-row justify-center">
        <tandem-button class="inline-block" theme="solid" color="primary" [onClick]="close.bind(this)">Close</tandem-button>
      </div>
    </ng-container>
  </ng-container>
</tandem-loading-container>
