import { Injectable } from '@angular/core';
import {AbstractDatabaseService} from "./abstract-database.service";
import {ChatMessage} from "../models/chat-message";
import {AngularFirestore, AngularFirestoreCollection, CollectionReference} from "@angular/fire/compat/firestore";
import {catchError, combineLatest, from, Observable, of, switchMap} from "rxjs";
import {TandemUser} from "../../auth/models/tandem-user";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ChatMessageService extends AbstractDatabaseService<ChatMessage> {

  constructor(protected override afs: AngularFirestore) {
    super('chatMessages', afs);
  }

  public getMessagesForMembers(userId1: string, userId2: string): Observable<ChatMessage[]> {
    const collection1: AngularFirestoreCollection<ChatMessage> = this.afs.collection<ChatMessage>(this.path, ref => ref
      .where('senderId', '==', userId1)
      .where('recipientId', '==', userId2));

    const collection2: AngularFirestoreCollection<ChatMessage> = this.afs.collection<ChatMessage>(this.path, ref => ref
      .where('senderId', '==', userId2)
      .where('recipientId', '==', userId1));

    return combineLatest([
      collection1.snapshotChanges(),
      collection2.snapshotChanges()
    ]).pipe(
      map(([changes1, changes2]) => [...changes1, ...changes2]),
      map(changes =>
        changes.map(change => {
          const data = change.payload.doc.data() as ChatMessage;
          const id = change.payload.doc.id;
          return { id, ...data };
        })
      ),
      map(messages =>
        messages.sort((a, b) => a.dateCreated.toDate().getTime() - b.dateCreated.toDate().getTime())
      )
    );
  }
}
