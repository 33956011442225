import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter, Input,
  Output, Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

@Component({
  selector: 'tandem-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.scss']
})
export class ModalWrapperComponent implements AfterViewInit {
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef }) viewContainerRef!: ViewContainerRef;
  @Output() onClose = new EventEmitter<any>();
  @Output() onViewInitialized = new EventEmitter<void>();

  @Input() width: string = 'sm:max-w-lg';

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    console.log(this.width)
    this.onViewInitialized.emit();
    // Component will be loaded dynamically after view initialization
  }

  public loadComponent<T extends Record<string, any>>(component: Type<T>, inputData: T): ComponentRef<T> | null {
    // if (!this.viewContainerRef) {
    //   // Handle this case appropriately
    //   throw new Error('ViewContainerRef is undefined.');v
    // }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.viewContainerRef?.clear();
    const componentRef = this.viewContainerRef?.createComponent(componentFactory);

    if (componentRef) {
      // Assign input data to instance properties safely
      for (const key of Object.keys(inputData)) {
        // @ts-ignore
        componentRef.instance[key] = inputData[key];
        if (key === 'width' && !!inputData[key]) {
          this.width = inputData[key];
          console.log(this.width)
        }
      }

      this.cdr.detectChanges();
      return componentRef;
    } else {
      return null;
    }
  }

  public close(result?: any): void {
    this.onClose.emit(result);
    // Logic to close the modal
  }
}
