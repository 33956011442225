import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[appEnterKeySubmit]'
})
export class EnterKeySubmitDirective {
  @Input('appEnterKeySubmit') formGroup?: FormGroup;
  @Input() onSubmit?: () => void;

  constructor(private el: ElementRef) {}

  @HostListener('keydown.enter', ['$event'])
  onEnterKey(event: KeyboardEvent) {
    if (this.formGroup && this.formGroup.valid && this.onSubmit) {
      const activeElement = document.activeElement;
      const allButtons = this.el.nativeElement.querySelectorAll('tandem-button button');

      // Only submit if the active element is not a button
      if (!Array.from(allButtons).includes(activeElement)) {
        event.preventDefault();
        this.onSubmit();
      }
    }
  }
}
