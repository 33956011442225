import { Injectable } from '@angular/core';
import {catchError, from, Observable, switchMap, throwError} from "rxjs";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {AuthService} from "../../auth/services/auth.service";
import {DialogService} from "../../tandem-core/services/dialog.service";
import {StripeService} from "ngx-stripe";
import {TandemUser} from "../../auth/models/tandem-user";

@Injectable({
  providedIn: 'root'
})
export class StripeOperationsService {
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private dialogService: DialogService,
    private stripeService: StripeService
  ) {}

  private handleError(error: any, disableDialog: boolean = false): Observable<never> {
    if (!disableDialog) {
      this.dialogService.closeModal2();
      this.dialogService.openMessageDialog('Unexpected Error', 'There was a problem processing your request. Please refresh and try again, or contact support.', true);
    }
    return throwError(error);
  }

  openStripeForUserOfRSCoach() {
    const dialogRef = this.dialogService.openModal2({
      title: 'Hang On',
      content: 'Processing your request. Please wait.',
      type: 'loading'
    });

    return from(this.auth.getIdToken()).pipe(
      switchMap(idToken => {
        if (!idToken) {
          throw new Error('No ID token available');
        }
        const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
        return this.http.post<any>(`${environment.functionsPath}/createSubscription`, {}, { headers });
      }),
      catchError(error => {
        this.dialogService.closeModal2();
        return this.handleError(error);
      })
    );
  }

  openStripeForRSCoach(user: TandemUser) {
    const dialogRef = this.dialogService.openModal2({
      title: 'Hang On',
      content: 'Processing your request. Please wait.',
      type: 'loading'
    });

    return from(this.auth.getIdToken()).pipe(
      switchMap(idToken => {
        if (!idToken) {
          throw new Error('No ID token available');
        }
        const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
        return this.http.post<any>(`${environment.functionsPath}/setupStripeConnect`, {}, { headers });
      }),
      switchMap(response => {
        if (response.url) {
          window.location.href = response.url;
          return new Observable(observer => observer.complete());
        } else {
          throw new Error('Invalid response from server');
        }
      }),
      catchError(error => {
        this.dialogService.closeModal2();
        return this.handleError(error);
      })
    );
  }

  openStripeForLGCoach(user: TandemUser) {
    const dialogRef = this.dialogService.openModal2({
      title: 'Hang On',
      content: 'Processing your request. Please wait.',
      type: 'loading'
    });

    return from(this.auth.getIdToken()).pipe(
      switchMap(idToken => {
        if (!idToken) {
          throw new Error('No ID token available');
        }
        const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
        return this.http.post<any>(`${environment.functionsPath}/setupStripeCheckout`, {}, { headers });
      }),
      catchError(error => {
        this.dialogService.closeModal2();
        return this.handleError(error, true);
      })
    );
  }

  validateStripeForLGCoach(sessionId: string) {

    return from(this.auth.getIdToken()).pipe(
      switchMap(idToken => {
        if (!idToken) {
          throw new Error('No ID token available');
        }
        const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
        const params = new HttpParams().set('sessionId', sessionId);
        return this.http.post<any>(`${environment.functionsPath}/validateLeadGenCoachCheckout`, {}, { headers, params });
      }),
      catchError(error => {
        this.dialogService.closeModal2();
        return this.handleError(error, true);
      })
    );
  }

  validateStripeForRSCoach(accountId: string) {

    return from(this.auth.getIdToken()).pipe(
      switchMap(idToken => {
        if (!idToken) {
          throw new Error('No ID token available');
        }
        const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
        const params = new HttpParams().set('accountId', accountId);
        return this.http.post<any>(`${environment.functionsPath}/validateCoachRevShareConnect`, {}, { headers, params });
      }),
      catchError(error => {
        this.dialogService.closeModal2();
        return this.handleError(error, true);
      })
    );
  }

  validateStripeForUserOfRSCoach(sessionId: string, coachId: string) {

    return from(this.auth.getIdToken()).pipe(
      switchMap(idToken => {
        if (!idToken) {
          throw new Error('No ID token available');
        }
        const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
        const params = new HttpParams().set('sessionId', sessionId).set('coachId', coachId);
        return this.http.post<any>(`${environment.functionsPath}/validateRevShareUserCheckout`, {}, { headers, params });
      }),
      catchError(error => {
        this.dialogService.closeModal2();
        return this.handleError(error);
      })
    );
  }
}
